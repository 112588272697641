(function SybitFront_datepicker($) {
  "use strict";
  /* German initialisation for the jQuery UI date picker plugin. */
  /* Written by Milian Wolff (mail@milianw.de). */
  $(function () {
    var pageLang = document.documentElement.lang;
    if (pageLang === "de") {

      $.datepicker.regional['de'] = {
        closeText: "Schließen",
        prevText: "<",
        nextText: ">",
        currentText: "Heute",
        monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni",
          "Juli", "August", "September", "Oktober", "November", "Dezember"],
        monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun",
          "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        weekHeader: "KW",
        dateFormat: "dd.mm.yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
      };

      $.datepicker.setDefaults($.datepicker.regional['de']);

    }

    initAll();
    $("body").on("ajaxreload", initAll);

    function initAll() {
      $(".datepicker:not(.datepicker--initialized)")
        .addClass("datepicker--initialized")
        .each(initOne);
    }

    function initOne() {
      var
        $this = $(this),
        $altField = getAltField(this),
        value = $altField.val(),
        date = value ? $.datepicker.parseDate("yy-mm-dd", value) : null
      ;
      $this
        .datepicker({altField: $altField[0], altFormat: "yy-mm-dd"})
        .datepicker("setDate", date)
        .on("change", onDatePickerChange)
      ;
    }

    function onDatePickerChange(e) {
      var $altField = getAltField(this);
      if (!$(this).val()) {
        $altField.val("");
      }
      window.requestAnimationFrame(function () {
        $altField.trigger("change");
      });
    }

    function getAltField(datepicker) {
      return $(datepicker).find("+.datepicker-alt-field");
    }
  });
})(jQuery);
