SybitFront.eChart = (function ($) {
  "use strict";
  var
    chartsToLoadWhenReady = [],
    apiIsInitializing = false,
    apiIsReady = false,
    ret = {
      load: load
    },
    charts = []
  ;

  $(function () {
    attach();
    $("body").on("ajaxreload", attach);
  });

  function attach() {
    var $charts = $(".echart[data-echart-api-src][data-echart-options-url]:not(.echart--attached,[data-echart-no-autoload])");
    $charts.addClass("echart--attached");
    if ($charts.length) {
      load($charts);
    }
  }

  return ret;

  /**
   * @param element domNode
   * @param datasetUrl (optional - to change dataset)
   * @param callback
   */
  function load(element, datasetUrl, callback) {
    ensureApi.call(element);
    if (typeof datasetUrl !== "undefined") {
      $(element).data("echart-dataset", datasetUrl);
    }
    $(element).removeData("echart-data").each(function () {
      var params = {chart: this};
      if (apiIsReady) {
        loadChart.call(this, callback);
      } else {
        if (callback) {
          params.callback = callback;
        }
        chartsToLoadWhenReady.push(params);
      }
    });
  }

  function ensureApi() {
    var
      $this = $(this),
      apiUrl = $this.data("echart-api-src")
    ;
    if (!apiUrl) {
      throw "Missing Attribute data-echart-api-src!";
    }
    if (apiIsInitializing) {
      return;
    }
    $this.addClass("echart--loading-lib");
    apiIsInitializing = true;
    if (
      typeof echart === "undefined" ||
      typeof echart.init !== "function"
    ) {
      SybitFront.load.single.call(this, apiUrl, ensureRessources);
    } else {
      ensureRessources();
    }

    function ensureRessources() {
      var i;
      $this.removeClass("echart--loading-lib");
      apiIsReady = true;
      if (chartsToLoadWhenReady.length) {
        for (i = 0; i < chartsToLoadWhenReady.length; i++) {
          if (chartsToLoadWhenReady[i].callback) {
            loadChart.call(chartsToLoadWhenReady[i].chart, chartsToLoadWhenReady[i].callback);
          } else {
            loadChart.call(chartsToLoadWhenReady[i].chart);
          }
        }
      }
    }
  }

  function loadChart(callback) {
    var
      $this = $(this),
      optionsUrl = $this.data("echart-options-url"),
      datasetUrl = $this.data("echart-dataset-url"),
      datasetUrlCallbackStr = $this.data("echart-dataset-url-callback"),
      datasetUrlCallback,
      urls = []
    ;
    if (optionsUrl) {
      $this.removeData("echart-options-url").removeAttr("data-echart-options-url");
      urls.push(optionsUrl);
    }
    if (datasetUrl) {
      if (datasetUrlCallbackStr) {
        datasetUrlCallback = SybitFront.util.retrieveFunction(datasetUrlCallbackStr);
        if (typeof datasetUrlCallback === 'function') {
          datasetUrl = datasetUrlCallback.call(this, datasetUrl);
        }
      }
      urls.push(datasetUrl);
    }
    $this.addClass("echart--loading-chart");
    SybitFront.load.parallel(urls, function (options, dataset) {
      if (options) {
        $this.data("echart-options", options);
      }
      if (dataset) {
        $this.data("echart-dataset", dataset);
      }
      checkResources.call($this[0], callback);
    }, null, function () {
      $this.removeClass("echart--loading-chart");
    });
  }

  function checkResources(callback) {
    var
      $this = $(this),
      options = $this.data("echart-options"),
      dataset = $this.data("echart-dataset")
    ;
    if (options instanceof Object) {
      if (dataset instanceof Object) {
        if (dataset.dataset) {
          options = $.extend(options, dataset);
        } else {
          options.dataset = dataset;
        }
      }
      if ($.isEmptyObject(options.dataset)) {
        $this.addClass("echart--empty");
        if (!$this.find(".echart__empty-msg").length) {
          $this.append(
            $("<div></div>")
              .addClass("echart__empty-msg")
              .text($this.data("echart-empty-msg"))
          );
        }
      } else {
        $this.removeClass("echart--empty");
        drawChart.call(this);
      }
    }
    if (callback) {
      callback.call(this);
    }
  }

  function drawChart() {
    var $this = $(this);
    getChartFrom(this).setOption(getOptionsFrom(this));
    $(window)
      .off("resizingStep", handleChartResizing)
      .on("resizingStep", handleChartResizing);

    function handleChartResizing() {
      if ($this.is(":visible")) {
        getChartFrom($this[0]).resize();
      }
    }
  }

  function getOptionsFrom(element) {
    var
      $element = $(element),
      options = $element.data("echart-options"),
      optionsPreprocessor = $element.data("echart-options-preprocessor"),
      dataset = $element.data("echart-dataset"),
      resolverToken = $element.data("echart-options-resolver-token")
    ;
    if (dataset) {
      options = mergeOptionsWithDataset(options, dataset);
    }
    if (resolverToken) {
      applyOptionsResolver(options, resolverToken);
    }
    if (optionsPreprocessor) {
      applyOptionsPreprocessor.call(element, options, optionsPreprocessor);
    }
    return options;
  }

  function mergeOptionsWithDataset(options, dataset) {
    if (dataset.dataset || dataset.baseOption) {
      options = $.extend(true, options, dataset);
    } else if (options.baseOption) {
      options.baseOption.dataset = dataset;
    } else {
      options.dataset = dataset;
    }
    return options;
  }

  function applyOptionsResolver(options, resolverToken) {
    for (var key in options) {
      if (options.hasOwnProperty(key)) {
        applyOptionsResolverByType(options, key, resolverToken);
      }
    }
  }

  function applyOptionsResolverByType(options, key, resolverToken) {
    switch (typeof options[key]) {
      case "string":
        checkAndHandleResolverToken(options, key, resolverToken);
        break;
      case "array":
      case "object":
        applyOptionsResolver(options[key], resolverToken);
        break;
      default:
        break;
    }
  }

  function checkAndHandleResolverToken(options, key, resolverToken) {
    var obj;
    if (0 === options[key].indexOf(resolverToken)) {
      obj = SybitFront.util.retrieveObject(options[key]);
      if (obj !== undefined) {
        options[key] = SybitFront.util.retrieveObject(options[key]);
      }
    }
  }

  function applyOptionsPreprocessor(options, processorPath) {
    var processor = SybitFront.util.retrieveFunction(processorPath);
    if (typeof processor === "function") {
      processor.call(this, options);
    }
  }

  function getChartFrom(element) {
    if (typeof element.echartInstanceId !== "number") {
      element.echartInstanceId = charts.length;
      var renderer = $(element).data("echart-renderer");
      if (renderer) {
        charts[element.echartInstanceId] = echarts.init(element, null, {renderer: renderer});
      } else {
        charts[element.echartInstanceId] = echarts.init(element);
      }
    }
    return charts[element.echartInstanceId];
  }
})(jQuery);
