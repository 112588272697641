var token;
var aliasList;
Multivac.machine.reports = (function ($) {

  return {
    load: loadReports,
    downloadReport: requestPDF
  };

  function dateFormat() {
    return { year: "numeric",  month: "2-digit",  day: "2-digit" };
  }

  function buildDataRow(alias, startDate, endDate, path, filetype, type, host, machineID) {
    var lang = $('body').data('current-language');
    var row = $('<tr></tr>')
    buildFileName(host, machineID, type, path).appendTo(row)
    buildReportType(alias).appendTo(row)
    buildFileType(filetype).appendTo(row)
    buildDate(startDate, lang, dateFormat()).appendTo(row)
    buildDate(endDate, lang, dateFormat()).appendTo(row)
    return row;
  }

  function buildFileName(host, machineID, type, path) {
    let td = $('<td></td>')
    $('<a></a>')
      .attr({
        'data-on-click-call': 'Multivac.machine.reports.downloadReport',
        'data-reports-api-host': host,
        'data-report-type': type,
        'data-report-path': path,
        'data-machine-name': machineID
      }).text(getFormattedFilename(path)).appendTo(td)
    return td;
  }

  function getFormattedFilename(filename) {
    return filename.split('%2F')[1];
  }

  function getLocalizedReportType(type) {
    let alias = aliasList.find(alias => alias.key === type.toLowerCase())
    return alias ? alias.value : type;
  }

  function buildReportType(type) {
    return $('<td></td>').text(getLocalizedReportType(type));
  }

  function buildFileType(fileType) {
    return $('<td></td>').text(fileType.toUpperCase());
  }

  function buildDate(date, language, options) {
    let isoDate = new Date(date);
    return $('<td></td>').attr({'data-sort-value': isoDate.getTime()}).text(isoDate.toLocaleDateString(language, options));
  }

  function buildTypesRequestUrl(host) {
    return 'https://' + host + '/reports/types';
  }

  function buildDataRequestUrl(reportType, machineID, host) {
    return 'https://' + host + '/reports/' + reportType + '/machines/' + machineID;
  }

  function buildDownloadRequestUrl(reportType, machineID, reportPath, host) {
    return 'https://' + host + '/reports/' + reportType + '/machines/' + machineID + '/download/' + encodeURIComponent(reportPath);
  }

  function requestData(url, token) {
    return $.ajax({
      url: url,
      beforeSend: function (request) {
        request.setRequestHeader("Authorization", "Bearer " + token);
      },
      type: 'GET'
    }).then(function (data) {
      return data;
    }).catch(function (error) {
      return Promise.reject(error);
    });
  }

  function loadReports(value) {
    token = value;
    var host = $(this).data("reports-api-host");
    aliasList = $(this).data("report-alias-list");
    var equipmentCode = $(this).data("equipment-code");
    var machineType = $(this).data("technical-machine-type");
    var machineID = machineType + '_' + equipmentCode;
    var $table = $(this).find(".machine-report-table");
    var $tableHeader = $(this).find(".machine-report-start-date");
    var $data = $(this).find(".machine-report-data");

    requestData(buildTypesRequestUrl(host), token).then(function (types) {
      var typeRequests = [];
      for (var i = 0; i < types.length; i++) {
        //This might cause a Problem in IE

        let type = types[i];
        typeRequests.push(requestData(buildDataRequestUrl(type, machineID, host), token).then(function (reports) {
          for (var j = 0; j < reports.length; j++) {
            buildDataRow(reports[j].alias, reports[j].startDate, reports[j].endDate, reports[j].path, reports[j].type, type, host, machineID).appendTo($data)
          }
        }))
      }
      Promise.all(typeRequests)
        .then(function() {
          $table.stupidtable();
          $tableHeader.stupidsort("desc");
        })
        .catch(function (reason) {
          console.error(reason);
        });
    })
  }

  function requestPDF() {
    var host = $(this).data("reports-api-host");
    var machineName = $(this).data("machine-name");
    var reportType = $(this).data("report-type");
    var reportPath = $(this).data("report-path");
    $.ajax({
      url: buildDownloadRequestUrl(reportType, machineName, reportPath, host),
      beforeSend: function (request) {
        request.setRequestHeader("Authorization", "Bearer " + token);
      },
      success: function (data) {
        var blob = new Blob([data]);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = reportPath;
        link.click();
      }
    });
  }

  //Added dummy function for convenience in local testing
  function requestDataDummyTypes() {
    return Promise.resolve([
      "machinedata-report",
      "monthly-report",
      "package-report"
    ]);
  }

  //Added dummy function for convenience in local testing
  function requestDataDummyReports() {
    return Promise.resolve(    [
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200601_20200701.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-06-01T00:00:00.000Z",
        "endDate": "2020-07-01T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200601_20200712.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-06-01T00:00:00.000Z",
        "endDate": "2020-07-12T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200615_20200726.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-06-15T00:00:00.000Z",
        "endDate": "2020-07-26T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200720_20200823.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-07-20T00:00:00.000Z",
        "endDate": "2020-08-23T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200720_20200830.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-07-20T00:00:00.000Z",
        "endDate": "2020-08-30T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200720_20201104.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-07-20T00:00:00.000Z",
        "endDate": "2020-11-04T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200727_20200823.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-07-27T00:00:00.000Z",
        "endDate": "2020-08-23T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200727_20200830.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-07-27T00:00:00.000Z",
        "endDate": "2020-08-30T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200727_20200906.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-07-27T00:00:00.000Z",
        "endDate": "2020-09-06T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200803_20200913.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-08-03T00:00:00.000Z",
        "endDate": "2020-09-13T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200810_20200920.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-08-10T00:00:00.000Z",
        "endDate": "2020-09-20T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200817_20200927.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-08-17T00:00:00.000Z",
        "endDate": "2020-09-27T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200824_20201004.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-08-24T00:00:00.000Z",
        "endDate": "2020-10-04T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200831_20201011.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-08-31T00:00:00.000Z",
        "endDate": "2020-10-11T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200907_20201018.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-09-07T00:00:00.000Z",
        "endDate": "2020-10-18T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200914_20201025.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-09-14T00:00:00.000Z",
        "endDate": "2020-10-25T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200921_20201101.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-09-21T00:00:00.000Z",
        "endDate": "2020-11-01T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20200928_20201108.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-09-28T00:00:00.000Z",
        "endDate": "2020-11-08T00:00:00.000Z"
      },

      {
        "path": "RX40_249389%2Freport_RX40_249389_20201026_20201206.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-10-26T00:00:00.000Z",
        "endDate": "2020-12-06T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20201221_20210131.html",
        "alias": "report",
        "type": "html",
        "startDate": "2020-12-21T00:00:00.000Z",
        "endDate": "2021-01-31T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20210111_20210221.html",
        "alias": "report",
        "type": "html",
        "startDate": "2021-01-11T00:00:00.000Z",
        "endDate": "2021-02-21T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20210208_20210321.html",
        "alias": "report",
        "type": "html",
        "startDate": "2021-02-08T00:00:00.000Z",
        "endDate": "2021-03-21T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20210809_20210919.html",
        "alias": "report",
        "type": "html",
        "startDate": "2021-08-09T00:00:00.000Z",
        "endDate": "2021-09-19T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20210816_20210926.html",
        "alias": "report",
        "type": "html",
        "startDate": "2021-08-16T00:00:00.000Z",
        "endDate": "2021-09-26T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20210823_20211003.html",
        "alias": "report",
        "type": "html",
        "startDate": "2021-08-23T00:00:00.000Z",
        "endDate": "2021-10-03T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20210830_20211010.html",
        "alias": "report",
        "type": "html",
        "startDate": "2021-08-30T00:00:00.000Z",
        "endDate": "2021-10-10T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20211004_20211114.html",
        "alias": "report",
        "type": "html",
        "startDate": "2021-10-04T00:00:00.000Z",
        "endDate": "2021-11-14T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20211227_20220206.html",
        "alias": "report",
        "type": "html",
        "startDate": "2021-12-27T00:00:00.000Z",
        "endDate": "2022-02-06T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220103_20220213.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-01-03T00:00:00.000Z",
        "endDate": "2022-02-13T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220214_20220327.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-02-14T00:00:00.000Z",
        "endDate": "2022-03-27T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220221_20220403.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-02-21T00:00:00.000Z",
        "endDate": "2022-04-03T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220228_20220410.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-02-28T00:00:00.000Z",
        "endDate": "2022-04-10T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220307_20220417.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-03-07T00:00:00.000Z",
        "endDate": "2022-04-17T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220314_20220424.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-03-14T00:00:00.000Z",
        "endDate": "2022-04-24T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220321_20220501.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-03-21T00:00:00.000Z",
        "endDate": "2022-05-01T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220411_20220522.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-04-11T00:00:00.000Z",
        "endDate": "2022-05-22T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220502_20220612.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-05-02T00:00:00.000Z",
        "endDate": "2022-06-12T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220516_20220626.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-05-16T00:00:00.000Z",
        "endDate": "2022-06-26T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220523_20220703.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-05-23T00:00:00.000Z",
        "endDate": "2022-07-03T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220530_20220710.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-05-30T00:00:00.000Z",
        "endDate": "2022-07-10T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220606_20220717.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-06-06T00:00:00.000Z",
        "endDate": "2022-07-17T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220613_20220724.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-06-13T00:00:00.000Z",
        "endDate": "2022-07-24T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220620_20220731.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-06-20T00:00:00.000Z",
        "endDate": "2022-07-31T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220627_20220807.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-06-27T00:00:00.000Z",
        "endDate": "2022-08-07T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220801_20220911.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-08-01T00:00:00.000Z",
        "endDate": "2022-09-11T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220808_20220918.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-08-08T00:00:00.000Z",
        "endDate": "2022-09-18T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220815_20220925.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-08-15T00:00:00.000Z",
        "endDate": "2022-09-25T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220822_20221002.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-08-22T00:00:00.000Z",
        "endDate": "2022-10-02T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220829_20221009.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-08-29T00:00:00.000Z",
        "endDate": "2022-10-09T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220905_20221016.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-09-05T00:00:00.000Z",
        "endDate": "2022-10-16T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220912_20221023.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-09-12T00:00:00.000Z",
        "endDate": "2022-10-23T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20220919_20221030.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-09-19T00:00:00.000Z",
        "endDate": "2022-10-30T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20221003_20221113.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-10-03T00:00:00.000Z",
        "endDate": "2022-11-13T00:00:00.000Z"
      },
      {
        "path": "RX40_249389%2Freport_RX40_249389_20221017_20221127.html",
        "alias": "report",
        "type": "html",
        "startDate": "2022-10-17T00:00:00.000Z",
        "endDate": "2022-11-27T00:00:00.000Z"
      }
    ])
  }
})(jQuery);
