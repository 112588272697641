SybitFront.filter = (function($){
  "use strict";
  var counters = [];

  $(function(){
    attachHandlers();
    $("body").on("ajaxreload",attachHandlers);
  });

  return {
    triggerRefreshAll: triggerRefreshAll,
    triggerRefreshSingle: triggerRefreshSingle,
    registerFilterCounter: registerFilterCounter,
    refreshAllFilterCounters: refreshAllFilterCounters
  };

  function attachHandlers() {
    $("[data-filter-on]:not(.data-filter-attached)").each(attachHandler).addClass("data-filter-attached");
  }

  function attachHandler() {
    var
      $this = $(this),
      eventName = $this.data("filter-on")
    ;
    $this.on(eventName, handleFilter);
  }

  function triggerRefreshAll() {
    $("[data-filter-on]").each(handleFilter);
  }

  function triggerRefreshSingle() {
    $(this).each(handleFilter);
  }

  function handleFilter() {
    var $this = $(this);
    window.requestAnimationFrame(function(){
      var
        matchClass = $this.data("filter-match-class"),
        conflictClass = $this.data("filter-conflict-class"),
        result = getFilterResult.call($this[0])
      ;
      if (matchClass || conflictClass) {
        result.$matches.addClass(matchClass).removeClass(conflictClass);
        result.$elements.not(result.$matches).removeClass(matchClass).addClass(conflictClass);
      } else {
        result.$matches.show();
        result.$elements.not(result.$matches).hide();
      }
      refreshAllFilterCounters();
      $this.trigger("filtered");
    });
  }

  function getFilterResult(forCounter) {
    var
      $this = $(this),
      retrieveValFuncName = $this.data(forCounter ? "filter-counter-value-retriever" : "filter-value-retriever"),
      retrieveValFunc = retrieveValFuncName ? SybitFront.util.retrieveFunction(retrieveValFuncName) : false,
      val = retrieveValFunc ? retrieveValFunc.call($this[0]) : $this.val().trim(),
      $elements = retrieveElements.call($this[0]),
      caseSensitive = /^true$/i.test($this.data("filter-case-sensitive")),
      fullMatch = /^true$/i.test($this.data("filter-full-match")),
      $matches = val ? getMatches($elements, getCallback.call($this[0]), val, caseSensitive, fullMatch) : $elements
    ;
    return {
      $elements: $elements,
      $matches: $matches
    };
  }

  function registerFilterCounter(counter, filter) {
    $(counter).filter(":not(.filter-counter--registered)").each(function(){
      var
        $counter = $(counter),
        $filter = $(filter)
      ;
      counters.push({$counter: $counter, $filter: $filter});
      window.requestAnimationFrame(function(){
        refreshSingleFilterCounter($counter, $filter);
      });
    }).addClass("filter-counter--registered");
  }

  function refreshAllFilterCounters() {
    window.requestAnimationFrame(function(){
      for(var c=0; c<counters.length; c++) {
        refreshSingleFilterCounter(counters[c].$counter, counters[c].$filter);
      }
    });
  }

  function refreshSingleFilterCounter($counter, $filter) {
    var count =  retrieveFilterCount.call($filter[0]);
    $counter.text(count);
    $filter.attr("filter-count", count);
  }

  function retrieveFilterCount() {
    var $this = $(this);
    var result = getFilterResult.call($this[0], true);
    return result.$matches.filter(":visible").length;
  }

  function getCallback() {
    var callbackPath = $(this).data("filter-text-callback");
    return callbackPath ? SybitFront.util.retrieveFunction(callbackPath) : defaultTextCallback;
  }

  function getMatches($elements, callback, val, caseSensitive, fullMatch) {
    return $elements.filter(function(){
      var text = callback.call(this);
      return textMatches(text, val, caseSensitive, fullMatch);
    });
  }

  function textMatches(text, val, caseSensitive, fullMatch) {
    var matches = false, func;
    if (val instanceof Array) {
      matches = textMatchesValueArray(text, val, caseSensitive, fullMatch);
    } else if(text instanceof Array) {
      matches = textArrayMatchesValue(text, val, caseSensitive, fullMatch);
    } else {
      if(caseSensitive) {
        func = fullMatch ? equals : includes;
      } else {
        func = fullMatch ? equalsLowercase : includesLowercase ;
      }
      matches = func(String(text), val, fullMatch);
    }
    return matches;
  }

  function textArrayMatchesValue(text, val, caseSensitive, fullMatch) {
    var matches = false, t;
    for (t = 0; t < text.length && !matches; t++) {
      if(textMatches(text[t], val, caseSensitive, fullMatch)) {
        matches = true;
      }
    }
    return matches;
  }

  function textMatchesValueArray(text, val, caseSensitive, fullMatch) {
    var matches = false, v;
    for (v = 0; v < val.length && !matches; v++) {
      if(textMatches(text, val[v], caseSensitive, fullMatch)) {
        matches = true;
      }
    }
    return matches;
  }

  function equalsLowercase(haystack, needle) {
    return equals(String(haystack).toLocaleLowerCase(), String(needle).toLocaleLowerCase());
  }
  function includesLowercase(haystack, needle) {
    return includes(String(haystack).toLocaleLowerCase(), String(needle).toLocaleLowerCase());
  }
  function equals(haystack, needle) {
    return haystack === needle;
  }
  function includes(haystack, needle) {
    return -1 !== haystack.indexOf(needle);
  }

  function retrieveElements() {
    var
      $this = $(this),
      selector = $this.data("filter-element-selector"),
      absoluteSelector = $this.data("filter-element-absolute-selector")
    ;
    return selector ? $this.find(selector) : $(absoluteSelector);
  }

  function defaultTextCallback() {
    return $(this).text().trim();
  }
})(jQuery);
