SybitFront.filedropper = (function ($) {
  "use strict";
  $(function () {
    attachAll();
    $("body").on("ajaxreload", attachAll);
  });

  return {
    plugIntoAjaxSettings: plugIntoAjaxSettings
  };

  function attachAll() {
    $(".filedropper:not(.js-filedropper-attached)")
      .addClass("js-filedropper-attached")
      .each(attachOne);
  }

  function attachOne() {
    var
      $filedropper = $(this),
      $input = $filedropper.find(".filedropper__input"),
      formats = $filedropper.data("filedropper-supported-formats")
    ;
    $filedropper
      .on("dragover dragenter", onDragOver)
      .on("dragleave dragend", onDragOut)
      .on("drop", onDrop)
      .on('change', '.filedropper__input', onChangeInput)
      .each(checkValidity);
    if(formats) {
      $input.attr("accept", convertFormatsForInputAccept(formats));
    }
  }

  function onDragOver(e) {
    $(this).addClass('filedropper--dragover');
    e.preventDefault();
  }

  function onDragOut() {
    $(this).removeClass('filedropper--dragover');
  }

  function onDrop(e) {
    var $this = $(this);
    $this.removeClass('filedropper--dragover');
    if (e.originalEvent && e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.files) {
      handleFiles.call(this, e.originalEvent.dataTransfer.files);
    }
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (/^true$/i.test($this.data("filedropper-autosubmit"))) {
      $this.parents("form").trigger("submit");
    }
  }

  function onChangeInput(e) {
    if (e.target.files && e.target.files.length) {
      handleFiles.call($(this).parents(".filedropper"), e.target.files);
      this.value = "";
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
  }

  function handleFiles(files) {
    files = validateFiles.call(this, files);
    if (files && files.length) {
      for (var f = 0; f < files.length; f++) {
        handleFile.call(this, files[f]);
      }
      updateList.call(this);
    }
    checkValidity.call(this);
  }

  function handleFile(file) {
    var $filedropper = $(this);
    if (getInputOf($filedropper)[0].hasAttribute("multiple")) {
      if (!($filedropper.prop("dropped-files") instanceof Array)) {
        $filedropper.prop("dropped-files", []);
      }
      $filedropper.prop("dropped-files").push(file);
    } else {
      $filedropper.prop("dropped-files", [file]);
    }
  }

  /**
   *
   * @param $filedropper
   * @return {JQuery}
   */
  function getInputOf($filedropper) {
    return $filedropper.find(".filedropper__input");
  }

  function plugIntoAjaxSettings(settings) {
    var
      $filedropper = $(this),
      inputName = $filedropper.data("filedropper-name"),
      files = $filedropper.prop('dropped-files'),
      isRequired = /^true$/i.test($filedropper.data("filedropper-required")),
      valid = !isRequired
    ;
    ensureAjaxSettingsWithFormData(settings);
    $.each(files, function (k, v) {
      settings.data.append(inputName, v);
      valid = true;
    });
    if (!valid) {
      throw new SybitFront.ajax.AbortMessage();
    }
    settings.processData = false;
    settings.contentType = false;
    settings.cache = false;
    attachEventListeners($filedropper, settings);
  }

  function attachEventListeners($filedropper, settings) {
    var
      showProgress = /^true$/.test($filedropper.data("filedropper-show-progress")),
      $progressBar = $filedropper.find(".filedropper__progress")
    ;
    settings.xhr = function () {
      var xhr = new XMLHttpRequest();
      xhr.addEventListener("abort", onAbort, false);
      xhr.addEventListener("error", onError, false);
      xhr.addEventListener("load", onUploadDone, false);
      if (showProgress) {
        xhr.upload.addEventListener("progress", onProgress, false);
        xhr.addEventListener("progress", onProgress, false);
        $progressBar
          .removeClass("filedropper__progress--error filedropper__progress--abort filedropper__progress--done")
          .addClass("filedropper__progress--active")
          .css("right", "100%");
      }
      $filedropper.addClass("filedropper--uploading");
      $filedropper.prop("xhr", xhr);
      return xhr;
    };

    function onProgress(e) {
      if (e.lengthComputable && e.total) {
        $progressBar.css("right", String((1 - (e.loaded / e.total)) * 100) + "%");
      }
    }

    function onError() {
      $filedropper.removeClass("filedropper--uploading").removeProp("xhr");
      changeProgressClass("filedropper__progress--error");
      $filedropper.each(relayErrorToForm);
    }

    function onAbort() {
      $filedropper.removeClass("filedropper--uploading").removeProp("xhr");
      changeProgressClass("filedropper__progress--abort");
    }

    function onUploadDone() {
      $filedropper.removeClass("filedropper--uploading").removeProp("xhr");
      changeProgressClass("filedropper__progress--done");
    }

    function changeProgressClass(className) {
      var classNames = [
        "filedropper__progress--abort",
        "filedropper__progress--done",
        "filedropper__progress--active"
      ].filter(function (val) {
        return val !== className;
      });
      $progressBar
        .addClass(className)
        .removeClass(classNames.join(" "));
    }

    $filedropper.on("abort", function () {
      if ($filedropper.is(".filedropper--uploading")) {
        $filedropper.prop("xhr").abort();
        onAbort();
      }
    });
    $filedropper.on("error", function () {
      if ($filedropper.is(".filedropper--uploading")) {
        onError();
      }
    });
  }

  function ensureAjaxSettingsWithFormData(settings) {
    var formData;
    if (!(settings.data instanceof FormData)) {
      formData = new FormData();
      $.each(settings.data, function (key, value) {
        formData.append(key, value);
      });
      settings.data = formData;
    }
  }

  function updateList() {
    var
      $filedropper = $(this),
      files = $filedropper.prop("dropped-files"),
      $list = $filedropper.find(".filedropper__entries"),
      $prototype = $list.find(".filedropper__entry-label--prototype")
    ;
    if ($list.length) {
      $list.find(".filedropper__entry-label:not(.filedropper__entry-label--prototype)").remove();
      $.each(files, function (key, file) {
        $prototype.clone()
          .removeClass("filedropper__entry-label--prototype")
          .find(".filedropper__entry-name").text(file.name).end()
          .find(".filedropper__entry-size").text(getLocalSize(file.size)).end()
          .prop("dropped-file", file)
          .on("click", removeFile)
          .appendTo($list);
      });
      $filedropper.trigger("change");
    }
  }

  function removeFile(e) {
    var
      $fileLabel = $(this),
      $filedropper = $fileLabel.closest(".filedropper"),
      files = $filedropper.prop("dropped-files"),
      file = $fileLabel.prop("dropped-file"),
      pos = files.indexOf(file)
    ;
    if (pos >= 0) {
      files.splice(pos, 1);
      $filedropper.prop("dropped-files", files);
      $fileLabel.remove();
    }
    $filedropper.each(checkValidity);
    e.preventDefault();
    e.stopPropagation();
    $filedropper.trigger("change");
  }

  function getLocalSize(bytes) {
    var
      u,
      units = ["Byte", "KB", "MB", "GB", "TB"],
      value = bytes
    ;
    for (u = 0; value > 950; u++) {
      value = Math.round(value * 10 / 1024) / 10;
    }
    return value.toLocaleString() + String.fromCharCode(160) + units[u];
  }

  /**
   * @param files File[]
   */
  function validateFiles(files) {
    var
      $filedropper = $(this),
      maxSize = parseInt($filedropper.data("max-file-size")),
      maxTotalSize = parseInt($filedropper.data("max-total-file-size")),
      maxSizeMessage = $filedropper.data("max-file-size-message"),
      maxTotalSizeMessage = $filedropper.data("max-total-file-size-message"),
      formats = $filedropper.data("filedropper-supported-formats"),
      formatsMessage = $filedropper.data("filedropper-supported-formats-message"),
      showSizeMessage = false,
      showFormatsMessage = false,
      showTotalSizeMessage = false,
      validFiles = [],
      existingFiles = $filedropper.prop('dropped-files'),
      currentTotalSize = calculateTotalSize(existingFiles)
    ;
    function isSizeValid(size) {
      return isNaN(maxSize) || size <= maxSize;
    }
    $.each(files, function (key, file) {
      var
        validFormats = !formats || validateFormats(file, formats),
        validSize = isSizeValid(file.size),
        validTotalSize = isSizeValid(currentTotalSize + file.size)
      ;
      if (validSize && validFormats && validTotalSize) {
        currentTotalSize += file.size;
        validFiles.push(file);
      } else {
        if (!validSize) {
          showSizeMessage = true;
        }
        if (!validFormats) {
          showFormatsMessage = true;
        }
        if (validSize && !validTotalSize) {
          showTotalSizeMessage = true;
        }
      }
    });
    if (showSizeMessage && maxSizeMessage) {
      showTemplateMessage(maxSizeMessage, /{size}/i, getLocalSize(maxSize));
    }
    if (showFormatsMessage && formatsMessage) {
      showTemplateMessage(formatsMessage, /{formats}/i, formats);
    }
    if (showTotalSizeMessage) {
      showTemplateMessage(maxTotalSizeMessage, /{size}/i, getLocalSize(maxTotalSize));
    }
    return validFiles;
  }

  function calculateTotalSize(files) {
    var totalSize = 0;
    $.each(files, function (key, file) {
      totalSize += file.size;
    });
    return totalSize;
  }

  function validateFormats(file, formats) {
    var
      allowed = splitFormats(formats),
      extension = file.name.toLowerCase().replace(/^.+?\.(\w+)$/,"$1")
    ;
    return allowed.length === 0 || allowed.indexOf(extension) !== -1;
  }

  function showTemplateMessage(message, search, replace) {
    var rendered = String(message).replace(search, replace);
    SybitFront.notification.create(rendered, "warning");
  }

  function checkValidity() {
    var
      $filedropper = $(this),
      isRequired = /^true$/i.test($filedropper.data("filedropper-required")),
      files = $filedropper.prop("dropped-files")
    ;
    $filedropper.find(".filedropper__input").attr("required", true).each(function () {
      var customValidity = "";
      if (isRequired && !(files && files.length)) {
        customValidity = $filedropper.data("data-required-message") ||
          getDefaultValidationMessage.call(this, $filedropper);
      }
      this.setCustomValidity(customValidity);
    }).removeAttr("required");
  }

  function getDefaultValidationMessage($filedropper) {
    var defaultValidationMessage = $filedropper.prop("defaultValidationMessage") || this.validationMessage;
    if (!defaultValidationMessage) {
      defaultValidationMessage = this.validationMessage;
      $filedropper.prop("defaultValidationMessage", defaultValidationMessage);
    }
    return defaultValidationMessage;
  }

  function relayErrorToForm() {
    var $filedropper = $(this);
    if (!$filedropper.prop("error-relay-timeout")) {
      $filedropper.prop("error-relay-timeout", window.setTimeout(function () {
        $filedropper
          .prop("error-relay-timeout", false)
          .closest("form").each(function () {
          SybitFront.ajax.handleError.call(this);
        });
      }), 50);
    }
  }

  function splitFormats(formats) {
    if(!formats) {
      return [];
    }
    return String(formats).toLowerCase().trim().split(/\s*[,;|]\s*\.?/g);
  }

  function convertFormatsForInputAccept(formats) {
    var
      arr = splitFormats(formats)
    ;
    return "."+arr.join(",.");
  }
})(jQuery);
