SybitFront.youtube = (function ($) {
  "use strict";
  var
    iframeApiLoaded = false,
    iframeApiReady = false,
    closing = false
  ;

  $(function () {
    attachEvents();
    $("body").on("ajaxreload", attachEvents);
  });

  return {
    profiles: {},
    close: closePlayer
  };

  function attachEvents() {
    $("[data-youtube-id]:not(.events-attached)")
      .addClass("events-attached")
      .on("click", initApiThenPlay);
  }

  function initApiThenPlay() {
    var element = this, args = arguments;
    if (iframeApiLoaded) {
      play.apply(element, args);
    } else {
      $.ajax({
        url: "https://www.youtube.com/iframe_api",
        dataType: "script",
        success: function () {
          iframeApiLoaded = true;
          play.apply(element, args);
        }
      });
    }
  }

  function play(e) {
    initPlayer.call(this, e).then(function (e) {
      e.target.playVideo();
    });
  }

  function initPlayer(e) {
    e.preventDefault();
    var
      $ytLoader = $(this),
      inline = /^true$/i.test($ytLoader.data("youtube-inline")),
      onReadyConfig = {
        onReady: function () {
        }
      }
    ;
    if (typeof $ytLoader[0].cmsYoutube === "undefined") {
      onApiReadyCreateInstance.call($ytLoader[0], onReadyConfig);
      if (!inline) {
        handleOverlay.call($ytLoader[0]);
      }
      return {
        then: function (callback) {
          onReadyConfig.onReady = callback;
        }
      };
    } else {
      return {
        then: function (callback) {
          callback.call($ytLoader[0], {target: $ytLoader[0].cmsYoutube});
        }
      };
    }
  }

  function onApiReadyCreateInstance(onReadyConfig) {
    var $ytLoader = $(this);
    if (iframeApiReady) {
      $ytLoader[0].cmsYoutube = createInstance.call($ytLoader[0], onReadyConfig);
    } else {
      window.onYouTubeIframeAPIReady = function () {
        iframeApiReady = true;
        $ytLoader[0].cmsYoutube = createInstance.call($ytLoader[0], onReadyConfig);
      };
    }
  }

  function createInstance(onReadyConfig) {
    var $ytLoader = $(this);
    return new YT.Player(
      fetchIframeContainerId.call($ytLoader[0]), {
        height: '100%',
        width: '100%',
        videoId: $ytLoader.data("youtube-id"),
        playerVars: retrievePlayerVars.call($ytLoader[0]),
        events: {
          'onReady': function (e) {
            onReadyConfig.onReady.call($ytLoader[0], e);
          }
        }
      }
    );
  }

  function fetchIframeContainerId() {
    var
      $ytLoader = $(this),
      inline = /^true$/i.test($ytLoader.data("youtube-inline")),
      $container = inline ? $ytLoader.find(".cms__youtube-inline") : $ytLoader.parent().find(".cms__youtube-iframe")
    ;
    return $container.attr("id");
  }

  function handleOverlay() {
    var $ytLoader = $(this);
    $ytLoader.parent().find(".cms__youtube-overlay")
      .find(".popup__close")
      .on("click", function () {
        $ytLoader[0].cmsYoutube.stopVideo();
      });
  }

  function retrievePlayerVars() {
    var
      $this = $(this),
      playerVars = $this.data("youtube-player-vars"),
      lang = $this.data("youtube-lang")
    ;
    playerVars = playerVars ? SybitFront.util.retrieveObject(playerVars) : {};
    if (lang) {
      playerVars.hl = lang;
    }
    return playerVars;
  }

  function closePlayer() {
    if (!closing) {
      closing = true;
      $(this).find(".popup__close").trigger("click");
      closing = false;
    }
  }
})(jQuery);
