Multivac.user = Multivac.user || {};
Multivac.user.profile = (function($){

  $(checkChangedDom);
  $("body").on("ajaxreload",checkChangedDom);

  return {
    checkMobileRequired: checkMobileRequired
  };

  function checkMobileRequired() {
    var mobileNeeded = $(".user-profile-form__needs-mobile .checkbox__native:checked").length > 0;
    $(".user-profile-form__mobile .input").attr("required", mobileNeeded);
  }

  function checkChangedDom() {
    $(".user-profile-form").each(checkMobileRequired);
  }
})(jQuery);
