SybitFront.popup = (function ($) {
  "use strict";
  $(function () {
    handlePopupIsOpen();
    window.addEventListener("hashchange", handlePopupIsOpen, false);
  });

  var scrollYpos = false;

  return {
    close: function (e) {
      if ($(e && e.target).is(this)) {
        forceClose();
      }
    },
    forceClose: forceClose,
    handlePopupSuccessClose: function ($overlay){
      window.setTimeout(handlePopupClose.bind(this, $overlay), parseInt($overlay.attr("data-close-delay") || "10000"));
    }
  };

  function forceClose() {
    var $overlayTarget = $(".popup__overlay--target");
    $overlayTarget.removeClass("popup__overlay--target");
    if ($overlayTarget.length) {
      handlePopupClose($overlayTarget);
    }
  }

  function handlePopupClose($overlay) {
    var returnUrl = $overlay.attr("data-on-close-return-url");
    if(returnUrl != "") {
      location.href = returnUrl;
    } else {
      window.history.back();
    }
  }

  function prefillFormFields($popup, params) {
    $popup.find('[name]').each(function fillFormField(index, element) {
      var $this = $(this),
        fieldName = $this.attr('name'),
        paramValue = params.get(fieldName);
      if (paramValue) {
        $this.val(paramValue);
      }
    });
  }

  function handlePopupIsOpen() {
    var
      hash = window.location.hash,
      params = new URLSearchParams(window.location.search),
      $body = $("body"),
      $popup = null,
      openPopupByQuery = params.get("startFlow")
    ;

    if(/^#\w+/.test(hash)) {
      $("[data-popup-id]").each(function (index, element) {
        var $this = $(this),
          hashPrefix = "#" + $this.data('popup-id');
        if (hash.startsWith(hashPrefix)) {
          $popup = $this;
        }
      });
    }

    if($popup === null && openPopupByQuery) {
      $popup = $("[data-popup-id=\"" + openPopupByQuery + "\"]");
      hash = "#" + openPopupByQuery;
    }

    if ( $popup != null) {
      $body.addClass("popup-is-open");
      $popup.addClass("popup__overlay--target");
      $popup.attr("id", hash.substring(1) || '');
      scrollYpos = $(document).scrollTop();
      var evt = $.Event("popupOpen");
      if(openPopupByQuery) {
        prefillFormFields($popup, params);
        evt.params = params;
      } else {
        evt.hash = hash || '#' + $popup.attr("id");
      }
      evt.$popup = $popup;
      $popup.trigger(evt);
    } else {
      $body.removeClass("popup-is-open");
      $(".popup__overlay--target").removeClass("popup__overlay--target");
      if (scrollYpos) {
        $(document).scrollTop(scrollYpos);
        scrollYpos = false;
      }
    }
  }
})(jQuery);
