(function Multivac_mobileNav($) {
  $(function () {
    var
      x1 = false,
      winWidth,
      $body = $("body"),
      navVisible,
      win15percent
    ;
    $body.on("touchstart", function (e) {
      var
        pageX = e.originalEvent.touches[0].pageX,
        startedLeft
      ;
      navVisible = $body.hasClass("mobile-nav-visible");
      winWidth = $(window).width();
      win15percent = winWidth * 0.15;
      startedLeft = pageX < win15percent;
      x1 = startedLeft || navVisible ? pageX : false;
    }).on("touchmove", function (e) {
      if (x1 !== false) {
        var
          x2 = e.originalEvent.touches[0].pageX,
          delta = x2 - x1
        ;
        if (delta > win15percent) {
          $body.addClass("mobile-nav-visible");
          x1 = false;
        } else if (delta < -win15percent) {
          $body.removeClass("mobile-nav-visible");
        }
      }
    })
    ;
  });
})(jQuery)
