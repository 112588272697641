Multivac.machine.tags = (function($){
  $(function(){
    initializeAll();
    $("body").on("ajaxreload", initializeAll);
  });

  function initializeAll() {
    $(".machine-tags:not(.machine-tags--initialized)")
      .select2({
        tags: true,
        dropdownAutoWidth: true,
        width: null
      })
      .addClass("machine-tags--initialized")
      .each(fixReFocusForIe11)
    ;
  }

  return {
    onSelect: onSelect,
    onUnselect: onUnselect,
    select: select
  };

  function fixReFocusForIe11() {
    if(detectIe11()) {
      var $select2 = $(this).find("+.select2");
      $select2.on("keyup",".select2-search__field", function(){
        window.requestAnimationFrame(function(){
          var $input = $select2.find(".select2-search__field");
          if(!$input.is(":focus")) {
            $input.each(HTMLInputElement.prototype.focus);
          }
        });
      });
    }
  }

  function detectIe11() {
    return Boolean(window.MSInputMethodContext) && Boolean(document.documentMode);
  }

  function onSelect(e) {
    handleChange.call(this, e, "add");
  }
  function onUnselect(e) { handleChange.call(this, e, "remove"); }

  function handleChange(e, action) {
    var
      $select = $(this),
      data = {
        equipmentCode: $select.data("machine-id"),
        equipmentTagName: e.params.args.data.text.trim()
      },
      url = $select.data("machine-tag-"+action+"-url")
    ;
    $.ajax({
      url: url,
      data: data,
      type: "post",
      success: function (response) {
        var $machineMenuTags = $(".machine-menu-tags");
        if($machineMenuTags.length) {
          Multivac.facetting.ensureTagFacetValue.call($machineMenuTags.find(".facet")[0],data.equipmentTagName);
        }
        Multivac.filter.refreshAllFilterCounters();
        ensureOption($(".machine-tags").not($select), data.equipmentTagName, false);
        handleSuccessMessage(response, action);
      },
      error: function() {
        var message = $select.data("machine-tag-error-notification");
        Multivac.notification.create(message, "error");
        handleAddError.call($select[0], data.equipmentTagName, action);
      }
    });
  }

  function handleSuccessMessage(response, action) {
    if (response) {
      if(action === "add") {
        Multivac.notification.create(response.message, "success");
      } else {
        Multivac.notification.create(response, "success");
      }
    }
  }

  function handleAddError(tagName, action) {
    var values = $(this).val(), i, undoAndRefresh = true;
    if (values) {
      i = values.indexOf(tagName);
      if(action === "add" && i>=0) {
        values.splice(i, 1);
      } else if (action === "remove" && i<0) {
        values.push(tagName);
      } else {
        undoAndRefresh = false;
      }
      if (undoAndRefresh) {
        $(this).val(values).change();
      }
    }
  }

  function select(tagName) {
    var $select = $(this);
    window.requestAnimationFrame(function(){
      var values = $select.val();
      if(-1 === values.indexOf(tagName)) {
        if($select.find("option[value='"+tagName+"']").length) {
          values.push(tagName);
          $select.val(values);
        } else {
          ensureOption($select, tagName, true);
        }
        $select
          .trigger("change")
          .trigger({
            type: "select2:selecting",
            params: { args: { data: { text: tagName } } }
          });
      }
    });
  }
  function ensureOption($selects, tagName, selected) {
      $selects.each(function () {
        var $select = $(this);
        if($select.find("option[value='"+tagName+"']").length === 0) {
          var option = new Option(tagName, tagName, selected, selected);
          $select.append(option);
        }
      });
  }
})(jQuery);
