Multivac.machine.services = (function ($) {
  var servicesLoaded = false;
  var trToBeDeleted = false;
  return {
    load: loadServices,
    create: {
      dialog: onOpenCreateDialog,
      success: onCreateSuccess
    },
    delete: {
      mem: memorizeEntry,
      submit: submitDeletion,
      success: onDeleteSuccess
    }
  };

  function loadServices() {
    if (!servicesLoaded) {
      $(this)
        .find(".machine-services__facet .facetting__form")
        .trigger("submit");
      servicesLoaded = true;
    }
  }

  function onOpenCreateDialog() {
    $("#createServicePopupAsync").html("").removeClass("data-reload-url--done");
    $("#createServicePopupAsyncTrigger").trigger("click");
  }

  function onCreateSuccess() {
    window.location.hash = "#";
    $(".services-load-trigger").trigger("click");
  }

  function memorizeEntry() {
    var $this = $(this);
    trToBeDeleted = $this.parents("tr")[0];
    $("#machineServiceDeleteId").val($this.data("service-code"));
  }

  function submitDeletion() {
    $("#machineServiceDeleteForm").trigger("submit");
  }

  function onDeleteSuccess() {
    $(trToBeDeleted).remove();
  }
})(jQuery);
