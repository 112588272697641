(function SybitFront_accordion($){
  "use strict";
  var
    collapsedClassname = "js-accordion--collapsed",
    expandedClassname = "js-accordion--expanded"
  ;

  $(function() {
    attachAll();
    $("body").on("ajaxreload", attachAll);
    $(window).on("resizingStarted", function() {
      $("."+expandedClassname+"[data-accordion]").each(function(){
        var
          axis = getAxis.call(this),
          $target = getBySelector.call(this, "target")
        ;
        $target.css(axis, "auto");
      });
    });
  });

  return {};

  function attachAll() {
    $("[data-accordion]:not(.accordion-attached)")
      .addClass("accordion-attached")
      .on("click", onClick)
      .each(init)
    ;
  }

  function init() {
    var
      axis = getAxis.call(this),
      $target = getBySelector.call(this, "target"),
      isOpen = $target.length && $target.eq(0)[axis]() !== 0,
      className = isOpen ? expandedClassname : collapsedClassname,
      open = /^true$/i.test($(this).data("accordion-open-on-dom-ready"))
    ;
    $target.add(this).addClass("js-accordion "+className);
    if(open && !isOpen) {
      $(this).trigger("click");
    }
  }

  function onClick() {
    var
      axis = getAxis.call(this),
      $source = getBySelector.call(this, "source"),
      $target = getBySelector.call(this, "target"),
      pixels = $source[axis]()
    ;
    if(!$target.length) {
      collapseAll.call(this, axis);
    } else {
      if ($target.is("."+collapsedClassname)) {
        collapseAll.call(this, axis);
        applyToTarget($target, axis, pixels);
      } else {
        applyToTarget($target, axis, 0);
      }
    }
  }

  function getAxis() {
    var axis = String($(this).data("accordion")).toLowerCase();
    if(axis !== "width") {
      axis = "height";
    }
    return axis;
  }

  function getBySelector(context) {
    var
      $this = $(this),
      relativeSelector = $this.data("accordion-"+context+"-selector"),
      absoluteSelector = $this.data("accordion-"+context+"-absolute-selector")
    ;
    return absoluteSelector ? $(absoluteSelector) : $this.find(relativeSelector);
  }

  function collapseAll(axis) {
    getBySelector
      .call(this, "collapse")
      .each(function(){
        applyToTarget($(this), axis, 0);
      });
  }

  function applyToTarget($target, axis, px) {
    var $trigger = getBySelector.call($target, "trigger");
    $target
      .css(axis,  px + "px")
      .add($trigger)
      .removeClass( px === 0 ? expandedClassname : collapsedClassname)
      .addClass( px === 0 ? collapsedClassname : expandedClassname)
    ;
  }
})(jQuery);
