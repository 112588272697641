SybitFront.form = (function($){
  "use strict";
  return {
    selfSubmit: function() { $(this).submit(); },
    selfSubmitWithButton: selfSubmitWithButton
  };

  function selfSubmitWithButton(){
    $('<input type="submit">').hide().appendTo(this).click().remove();
  }
})(jQuery);
