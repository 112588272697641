Multivac.cart = (function ($) {
  var
    $entryToModify = false,
    changePending = false,
    cartBulkButtonSelector =
      ".order-detail__add-selection-to-cart, " +
      ".quote-detail__add-selection-to-cart, " +
      ".product-detail__set-add-selection-to-cart, " +
      ".cart-template__add-selection-to-cart, " +
      ".order-detail__return-selection, " +
      ".order-detail__save-selection-as-template, " +
      ".quote-detail__save-selection-as-template"
  ;
  $(function () {
    initCartQtyInputs();
    initAddSelectionToCart();
    $("body").on("ajaxreload", initCartQtyInputs);
  });

  return {
    updateMiniCartCounter: updateMiniCartCounter,
    entry: {
      onDeleteClick: onDeleteEntryClick,
      onDeleteConfirm: onEntryDeleteConfirm,
      onDeleteAbort: onEntryDeleteAbort,
      onQtyChange: onEntryFormQtyChange,
      onEquipmentSerialNumbersChanged: onEntryEquipmentSerialNumbersChanged
    },
    modify: {
      submit: submitModification,
      onSuccess: onCartModifySuccess,
      onError: onCartModifyError
    },
    import: {
      onSubmit: onImportSubmit
    },
    bulk: {
      onGroupSelectChange: refreshBulkGroupSelectChange,
      onSubmit: onBulkSubmit
    },
    template: {
      onSaveDialogOpen: onCartSaveTemplateDialogOpen,
      delete: triggerTemplateDeleteForm
    },
    clearBeforePunchout: clearBeforePunchout,
    mandatory: {
      onChange: onMandatoryChange,
      onSubmit: onMandatorySubmit,
      onKeydown: onMandatoryKeydown
    },
    delete: deleteCart,
    buttons: {
      onLoad: onButtonsLoad
    },
    onCreateQuoteConfirm: onCreateQuoteConfirm
  };

  function updateMiniCartCounter(Data) {
    var
      quantity = Data.cartQty,
      notification = Data.notification
    ;
    if (notification) {
      if (notification.html) {
        Multivac.notification.create(
          notification.html,
          String(notification.type || "success").toLowerCase(),
          null,
          "html"
        );
      } else {
        Multivac.notification.create(notification.text, String(notification.type || "success").toLowerCase());
      }
    }
    updateCartQuantity(quantity);
  }

  function updateCartQuantity(quantity) {
    var $cartCounter = $(".meta-nav-entry__cart .meta-nav-entry__counter");
    if (!isNaN(quantity)) {
      $cartCounter.text(quantity);
      if (quantity) {
        $cartCounter.removeClass("meta-nav-entry__counter--0");
      } else {
        $cartCounter.addClass("meta-nav-entry__counter--0");
      }
    }
  }

  function onDeleteEntryClick() {
    var $input = $(this)
      .parents(".cart-entry")
      .find(".cart-entry__qty-form .input--number")
    ;
    $input.val(0);
    if (!changePending) {
      $input.trigger("change");
    }
  }

  function onEntryFormQtyChange() {
    var $input = $(this);
    if (!changePending) {
      changePending = true;
      window.setTimeout(function () {
        $entryToModify = $input.parents(".cart-entry");
        if ($input.val() === "0") {
          $(".cart__delete-confirm-modal").trigger("click");
        } else if ($input.val() === "") {
          $input.each(resetQuantity);
        } else {
          submitModification();
        }
        $input.removeClass("input--changed")
        changePending = false;
      }, 100);
    }
  }

  function onEntryDeleteConfirm() {
    submitModification();
  }

  function onEntryDeleteAbort() {
    $entryToModify
      .find(".cart-entry__qty-form .input--number")
      .each(resetQuantity)
    ;
  }

  function resetQuantity() {
    var $input = $(this);
    $input.val($input.parents(".cart-entry__qty-form").data("dom-ready-qty"));
  }

  function submitModification() {
    $entryToModify.find(".cart-entry__qty-form").trigger("submit");
  }

  function onCartModifySuccess() {
    window.requestAnimationFrame(function () {
      var quantity = $("[data-cart-refresh-count]").data("cart-refresh-count");
      updateCartQuantity(quantity);
    });
  }

  function onCartModifyError(xhr) {
    var $form = $entryToModify.find(".cart-entry__qty-form");
    $form.find(".input--number").each(resetQuantity);
    var params = [];
    if (typeof xhr !== "undefined" && !isNaN(xhr.status)) {
      params.push(xhr.status);
    }
    Multivac.ajax.handleError.apply($form[0], params);
  }

  function initCartQtyInputs() {
    $(".cart-entry__qty-form, .cart-entry__qty-number")
      .find(".input--number:not(.cart-keypress-attached)")
      .addClass("cart-keypress-attached")
      .each(initCartQtyInput);
  }

  function initCartQtyInput() {
    $(this).on("focus", onInputFocus);
  }

  function onInputFocus() {
    var $this = $(this);
    $this
      .on("keydown", onInputKeydown)
      .on("blur", onInputBlur)
    ;
  }

  function onInputBlur() {
    var
      $input = $(this).off("keydown", onInputKeydown),
      $form = $input.parents(".cart-entry__qty-form")
    ;
    if (parseInt($input.val()) !== parseInt($form.data("dom-ready-qty"))) {
      $input.trigger("change");
    }
  }

  function onInputKeydown(e) {
    if (e.keyCode === 38) {
      e.preventDefault();
      Multivac.inputQuantity.plus.call(this);
    }
    if (e.keyCode === 40) {
      e.preventDefault();
      Multivac.inputQuantity.minus.call(this);
    }
  }

  function onEntryEquipmentSerialNumbersChanged() {
    $(this).parents(".cart-entry__machine-serial").trigger("submit");
  }

  function onImportSubmit() {
    window.location.hash = "#";
  }

  function refreshBulkGroupSelectChange() {
    var
      $button = $(cartBulkButtonSelector),
      formSelector = $button.data("on-click-submit-absolute-selector"),
      $form
    ;
    if (!formSelector) {
      formSelector = $button.data("transfer-attr-target-absolute-selector");
    }
    $form = $(formSelector).find(".cart-entry__selector input:checked");
    if ($form.length > 0) {
      $button.removeAttr("disabled");
    } else {
      $button.attr("disabled", true);
    }
  }

  function initAddSelectionToCart() {
    $(cartBulkButtonSelector)
      .each(refreshBulkGroupSelectChange);
  }

  function triggerTemplateDeleteForm() {
    $(".cart-template__delete-form").trigger("submit");
  }

  function onCartSaveTemplateDialogOpen() {
    window.requestAnimationFrame(function () {
      $(".cart-action__save-template-form .input--text").focus();
    });
  }

  function clearBeforePunchout(e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    var
      $form = $(this),
      settings = {
        method: "GET",
        dataType: "text",
        complete: function(){$form[0].submit();}
      }
    ;
    $.ajax($form.data("cart-clear-url"),settings);
  }

  function onMandatoryKeydown() {
    var
      $input = $(this),
      cleanValue = $input.data("value")
    ;
    if (typeof cleanValue === "undefined") {
      cleanValue = $input.val();
      $input.data("value", cleanValue);
    }
    if($input.val() !== cleanValue) {
      markDirty($input);
    } else {
      unmarkDirty($input);
    }
  }
  function onMandatoryChange() {
    var
      $form = $(this),
      $input = $form.find("input"),
      cleanValue = $input.data("value")
    ;
    if(typeof cleanValue === "undefined" || $input.val() !== cleanValue) {
      Multivac.form.selfSubmitWithButton.call($form[0]);
    }
  }
  function onMandatorySubmit() {
    var $input = $(this).find("input");
    $input.data("value", $input.val());
    unmarkDirty($input);
  }

  function markDirty($input) {
    unmarkDirty($input);
    $input.data("dirty-timeout", window.setTimeout(function () {
      handleDirtyTimeout($input);
    }, 500));
  }
  function handleDirtyTimeout($input) {
    $input.trigger("change");
  }
  function unmarkDirty($input) {
    var timeout = $input.data("dirty-timeout");
    if(timeout) {
      window.clearTimeout(timeout);
      $input.removeData("dirty-timeout");
    }
  }
  function onBulkSubmit(e) {
    var
      selector = $(this).data("cart-entries-row"),
      $active = $(document.activeElement),
      action = $(this).attr("action")
    ;
    if($active.is(":not(body)")) {
      $active.blur();
    }
    if (action && !/^#/.test(action)) {
      $(selector).addClass("entries-bulk-submitting");
    }
  }
  function deleteCart() {
    $(".cart-delete__form").eq(0).trigger("submit");
  }
  function onButtonsLoad() {
    window.requestAnimationFrame(function(){
      Multivac.cart.validation.check();
      evaluateCartStatus();
    });
  }
  function onCreateQuoteConfirm() {
    var $modal = $(".cart-actions__create-quote");
    window.location.href = $modal.data("create-quote-url");
  }
  function evaluateCartStatus() {
    var
      $status = $(".cart-status"),
      hasPurchasables = /^true$/i.test($status.data("cart-has-purchasables")),
      hasNonPurchasables = /^true$/i.test($status.data("cart-has-non-purchasables")),
      hasShortFallings = /^true$/i.test($status.data("cart-has-short-fallings"))
    ;
    $(".cart")
      [ hasPurchasables ? "addClass" : "removeClass" ]("cart--has-purchasables")
      [ hasNonPurchasables ? "addClass" : "removeClass" ]("cart--has-non-purchasables")
      [ hasShortFallings ? "addClass" : "removeClass" ]("cart--has-short-fallings")
    ;
  }
})(jQuery);
