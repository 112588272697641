/**
 * Example:
 * $(window).on("resizingStarted resizingStep resizingFinished",function(e,eOrig){
 *   console.log(e.type+" <= "+eOrig.type);
 * });
 */

SybitFront.resizeHandler = (function($) {
  "use strict";
  var
    finishedTimeoutMs = 200,
    stepTimeoutMs = 50
  ;

  $(function () {
    var
      finishedTimeout = null,
      stepTimeout = null,
      $window = $(window)
    ;
    $window.on("resize", function(e){
      window.requestAnimationFrame(function(){handleResize(e);},0);
    });

    function handleResize(e) {
      if(finishedTimeout===null) {
        $window.trigger("resizingStarted", e);
      } else {
        window.clearTimeout(finishedTimeout);
      }
      finishedTimeout = window.setTimeout(handleFinishedTimeout, finishedTimeoutMs);

      if(stepTimeout===null) {
        $window.trigger("resizingStep", e);
        stepTimeout = window.setTimeout(function(){stepTimeout = null;}, stepTimeoutMs);
      }
      return true;

      function handleFinishedTimeout() {
        finishedTimeout = null;
        if(stepTimeout!==null) {
          window.clearTimeout(stepTimeout);
          stepTimeout = null;
        }
        $window
          .trigger("resizingStep",e)
          .trigger("resizingFinished",e);
      }
    }
  });

  return {
    "setReadyTimeout": setFinishedTimeout,
    "setStepMinInterval": setStepTimeout
  };

  /**
   * sets the check-timeout for firing "resizingFinished"
   * @param ms miliseconds
   */
  function setFinishedTimeout(ms) {
    finishedTimeoutMs = ms;
  }

  /**
   * sets the fire-interval for firing "resizingStep"
   * @param ms miliseconds
   */
  function setStepTimeout(ms) {
    stepTimeoutMs = ms;
  }
})(jQuery);
