Multivac.debtorChooser = (function($){
  return {
    formatSelectEntry: formatSelectEntry
  };

  function formatSelectEntry(config) {
    var
      address = SybitFront.util.String.escapeHtml($(config.element).data("b2bunit-address")),
      title = SybitFront.util.String.escapeHtml($(config.element).data("b2bunit-title"))
    ;
    return $("<span class='user-choose-debitor__entry-title'>"+title+"</span><span class='user-choose-debitor__entry-address'>"+address+"</span>");
  }
})(jQuery);
