(function _stupidtable($) {
  $(function () {
    window.requestAnimationFrame(function () {
      initAll();
      $("body").on("ajaxreload", initAll);
    });
  });

  function initAll() {
    $("[data-stupidtable]:not(.stupidtable-initialized)")
      .addClass("stupidtable-initialized")
      .each(initOne);
  }

  function initOne() {
    var
      $table = $(this),
      comparatorList = $table.data("stupidtable-comparators"),
      comparators = {}
    ;
    Multivac.util.setNameSpace("Multivac");
    if (comparatorList) {
      $.each(String(comparatorList).trim().replace(/\s*,$/,"").split(/\s*,\s*/), function (index, name) {
        Multivac.util.appendConfigFromDataAttr($table, "stupidtable-comparator-", name, comparators);
      });
    }
    $table.stupidtable(comparators);
  }
})(jQuery);
