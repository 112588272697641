(function SybitFront_inputTelephone($) {
  $(function () {
    initAll();
    $("body").on("ajaxreload", initAll);
  });

  function initAll() {
    $(".input-telephone:not(.input-telephone--initialized)")
      .addClass("input-telephone--initialized")
      .each(initOne);
  }

  function initOne() {
    $(this)
      .each(handleAppearance)
      .each(parseValue)
      .each(updateTooltip)
      .find(":input")
      .on("change", onChange)
    ;
  }

  function handleAppearance() {
    var $this = $(this);
    $this.find(".input-telephone__number, .input-telephone__country-selector")
      .on("mouseenter", function () {
        $this.addClass("input-telephone--hover");
      })
      .on("mouseleave", function () {
        $this.removeClass("input-telephone--hover");
      })
      .on("focus", function () {
        $this.addClass("input-telephone--focus");
      })
      .on("blur", function () {
        $this.removeClass("input-telephone--focus");
      });
    $this.find(".input-telephone__field").on("focus", function () {
      $this.find(".input-telephone__number").focus();
    });
  }

  function parseValue() {
    var
      $this = $(this),
      tel = cleanupNumber($this.find(".input-telephone__field").val()),
      $options = $this.find(".input-telephone__country-selector option").each(prepareOption),
      $selected = filterCountryCodeOptionByPhoneNumber($options, tel),
      countryCode = $selected.length ? $selected.data("clean-country-code") : "",
      nationalNumber = tel.substr(countryCode.length)
    ;
    $options.removeAttr("selected");
    $selected.attr("selected", "selected");
    $this.find(".input-telephone__number").val(nationalNumber);
  }

  function prepareOption() {
    var
      $this = $(this),
      ccc = cleanupCountryCode(String($this.data("country-code")))
    ;
    $this.data("clean-country-code", ccc);
  }

  function onChange() {
    $(this)
      .closest(".input-telephone")
      .each(checkCountryCodeEntered)
      .each(updateTooltip)
      .each(updateField)
    ;
  }

  function filterCountryCodeOptionByPhoneNumber($options, tel) {
    var $matchingOptions = $options
      .filter(function () {
        var ccc = $(this).data("clean-country-code");
        return ccc.length && tel.indexOf(ccc) === 0;
      })
    ;
    Array.prototype.sort.bind($matchingOptions)(matchComparator);
    return $matchingOptions.length ? $matchingOptions.eq(0) : $matchingOptions;
  }

  function matchComparator(a, b) {
    return $(a).data("clean-country-code").length < $(b).data("clean-country-code").length ? 1 : -1;
  }

  function cleanupCountryCode(cc) {
    return cc.replace(/[^\+\d]/g, "");
  }

  function cleanupNumber(number) {
    var hasPlus = false;
    var tel = number.trim();
    if (/^00/.test(tel)) {
      tel = tel.replace(/^00/, "+");
    }
    if (/^\+/.test(tel)) {
      hasPlus = true;
      tel = tel.replace(/^\+/, "");
    }
    tel = tel.replace(/\D/g, "");
    if (hasPlus) {
      tel = "+" + tel;
    }
    return tel;
  }

  function updateTooltip() {
    var
      $this = $(this),
      $select = $this.find(".input-telephone__country-selector"),
      content = $select.find("option:selected").data("country-tooltip") || $select.find("option[value='']").data("country-tooltip")
    ;
    $this.find(".tooltip__content").text(content);
  }

  function updateField() {
    var
      $this = $(this),
      ccc = $this.find(".input-telephone__country-selector option:selected").data("clean-country-code") || "",
      national = $this.find(".input-telephone__number").val().replace(/\D/g, "")
    ;
    $this
      .find(".input-telephone__field")
      .val(ccc + national)
      .end()
      .trigger("change")
      .each(parseValue)
      .each(updateTooltip)
    ;
  }

  function checkCountryCodeEntered() {
    var
      $this = $(this),
      national = $this.find(".input-telephone__number").val()
    ;
    if (/^(?:\+|00)/.test(national)) {
      $this
        .find(".input-telephone__field")
        .val(national)
        .end()
        .each(parseValue)
        .each(updateTooltip)
      ;
    }
  }
})(jQuery);
