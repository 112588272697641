Multivac.facetting.generic = (function($){
  return {
    onChange: onChange,
    onResponse: onResponse
  };

  function getFormOf(element) {
    return $(element).parents(".facetting").find(".facetting__form");
  }

  function onChange() {
    var $form = getFormOf(this);
    if($form.data("submitting")) {
      $form.data("submitPending", true);
    } else {
      $form.each(submit);
    }
  }

  function onResponse() {
    var $form = getFormOf(this);
    $form.data("submitting", false);
    if($form.data("submitPending")) {
      $form.data("submitPending", false).each(submit);
    }
  }

  function submit() {
    $(this).data("submitting",true).trigger("submit");
  }
})(jQuery);
