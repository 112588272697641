SybitFront.template = (function($){
  "use strict";
  return {
    text: {
      resolve: resolveText
    }
  };

  function resolveText() {
    var
      $collection = $(this),
      args = arguments
    ;
    $collection.each(function(){
      var
        $this = $(this),
        text = $this.data("text-template"),
        a
      ;
      for(a=0; a<args.length; a++) {
        text = text.replace(new RegExp("\\{"+a+"\\}"), args[a]);
      }
      $this.text(text);
    });
  }
})(jQuery);
