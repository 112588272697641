SybitFront.socket = (function($){

  $(function(){
    initAll();
    $("body").on("ajaxreload",initAll);
  });

  return {
    send: send
  };

  function initAll() {
    $("[data-socket-url]").each(initOne);
  }

  function initOne() {
    var
      $this = $(this),
      connection = createConnection.call(this),
      beforeSendFunc = $this.data("socket-before-send"),
      afterInitFunc = $this.data("socket-after-init"),
      beforeSend
    ;
    handleEventCallbacks.call(this, connection);
    if (beforeSendFunc) {
      beforeSend = SybitFront.util.retrieveFunction(beforeSendFunc);
    }
    $this.on("socket-send", function(event, data) {
      var beforeSendReturn;
      if(beforeSend) {
        beforeSendReturn = beforeSend.call(this, event, data, connection);
      }
      if(typeof beforeSendReturn !== "undefined") {
        data = beforeSendReturn;
      }
      if (!event.isDefaultPrevented()) {
        connection.send( data );
      }
    });
    if (afterInitFunc) {
      SybitFront.util.retrieveFunction(afterInitFunc).call(this, connection);
    }
  }

  function createConnection() {
    var
      $this = $(this),
      url = $this.data("socket-url"),
      protocols = String($this.data("socket-protocols") || ""),
      connection
    ;
    if (protocols) {
      protocols = cleanupSplitProtocolsString(protocols);
      connection = new WebSocket(url, protocols);
    } else {
      connection = new WebSocket(url);
    }
    return connection;
  }

  function cleanupSplitProtocolsString(protocols) {
    return protocols.trim().replace(/^[;,]\s*|\s*[;,]\s*[;,]\s*|\s*[;,]$/g,"").split(/[;,]/);
  }

  function handleEventCallbacks(connection) {
    var
      $this = $(this),
      onOpenFunc = $this.data("socket-on-open"),
      onMessageFunc = $this.data("socket-on-message"),
      onErrorFunc = $this.data("socket-on-error")
    ;
    if (onOpenFunc) {
      attachEventhandler.call(this, connection, "open", onOpenFunc);
    }
    if (onMessageFunc) {
      attachEventhandler.call(this, connection, "message", onMessageFunc);
    }
    if (onErrorFunc) {
      attachEventhandler.call(this, connection, "error", onErrorFunc);
    }
  }

  function attachEventhandler(connection, eventName, funcName) {
    var
      domElement = this,
      func = SybitFront.util.retrieveFunction(funcName)
    ;
    connection.addEventListener(eventName, function(event) {
      func.call(domElement, event, connection);
    });
  }

  function send(data, selector) {
    var $element;
    if(this instanceof HTMLElement) {
      if(selector) {
        $element = $(this).find(selector);
      } else {
        $element = $(this);
      }
    } else {
      $element = $(selector);
    }
    $element.trigger("socket-send", [data]);
  }
})(jQuery);
