(function _facetInColumns($) {
  var $body = $("body");

  $(function () {
    initFacets();
    $body.on("ajaxreload", initFacets);
  });

  /**
   *
   */
  function onMediaBreakpointChange(e, breakpoint) {
    refreshFacetOffset.apply(this || window, arguments);
  }

  /**
   * Der Facette bekanntgeben die wievielte von rechts sie ist
   * @param e           Event
   * @param breakpoint  Media-Breakpoint
   */
  function refreshFacetOffset(e, breakpoint) {
    $(".facetting__dialog-body").each(function(){
      var $facetValues = $(".facet__values")
          .removeClass("r-off-0 r-off-1 r-off-2 r-off-3")
          .addClass("r-off-x"),
        cols,
        c
      ;
      if (!breakpoint) {
        MediaBreakpoint.trigger();
      }
      if (breakpoint) {
        $facetValues.removeClass("r-off-x");
        switch (breakpoint) {
          case "small":
            cols = 1;
            break;
          case "smedium":
            cols = 2;
            break;
          case "medium":
            cols = 3;
            break;
          case "large":
            cols = 4;
            break;
          default:
            cols = 5;
            break;
        }
        for (c = 0; c < $facetValues.length; c++) {
          $facetValues.eq(c).addClass("r-off-" + ((cols - (c % cols)) - 1));
        }
      } else {
        $facetValues.addClass("r-off-x");
      }
    });
  }
  $(window).on("breakpoint-change", onMediaBreakpointChange);

  /**
   * Wieviele Spalten soll die Facette aufspannen können
   * Ab 10 .facet__value darf es 2-spaltig werden
   * Ab 20 .facet__value darf es 3-spaltig werden
   * Ab 30 .facet__value darf es 4-spaltig werden
   */
  function refreshFacetColClasses() {
    $(".facet__values").each(function () {
      var
        $this = $(this),
        maxCols = parseInt($this.data("max-cols")) || 5,
        breakItems = parseInt($this.data("break-items")) || 10,
        cnt = $this.find(".facet-value").length,
        cols = cnt > (maxCols*breakItems) ? maxCols : Math.ceil(cnt / breakItems),
        i
      ;
      for (i = 1; i <= cols; i++) {
        $this.addClass("allow-cols-" + i);
      }
    });
  }

  /**
   * Alle Facetten nach dem Laden aufbereiten
   */
  function initFacets() {
    if (!$(".facetting__dialog-body").length) {
      return;
    }
    refreshFacetColClasses();
    refreshFacetOffset(null, $body.attr("media-breakpoint"));
  }
})(jQuery);
