(function SybitFront_transferAttributes($){
  "use strict";
  SybitFront.transferAttributes = function(){
	  var
	    $source = $(this),
	    selectorAbs = $source.data("transfer-attr-target-absolute-selector"),
	    selectorRel = $source.data("transfer-attr-target-selector"),
	    $target = selectorAbs ? $(selectorAbs) : $source.find(selectorRel),
	    list = getList($source, "transfer")
    ;
    transferAll($source, $target, list);
  };

  SybitFront.removeAttributes = function(){
    var
      $source = $(this),
      selectorAbs = $source.data("remove-attr-target-absolute-selector"),
      selectorRel = $source.data("remove-attr-target-selector"),
      $target = selectorAbs ? $(selectorAbs) : $source.find(selectorRel),
      list = getList($source, "remove")
    ;
    removeAll($source, $target, list);
  };

  function getList($source, command) {
    return String($source.data(command+"-attr-list")).trim().split(/\s*[,;|]\s*/);
  }
  function transferAll($source, $target, list) {
    list.forEach(function(name){
      if(name) {
        var value = getAttrValue($source, name);
        transferAttr($target, name, value);
      }
    });
  }
  function getAttrValue($source, name) {
    return $source.data("transfer-attr-"+name);
  }
  function transferAttr($target, name, value) {
    $target.attr(name, value);
    if(/^data_/.test(name)) {
      $target.data(name.replace(/^data-/,""), value);
    }
  }
  function removeAll($source, $target, list) {
    list.forEach(function(name){
      if(name) {
        removeAttr($target, name);
      }
    });
  }
  function removeAttr($target, name) {
    $target.removeAttr(name);
    if(/^data_/.test(name)) {
      $target.removeData(name.replace(/^data-/,""));
    }
  }
})(jQuery);
