/**
 *  data-on-mouse-away-call => Function to be called when mouse is away
 *  data-on-mouse-away-distance => maximum distance
 *  data-on-mouse-away-timeout => trigger-timeout for beeing outside, but in distance
 *  data-on-mouse-away-of-selector => selects sub-elements to be away of
 */
(function SybitFront_on($) {
  "use strict";
  var
    idCounter = 0,
    configCount = 0,
    configs = {},
    inHandler = false,
    pendingEvent = false,
    pendingTimout = false
  ;
  $(function () {
    attachHandlers();
    $("body")
      .on("ajaxreload", attachHandlers)
      .on("mousemove", debounce)
    ;
  });

  function attachHandlers() {
    $("[data-on-mouse-away-call]:not(.mouse-away-attached)," +
      "[data-on-mouse-away-remove-class]:not(.mouse-away-attached)")
      .addClass("mouse-away-attached")
      .on("mouseleave", ensureAttached)
      .on("mouseenter", detachElement)
    ;
  }

  function ensureAttached() {
    if (!this.hasAttribute("mouse-away-id")) {
      attachElement.call(this);
    }
  }

  function attachElement() {
    var
      config = getConfig(this),
      id = pushConfig(config)
    ;
    window.setTimeout(function () {
      if (configs[id]) {
        trigger(config);
      }
    }, config.timeout);
  }

  function detachElement() {
    if (this.hasAttribute("mouse-away-id")) {
      var id = $(this).attr("mouse-away-id");
      removeConfig(id);
    }
  }

  function getConfig(element) {
    var
      $element = $(element),
      funcName = $element.data("on-mouse-away-call"),
      sources = $element.data("on-mouse-away-of-selector")
    ;
    return {
      $element: $element,
      $sources: sources ? $element.find(sources) : $element,
      func: funcName && SybitFront.util.retrieveFunction(funcName),
      distance: parseInt($element.data("on-mouse-away-distance")) || 32,
      timeout: parseInt($element.data("on-mouse-away-timeout")) || 1500,
      remove: $element.data("on-mouse-away-remove-class")
    };
  }

  function pushConfig(config) {
    idCounter++;
    config.$element.attr("mouse-away-id", idCounter);
    configs[idCounter] = config;
    configCount++;
    return idCounter;
  }

  function removeConfig(id) {
    configs[id].$element.removeAttr("mouse-away-id");
    configs[id] = undefined;
    delete configs[id];
    configCount--;
  }

  function debounce(event) {
    if (configCount) {
      window.requestAnimationFrame(function () {
        if (inHandler) {
          pendingEvent = event;
          if (!pendingTimout) {
            pendingTimout = window.setTimeout(rebounce, 50);
          }
        } else {
          inHandler = true;
          handle(event);
          inHandler = false;
        }
      });
    }
  }

  function rebounce() {
    inHandler = true;
    handle(pendingEvent);
    inHandler = false;
    pendingTimout = 0;
  }

  function handle(event) {
    var id, away, config, s;
    for (id in configs) {
      if (configs.hasOwnProperty(id)) {
        away = true;
        config = configs[id];
        for (s = 0; s < config.$sources.length; s++) {
          away = away && !isNear(config.$sources.eq(s), event, config.distance);
        }
        if (away) {
          trigger(config);
        }
      }
    }
  }

  function isNear($element, event, distance) {
    var
      left = $element.offset().left - distance,
      top = $element.offset().top - distance,
      right = left + $element.outerWidth() + 2 * distance,
      bottom = top + $element.outerHeight() + 2 * distance,
      x = event.pageX,
      y = event.pageY
    ;
    return (x > left && x < right && y > top && y < bottom);
  }

  function trigger(config) {
    config.$element.each(detachElement);
    if (config.remove) {
      config.$element.removeClass(config.remove);
    }
    if (config.func) {
      config.$element.each(config.func);
    }
  }
})(jQuery);
