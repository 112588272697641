Multivac.mainNav = (function ($) {
  var baseUrl;
  $(hightlight);

  function hightlight() {
    retrieveBaseUrl();
    var pageUrl = getPageUrl();
    var navBackUrl = getNavBackUrl();
    $(".js-main-nav-entry").each(function () {
      var mainNavUrl = extractMainNavUrl.call(this);
      if (pageUrl.indexOf(mainNavUrl.toString()) >= 0 || navBackUrl.indexOf(mainNavUrl.toString()) >= 0 ) {
        $(this).addClass("main-nav-entry--active");
      }
    });
  }

  function retrieveBaseUrl() {
    var $meta = $("meta[data-custom-name='hybris-base-url']");
    if(!$meta.length) {
      return "";
    }
    baseUrl = Multivac.util.url.convertAbsolute(
      String( $meta.data("custom-content") ).replace(/\/$/, "")
    );
  }

  function stripBaseUrl(url) {
    return String(url).replace(/([^:]\/)\//g, "$1").replace(baseUrl, "");
  }

  function stripCategoriesPath(url) {
    return String(url).replace(/.+?\/c\//, "/");
  }

  function extractMainNavUrl() {
    return stripCategoriesPath(
      stripBaseUrl(
        Multivac.util.url.convertAbsolute(
          $(this).find("a").attr("href")
        )
      )
    );
  }
  function getPageUrl() {
    return stripCategoriesPath( stripBaseUrl( window.location.href ) );
  }
  function getNavBackUrl() {
    var $navBack = $(".page__main a.nav-back");
    if(!$navBack.length) {
      return "";
    }
    return stripCategoriesPath(
      stripBaseUrl(
        Multivac.util.url.convertAbsolute(
          $navBack.attr("href")
        )
      )
    );
  }
})(jQuery);
