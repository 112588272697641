/**
 * Example:
 * $(window).on("scrollingStarted scrollingStep scrollingFinished",function(e,eOrig){
 *   console.log(e.type+" <= "+eOrig.type);
 * });
 */

SybitFront.scrollHandler = (function($) {
  "use strict";
  var
    finishedTimeoutMs = 200,
    stepTimeoutMs = 50
  ;

  $(function(){
    initWindow();
    initContainers();
    $("body").on("ajaxreload", initContainers);
  });

  return {
    "setReadyTimeout": setFinishedTimeout,
    "setStepMinInterval": setStepTimeout,
  };

  function initWindow() {
    $(window).each(initOne);
  }

  function initContainers() {
    $("[data-scroll-handler-container]")
      .filter(":not(.scroll-handler-attached)")
      .each(initOne)
      .addClass("scroll-handler-attached")
    ;
  }

  function initOne() {
    var
      finishedTimeout = null,
      stepTimeout = null,
      $this = $(this)
    ;
    $this.on("scroll", function(e){
      window.requestAnimationFrame(function(){handleScroll(e);},0);
    });

    function handleScroll(e) {
      if(finishedTimeout===null) {
        $this.trigger("scrollingStarted", e);
      } else {
        window.clearTimeout(finishedTimeout);
      }
      finishedTimeout = window.setTimeout(function(){handleFinishedTimeout(e);}, finishedTimeoutMs);

      if(stepTimeout===null) {
        $this.trigger("scrollingStep", e);
        stepTimeout = window.setTimeout(function(){stepTimeout = null;}, stepTimeoutMs);
      }
      return true;
    }

    function handleFinishedTimeout(e) {
      finishedTimeout = null;
      if(stepTimeout!==null) {
        window.clearTimeout(stepTimeout);
        stepTimeout = null;
      }
      $this
        .trigger("scrollingStep",e)
        .trigger("scrollingFinished",e);
    }
  }

  /**
   * sets the check-timeout for firing "scrollingFinished"
   * @param ms miliseconds
   */
  function setFinishedTimeout(ms) {
    finishedTimeoutMs = ms;
  }

  /**
   * sets the fire-interval for firing "scrollingStep"
   * @param ms miliseconds
   */
  function setStepTimeout(ms) {
    stepTimeoutMs = ms;
  }
})(jQuery);
