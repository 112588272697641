SybitFront.history = (function($){
  "use strict";
  $(function(){
    handleDomReady();
    $("body").on("ajaxreload", handleDomReady);
  });

  return {
    set: set
  };

  function handleDomReady() {
    $("[data-on-dom-ready-set-history]:not(.history-set)")
      .addClass("history-set")
      .last()
      .each(setHistoryByElement)
    ;
  }

  function setHistoryByElement() {
    var url = $(this).data("on-dom-ready-set-history");
    set(url);
  }

  function set(url) {
    url = $.trim(url);
    if(isHistoryApiSupported() === true && typeof url !== 'undefined' && url){
      window.history.replaceState(null, "", url);
    }
  }

  function isHistoryApiSupported() {
    return !!(window.history && history.replaceState);
  }
})(jQuery);
