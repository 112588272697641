SybitFront.Delegator = (function ($) {
  "use strict";
  var
    handlers = {},
    idCounter = 0,
    $body
  ;
  $(function () {
    $body = $("body");
    attachClickHandlers();
    $body.on("ajaxreload", attachClickHandlers);
  });

  return {
    on: {
      click: {
        direct: onDirect,
        inside: onInside,
        outside: onOutside
      }
    },
    off: off
  };

  function onDirect(obj, target, decider) {
    return on("direct", obj, target, decider);
  }

  function onInside(obj, target, decider) {
    return on("inside", obj, target, decider);
  }

  function onOutside(obj, target, decider) {
    return on("outside", obj, target, decider);
  }

  function on(ido, obj, target, decider) {
    var
      handler = {
        obj: $(obj),
        target: target,
        decider: decider,
        id: nextId(),
        ido: ido,
        func: null,
        off: null
      },
      clicktarget,
      doNotLoop = true
    ;

    switch (ido) {
      case "direct":
        handler.func = function (e) {
          if (e.target === this) {
            trigger.call(this, e);
          }
        };
        break;
      case "inside":
        handler.func = function (e) {
          trigger.call(this, e);
        };
        break;
      case "outside":
        handler.func = function (e) {
          if (!handler.obj.has(e.target).length) {
            trigger.call(this, e);
          }
        };
        break;
      default:
        console.error("Delegation unknown");
        return;
    }
    handler.off = off;
    clicktarget = (ido === "outside" ? $body : handler.obj);
    clicktarget.on("click", handler.func);
    handlers[handler.id] = handler;
    window.setTimeout(function () {
      doNotLoop = false;
    }, 0);
    return handler.id;

    function getTargetParams(handler) {
      var
        targetAbs,
        targetRel,
        ret = []
      ;
      if (handler.target) {
        ret = [target];
      } else {
        targetAbs = handler.obj.data("delegate-click-target-absolute-selector");
        targetRel = handler.obj.data("delegate-click-target-selector");
        if (targetAbs) {
          ret = [targetAbs];
        } else if (targetRel) {
          ret = [targetRel, handler.obj];
        }
      }
      return ret;
    }

    function getDecider(handler) {
      var decider, ret = false;
      if (typeof handler.decider === "function") {
        ret = handler.decider;
      } else {
        decider = handler.obj.data("delegate-click-decider");
        if (decider) {
          ret = SybitFront.util.retrieveFunction(decider);
        }
      }
      return ret;
    }

    function trigger(e) {
      var
        $target = $.apply(null, getTargetParams(handler)),
        decider
      ;
      if ($target.has(this).length || $target.has(e.target).length) {
        return; // original click was inside the delegate-target - do not fire!
      }
      decider = getDecider(handler);
      if (!doNotLoop) {
        if (typeof decider === "function" && false === decider.apply(this, arguments)) {
          return;
        }
        doNotLoop = true;
        $target.eq(0).trigger(e.type);
        doNotLoop = false;
        if (/^true$/i.test(handler.obj.data("delegate-click-once"))) {
          off();
        }
      }
    }

    function off() {
      SybitFront.Delegator.off(handler.id);
    }
  }

  function off(id) {
    var handler = handlers[id];
    (handler.ido === "outside" ? $body : handler.obj).off("click", handler.func);
    $(handler.target).off("click", handler.off);
    handlers[id] = false;
  }

  function nextId() {
    return idCounter++;
  }

  function attachClickHandlers() {
    $("[data-delegate-click]:not(.delegate-click-attached)")
      .addClass("delegate-click-attached")
      .each(attachClickHandler);
  }

  function attachClickHandler() {
    var
      $this = $(this),
      ido = $this.data("delegate-click"),
      meth = "inside",
      params = [$this]
    ;
    switch (ido) {
      case "direct":
      case "outside":
        meth = ido;
        break;
      default:
        break;
    }
    SybitFront.Delegator.on.click[meth].apply(null, params);
  }
})(jQuery);
