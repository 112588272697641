Multivac.cart.quickadd = (function($){
  return {
    searchOnKeyPress: quickAddSearchKeypress,
    search: quickAddSearch,
    onEnterPreventDefault: onEnterPreventDefault,
    abort: abort
  };

  function  quickAddSearchKeypress(e) {
    if(onEnterPreventDefault(e)) {
      quickAddSearch.call(this);
    }
  }

  function onEnterPreventDefault(e) {
    if(e.keyCode === 13) {
      e.preventDefault();
      return true;
    }
    return false;
  }

  function quickAddSearch() {
    if(checkCleared.call(this) || !checkQuickAddSearchRealyChanged.call(this)) {
      return;
    }
    doDebouncedSearch.call(this);
  }

  function checkQuickAddSearchRealyChanged() {
    var
      $this = $(this),
      val = $this.val(),
      result = $this.data("lastvalue") !== val
    ;
    if(result) {
      $this.data("lastvalue", val);
    }
    return result;
  }

  function abort() {
    $(this).parents(".cart-quickadd__entry").find(".cart-quickadd__search .input").val("").trigger("change");
  }

  function checkCleared() {
    var $this = $(this);
    if (/^\s*$/.test($this.val())) {
      $this.data("lastvalue","");
      resetQuickaddSearchEntry.call($this.parents(".cart-quickadd__entry"));
      refreshCartBatchTable();
      return true;
    }
    return false;
  }

  function doDebouncedSearch() {
    var $entry = $(this).parents(".cart-quickadd__entry");
    if($entry.is(".cart-quickadd__entry--search-running")) {
      $entry.addClass("cart-quickadd__entry--search-again");
    } else {
      doSearch.call(this);
    }
  }

  function doSearch() {
    var
      $input = $(this),
      $entry = $input.parents(".cart-quickadd__entry"),
      url = $input.data("quickadd-load-product-url"),
      ajaxPayload = retrieveAjaxPayload.call(this),
      ajaxSettings = $.extend(ajaxPayload, {method: "GET", context: $entry[0]}, retrieveAjaxHandlers())
    ;
    $entry
      .each(resetQuickaddSearchEntry)
      .addClass("cart-quickadd__entry--search-running")
    ;
    $.ajax(url, ajaxSettings);
  }

  function retrieveAjaxPayload() {
    var $input = $(this), knownProductCodes = [];
    $(".cart-quickadd__form .cart-quickadd__material-id input").each(function(){
      var val = $(this).val();
      if(/\S/.test(val)) {
        knownProductCodes.push(val);
      }
    });
    return {
      data: {
        productCode: $input.val(),
        knownProductCodes: knownProductCodes
      }
    };
  }

  function retrieveAjaxHandlers() {
    return {
      success: quickaddSearchEntrySuccess,
      error: quickaddSearchEntryGenericError,
      complete: quickaddSearchEntryComplete
    };
  }

  function quickaddSearchEntrySuccess(data) {
    var $this = $(this);
    if (String(data.status).toLowerCase() === "success") {
      $this.addClass("cart-quickadd__entry--search-success");
      fillQuickaddSearchEntry.call(this, data);
      refreshCartBatchTable();
    } else if(String(data.status).toLowerCase() === "existing") {
      window.requestAnimationFrame(function(){
        abort.call($this.find(".cart-quickadd__search .input"));
      });
    } else if(!data.productCode) {
      $(this).addClass("cart-quickadd__entry--search-error");
    }
    if(data.productCode) {
      focusQty(String(data.productCode));
    }
    if(data.notification && data.notification.message) {
      Multivac.notification.create(data.notification.message, data.notification.type || "warning");
    }
  }

  function focusQty(productCode) {
    $(".cart-quickadd__material-id input")
      .filter(function(){return $(this).val()===productCode;})
      .eq(0)
      .parents(".cart-quickadd__entry")
      .find(".cart-quickadd__qty .input")
      .focus();
  }

  function quickaddSearchEntryGenericError() {
    var msg = $(this).find(".cart-quickadd__search .input").data("quickadd-load-error-message");
    Multivac.notification.create(msg, "error");
    $(this).addClass("cart-quickadd__entry--search-error");
  }
  function quickaddSearchEntryComplete() {
    var $entry = $(this);
    $entry.removeClass("cart-quickadd__entry--search-running");
    if($entry.is(".cart-quickadd__entry--search-again")) {
      $entry
        .removeClass("cart-quickadd__entry--search-again")
        .each(doDebouncedSearch);
    }
  }

  function resetQuickaddSearchEntry() {
    var $entry = $(this);
    $entry.removeClass("cart-quickadd__entry--search-error cart-quickadd__entry--search-success");
    $entry.find(".cart-quickadd__qty .input").val(1);
    $entry.find(".cart-quickadd__qty .input__label").text("");
    $entry.find(".cart-quickadd__name").text("");
    $entry.find(".cart-quickadd__material-id span").text("");
    $entry.find(".cart-quickadd__material-id input").val("");
  }

  function fillQuickaddSearchEntry(data) {
    var $entry = $(this);
    $entry.find(".cart-quickadd__qty .input__label").text(data.unit);
    $entry.find(".cart-quickadd__name").text(data.name);
    $entry.find(".cart-quickadd__material-id span").text(data.productCode);
    $entry.find(".cart-quickadd__material-id input").val(data.productCode);
    if(data.price) {
      $entry.find(".cart-quickadd__unit-price").text(data.price);
    }
  }

  function refreshCartBatchTable() {
    var
      $cartBatch = $(".cart-quickadd__form .cart-quickadd__table"),
      $success = $cartBatch.find(".cart-quickadd__entry--search-success"),
      $entries = $cartBatch.find(".cart-quickadd__entry:not(" +
        ".cart-quickadd__entry--search-success," +
        ".cart-quickadd__entry--search-running," +
        ".cart-quickadd__entry--search-error)")
    ;
    if($success.length === 0) {
      $cartBatch.removeClass("cart-quickadd--search-success");
    } else {
      $cartBatch.addClass("cart-quickadd--search-success");
    }
    if($entries.length === 0) {
      appendNextPrototype();
    }
  }

  function appendNextPrototype() {
    $(".cart-quickadd--prototype").trigger("click");
  }
})(jQuery);
