/* used by clone */
SybitFront.formgroup = (function($) {
  "use strict";
  function add($trg, $group, groupName, b4) {
    var
      count = getNextIndex(getForm($trg),groupName),
      $b4 = b4 !== false && $trg.find(b4).eq(0)
    ;
    handleNewGroup($group, groupName, count);
    if($b4 && $b4.length) {
      $group.insertBefore($b4);
    } else {
      $trg.append($group);
    }
    $("body").trigger("ajaxreload");
  }

  function del($group, groupName) {
    var $form = getForm($group);
    $group.remove();
    renumGroups($form, groupName);
  }

  /**
   * returns $form
   * @param $element
   */
  function getForm($element) {
    return $element.closest("form");
  }

  /**
   * to call on the $form, when the order has changed or items where deleted
   * @param $form
   * @param groupName
   */
  function renumGroups($form, groupName) {
    var map=[],oldIndex,newIndex=0;
    getFields($form, groupName).each(function(){
      var
        match = $(this).attr("name").substr(groupName.length).match(/\[(\d+)\]/),
        index = match[1]
      ;
      if(typeof map[index]==="undefined") {
        map[index] = $(this);
      } else {
        map[index] = map[index].add(this);
      }
    });
    for(oldIndex in map) {
      if(map.hasOwnProperty(oldIndex)) {
        renumInputs(map[oldIndex], groupName, newIndex);
        newIndex++;
      }
    }
  }

  /**
   *
   * @param $inputs
   * @param groupName
   * @param newIndex
   */
  function renumInputs($inputs, groupName, newIndex) {
    var len = groupName.length;
    $inputs.each(function(){
      var $this = $(this);
      $this.attr("name",
        groupName + $this.attr("name").substr(len).replace(/\[\d+\]/,"["+newIndex+"]")
      );
    });
  }

  /**
   * returns current formgroup-Array-Index +1 by groupName
   * @param $form
   * @param groupName
   * @return {number}
   */
  function getNextIndex($form, groupName) {
    var
      $fields = getFields($form, groupName),
      $last, match
    ;
    if(!$fields.length) {
      return 0;
    }
    $last = $fields.eq(-1);
    match = $last.attr("name").substr(groupName.length).match(/\[(\d+)\]/);
    return match?(parseInt(match[1])+1):0;
  }

  function getFields($form, groupName) {
    return $form.find(":input[name^="+groupName+"]");
  }

  /**
   * takes normal input names and applies Array-Logic to them by formgroup-groupName
   * @param $group
   * @param groupName
   * @param count
   */
  function handleNewGroup($group, groupName, count) {
    var len = groupName.length;
    $group
      .find(":input[name]")
      .each(function(){
      var $this = $(this), name = $this.attr("name");
      if(name.substr(0,len)===groupName) {
        $this.attr("name", groupName+name.substr(len).replace(/\[\d*\]/,"["+count+"]"));
      } else {
        $this.attr("name",groupName+"["+count+"]["+name+"]");
      }
    });
  }

  return {
    add: add,
    del: del
  };
})(jQuery);
