SybitFront.topFixed = (function($){
  "use strict";
  var
    list = [],
    checking = false,
    checkAgain = false
  ;

  $(function(){
    registerAll();
    $("body").on("ajaxreload", registerAll);
    $(document).on("scroll",debounceScrollHandler);
  });

  return {
    register: registerOne
  };

  function registerAll() {
    $("[data-out-of-top-bounds]:not(.ootb-registered)")
      .each(registerOne)
      .addClass("ootb-registered");
    debounceScrollHandler();
  }

  function registerOne() {
    var
      $this = $(this),
      className = $this.data("out-of-top-bounds")
    ;
    list.push({
      $element: $this,
      className: className || "out-of-top-bounds"
    });
  }

  function debounceScrollHandler() {
    window.requestAnimationFrame(function(){
      if(checking) {
        checkAgain = true;
      } else {
        checking = true;
        checkAll();
        checking = false;
        if(checkAgain) {
          checkAgain = false;
          debounceScrollHandler();
        }
      }
    });
  }

  function checkAll() {
    var scrollTop = $(document).scrollTop();
    for (var i=0; i<list.length; i++) {
      checkOne(scrollTop, list[i].$element, list[i].className);
    }
  }

  function checkOne(scrollTop, $element, className) {
    if($element.offset().top < scrollTop) {
      $element.addClass(className);
    } else {
      $element.removeClass(className);
    }
  }

})(jQuery);
