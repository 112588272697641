SybitFront.load = (function($){
  "use strict";
  return {
    single: single,
    serial: serial,
    parallel: parallel
  };

  function serial(urls, success, error, complete) {
    var
      url = urls.shift(),
      data = typeof arguments[4]==="undefined" ? [] : arguments[4],
      d = typeof arguments[5]==="undefined" ? 0 : arguments[4]
    ;
    if(urls.length) {
      single(
        url,
        function(response) {
          data[d] = response;
          serial(urls, success, error, complete, data, d+1);
        },
        error
      );
    } else {
      single(
        url,
        function(){
          data[d] = response;
          success.apply(null, data);
        },
        error,
        function(){
          complete.apply(null, data);
        }
      );
    }
  }

  function parallel(urls, success, error, complete) {
    var
      data = [],
      u,
      successCount = 0,
      completeCount = 0
    ;
    for (u = 0; u < urls.length; u++) {
      (function(){
        var d = u;
        single(
          urls[u],
          function(response) {
            successCount++;
            data[d] = response;
            if(successCount === urls.length) {
              success.apply(null, data);
            }
          },
          error,
          function() {
            completeCount++;
            if(completeCount === urls.length) {
              complete.apply(null, data);
            }
          }
        );
      })();
    }
  }

  function single(url, success, error, complete) {
    var ajaxSettings = {dataType: getType(url) };
    if (typeof complete === "function") { ajaxSettings.complete = complete; }
    if (typeof success === "function") { ajaxSettings.success = success; }
    if (typeof error !== "function") {
      error = function() {
        if (typeof xhr !== "undefined" && !isNaN(xhr.status)) {
          SybitFront.ajax.handleError(xhr.status, "echart");
        }
      };
    }
    ajaxSettings.error = error;
    $.ajax(url, ajaxSettings);
  }

  function getType(url) {
    if(/\.js(?:\?.+)?$/.test(url)) {
      return "script";
    }
    return "json";
  }
})(jQuery);
