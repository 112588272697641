Multivac.remoteAssistance = (function ($) {

  $('.remote-assistance-form__privacy-policy-checkbox').change(function() {
    var $checkbox = $(".remote-assistance-form__privacy-policy-checkbox").find(".checkbox__native").get(0).checked
    var $button = $('.remote-assistance__form-submit').find('.button');

    if($checkbox) {
      $button.prop('disabled', false);
    } else {
      $button.prop('disabled', true);
    }
  });

  return {};
})(jQuery);
