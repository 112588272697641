SybitFront.gridOptimizeGroups = (function ($) {
  "use strict";

  $(function () {
    $(window).on("breakpoint-change", handleBreakpointChange);
  });

  return {};

  function handleBreakpointChange() {
    $('[data-grid-optimize-selector]' +
      '[data-grid-optimize-element-selector]' +
      '[data-grid-optimize-max-column-count]'
    ).each(handleGridResize)
  }

  function handleGridResize(e, breakpoint) {
    var $grid = $(this);
    var elementSelector = $grid.data('grid-optimize-element-selector');
    var $groups = $grid.find($grid.data('grid-optimize-selector'))
    processElementCount($grid, $groups, elementSelector)
    requestAnimationFrame(function () {
      var gridColCount = processCurrentColCount($grid, $groups, elementSelector)
      optimizeColumns($grid, $groups, gridColCount)
    })
  }

  function processElementCount($grid, $groups, elementSelector) {
    var countMax = parseInt($grid.data('grid-optimize-max-column-count'));
    $groups.each(function () {
      var $group = $(this);
      var count = $group.find(elementSelector).length;
      $group.attr('data-grid-optimize-element-count', Math.min(count, countMax));
    })
  }

  function processCurrentColCount($grid, $groups, elementSelector) {
    var biggest = [null, 0]
    $groups.filter(':has(' + elementSelector + ')').each(function () {
      var $group = $(this);
      var $elements = $group.find(elementSelector)
      var minOffset = $elements.eq(0).offset().top
      var count = $elements.filter(function () {
        return $(this).offset().top <= minOffset
      }).length || 0
      $group.attr('data-grid-optimize-col-count', count);
      if (biggest[1] < count) {
        biggest = [$group.width(), count]
      }
    })
    var width = biggest[0],
      cols = biggest[1];
    return Math.round($grid.width() / (width / cols))
  }

  function optimizeColumns($grid, $groups, gridColCount) {
    var order = 1
    var missing = 0
    $groups.removeClass('grid-optimize-ordered').each(function () {
      var $group = $(this)
      if (!$group.hasClass('grid-optimize-ordered')) {
        $group.css({order: order}).addClass('grid-optimize-ordered')
        missing = gridColCount - parseInt($group.attr('data-grid-optimize-col-count'))
        order++
        if (missing) {
          order = handleMissing(missing, $groups.filter(':not(.grid-optimize-ordered)'), order)
          missing = 0
        }
      }
    })
  }

  function handleMissing(missing, $groups, order) {
    for (var i = missing; i > 0 && missing; i--) {
      $groups.filter('[data-grid-optimize-col-count=' + i + ']').each(function () {
        if (missing >= i) {
          $(this).css({order: order}).addClass('grid-optimize-ordered')
          missing -= i
          order++
        }
      })
    }
    return order
  }
})(jQuery);
