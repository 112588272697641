Multivac.customname = (function($){
  return {
    assigne: function(data) {
      $(".js-page__title-name").css("display", "none");
      $(".machine-set-custom-name-form").css("display", "flex");
      $("#customerSpecificName").focus();
      var csp = $(this).data("customer-specific-name");
      $("#customerSpecificName").val(csp)
    },
    cancel: function(e) {
      $(".machine-set-custom-name-form").css("display", "none");
      $(".js-page__title-name").css("display", "flex");
    }
  }
})(jQuery);
