SybitFront.droppable = (function($){
  "use strict";
  $(function(){
    attachHandlers();
    $("body").on("ajaxreload", attachHandlers);
  });

  return {};

  function attachHandlers() {
    $("[data-droppable='true']:not(.droppable-attached)")
      .addClass("droppable-attached")
      .each(attachHandler);
  }

  function attachHandler() {
    var
      params = getParams.call(this),
      $this = $(this)
    ;
    $this.droppable(params);
  }

  function getParams() {
    return $.extend(
      getAcceptParams.call(this),
      getClassParams.call(this),
      getEventHandlerParams.call(this)
    );
  }

  function getAcceptParams() {
    var
      $this = $(this),
      accept = $this.data("droppable-accept"),
      tolerance = $this.data("droppable-tolerance"),
      params = {}
    ;
    if(accept) {
      params.accept = accept;
    }
    if(/^(?:fit|intersect|pointer|touch)$/.test(tolerance)) {
      params.tolerance = tolerance;
    }
    return params;
  }

  function getClassParams() {
    var
      $this = $(this),
      params = {},
      active = $this.data("droppable-active-classname"),
      hover = $this.data("droppable-hover-classname")
    ;
    if(active) {
      params.classes = params.classes || {};
      params.classes.active = active;
    }
    if(hover) {
      params.classes = params.classes || {};
      params.classes.hover = hover;
    }
    return params;
  }

  function getEventHandlerParams() {
    var
      $this = $(this),
      params = {},
      onDropName = $this.data("droppable-on-drop"),
      onDrop = onDropName ? SybitFront.util.retrieveFunction(onDropName) : false
    ;
    if(onDrop) {
      params.drop = onDrop;
    }
    return params;
  }
})(jQuery);
