SybitFront.notification = (function($){
  "use strict";
  var
    timeoutPerWord = 400,
    minTimeout = 3000,  //miliseconds
    stdType = "info",
    onBeforeRender = false
  ;

  $(function(){
    checkCookieNotification();
    checkDomReady();
    $("body").on("ajaxreload", checkDomReady);
    checkContainerVisible();
    $(document).on("scroll",checkContainerVisible);
  });


  return {  // public interface
    create: create,
    setMinTimeout: function(timeout){minTimeout = timeout;},
    setDefaultType: function(type){stdType = type;},
    setOnBeforeRender: function(func){
      onBeforeRender = func;
    }
  };

  function checkCookieNotification() {
    $(".cookie-notification").each(function(){
      if("true"===window.localStorage.getItem("cookiesAccepted")) {
        $(this).remove();
      } else {
        $(this).removeClass("cookie-notification").addClass("dom-ready-notification");
      }
    });
    $("body").on("click",".cookie-notification__button",function(){
      var $notification = $(this).closest(".notification");
      window.localStorage.setItem("cookiesAccepted","true");
      $notification.addClass("notification__fadeout").removeClass("notification__fadein");
      window.setTimeout(function(){
        $notification.remove();
      },2000);
    });
  }

  function checkDomReady() {
    $(".dom-ready-notification").each(function(){
      var
        $n = $(this),
        html = $n.find(".dom-ready-notification__html").html(),
        content = html?html:$n.find(".dom-ready-notification__text").text().trim(),
        type = validateType($n.find(".dom-ready-notification__type").text().trim()),
        timeout = validateTimeout(
          $n.find(".dom-ready-notification__timeout").text().trim(),
          content,
          html?"html":"text"
        )
      ;
      $n.remove();
      if(content) {
        create(content, type, timeout, html?"html":"text");
      }
    });
  }

  /**
   * Creates a notification, shows and returns its Jquery-instance
   * @param content     Content according to contentType
   * @param type        ["error","warning","cookie","info"] (optional -> outofbounds defaulted to stdType)
   * @param timeout     in miliseconds  (optional -> minTimeout)
   * @param contentType ["html","text"] (optional -> autodetect)
   */
  function create(content, type, timeout, contentType) {
    var $notification;
    type = validateType(type);
    timeout = validateTimeout(timeout, content || "", contentType);
    $notification = $("<div/>").addClass("notification notification__"+type).data("notification-timeout", timeout);
    applyContent($notification, content || "", contentType||"");
    if(typeof onBeforeRender==="function") {
      onBeforeRender.call($notification[0],ensureContainer(),ensureFloater());
    }
    $notification.appendTo(ensureFloater());
    window.requestAnimationFrame(function(){
      window.setTimeout(function(){$notification.addClass("notification__fadein");},0);
      window.setTimeout(function(){
        $notification.addClass("notification__fadeout").removeClass("notification__fadein");
        window.setTimeout(function(){
          $notification.remove();
        },500);
      },timeout);
    });
    return $notification;
  }

  /**
   * creates/returns notification-container (self-cached)
   * @returns JQuery
   */
  function ensureContainer() {
    var $container = $(".notification__container").last();
    if(!$container.length) {
      $container = $("<div/>")
        .addClass("notification__container")
        .each(function(){
          var $header = $(".header").eq(0);
          if($header.length) {
            $header.after(this);
          } else {
            $("body").prepend(this);
          }
        });
    }
    return $container;
  }

  function ensureFloater() {
    var $floater = $(".notification__floater").last();
    if(!$floater.length) {
      $floater = $("<div/>")
        .addClass("notification__floater")
        .appendTo(ensureContainer())
      ;
    }
    return $floater;
  }

  /**
   * Attaches content to an Jquery-instance den Content nach seinem contentType hinzu (wenn nicht angegeben, dann wird contentType detektiert)
   * @param $notification Jquery
   * @param content
   * @param contentType ["text"|"html"] optional (autodetect)
   */
  function applyContent($notification ,content, contentType) {
    if(content instanceof $) {
      $notification.html("").append(content);
    } else {
      if (!contentType || !/^(?:html|text)$/.test(contentType)) {
        contentType = /^\s*<\w+(?:\s+(?:\w+|\w+=(?:\w+|"[^"]+"|'[^']+')))*\/?>/.test(content) ? "html" : "text";
      }
      $notification[contentType](content);
    }
  }

  /**
   * validates notification-type with fallback to stdType
   * @param type string ["error"|"warning"|stdType]
   * @returns string
   */
  function validateType(type) {
    var lowerType = String(type).toLowerCase();
    switch(lowerType) {
      case "error":
      case "warning":
      case "cookie":
        break;
      default:
        lowerType = stdType;
    }
    return lowerType;
  }

  /**
   * validates Timeout between 250ms and 10000ms with fallback to minTimeout
   * @param timeout number
   * @param content string (text oder html)
   * @param contentType string ["text"|"html"]
   * @returns number
   */
  function validateTimeout(timeout, content, contentType) {
    if(!timeout) {
      timeout = countWords(content, contentType)*timeoutPerWord;
    }
    if(timeout < minTimeout) {
      timeout = minTimeout;
    }
    return timeout;
  }

  function countWords(content, contentType) {
    var cnt1, match, cnt2;
    if(/html/i.test(contentType)) {
      content = $("<div/>").html(content).text();
    }
    cnt1 = content.replace(/[\u3400-\u9FBF]/g,"").split(/[^\wäöüßÄÖÜ](?=\w)/g).length;
    match = content.match(/[\u3400-\u9FBF]/g)||[];
    cnt2 = match.length||0;
    return (isNaN(cnt1)?0:cnt1)+(isNaN(cnt2)?0:cnt2);
  }

  function checkContainerVisible() {
    var $container = ensureContainer();
    if($container.offset().top < $(document).scrollTop()) {
      $container.addClass("outOfTopBounds");
    } else {
      $container.removeClass("outOfTopBounds");
    }
  }
})(jQuery);
