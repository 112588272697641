(function SybitFront_timepicker($) {
  "use strict";


  $(function () {
      let pageLang = document.documentElement.lang;
      let timeFormat = 'h:i A'
      if (pageLang === "de") {
        timeFormat = 'H:i'
      }

      let config = {'timeFormat': timeFormat, 'scrollDefault': 'now'}

      initAll();
      $("body").on("ajaxreload", initAll);

      function initAll() {
        $(".timepicker:not(.timepicker--initialized)")
          .addClass("timepicker--initialized")
          .each(initOne);
      }

      function initOne() {
        var
          $this = $(this),
          $altField = getAltField(this),
          value = $altField.val(),
          time = value ? $this.val() : null;

        $this
          .timepicker(config)
          .timepicker("setTime", time)
          .on("changeTime", onTimePickerChange);
      }

      function onTimePickerChange(e) {
        var $altField = getAltField(this);

        if (!$(this).val()) {
          $altField.val("");
        } else {
          $altField.val($(this).val())
        }
      }

      function getAltField(timepicker) {
        return $(timepicker).find("+.timepicker-alt-field");
      }
    }
  );
})(jQuery);
