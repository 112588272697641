Multivac.userTable = (function ($) {
  var $deleteForm = false;

  return {
    rememberForAccessRestriction: rememberForAccessRestriction,
    restrictAccess: restrictAccess
  };

  function showNotification(notification) {
    Multivac.notification.create(notification.text, notification.type);
  }

  function rememberForAccessRestriction() {
    $deleteForm = $(this)
      .parents(".user-customers-table__action")
      .find(".user-customers-table__restrict-access-form")
    ;
  }

  function restrictAccess() {
    $deleteForm.trigger("submit");
  }
})(jQuery);
