Multivac.search = (function($){
  return {
    checkValidity: function(_e) {
      var $this = $(this);
      if ($this.is("input")) {
        postponedCheckValidity(this);
      } else {
        checkValidityNow($this.find("input")[0]);
      }
    }
  };

  function postponedCheckValidity(input) {
    window.requestAnimationFrame(function(){
      checkValidityNow(input);
    });
  }

  function checkValidityNow(input) {
    var
      $form = $(input).parents(".header__search"),
      pattern = $form.data("search-validator-pattern"),
      regexp = new RegExp(pattern),
      msg = ""
    ;
    if (!regexp.test($(input).val())) {
      msg = $form.data("search-invalid-message");
    }
    input.setCustomValidity(msg);
  }
})(jQuery);
