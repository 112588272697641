Multivac.service = (function($){
  $(function(){
    if(window.location.hash === '#customer-number-import-dialog' &&
      !areTermsAccepted()
    ) {
      window.location.hash = '#';
    }
  });

  return {
    customerNumber: {
      checkTerms: checkCustomerNumberTerms,
      onSubmit:  onCustomerNumberSubmit,
      onChange: onFiledropperChange
    },
    attachmentUpload: {
      onSuccess: onAttachmentUploadSuccess
    }
  };
  function onAttachmentUploadSuccess() {
    var $this = $(this)
    location.href = $this.data("ajax-on-success-redirect");
  }
  function checkCustomerNumberTerms(e) {
    var customerNumberImport = document.querySelector(".customer-number-import");
    var accepted = areTermsAccepted();
    if(accepted) {
      customerNumberImport.classList.remove("customer-number-import--terms-error");
    } else {
      customerNumberImport.classList.add("customer-number-import--terms-error");
      if(e.currentTarget.nodeName.toLowerCase() === 'a') {
        e.preventDefault();
      }
    }
  }
  function areTermsAccepted() {
    var checkbox = document.querySelector(".customer-number-import__terms-accepted .checkbox__native");
    return checkbox.checked;
  }
  function onCustomerNumberSubmit() {
    window.location.hash = "#";
  }
  function onFiledropperChange() {
    var
      $this = $(this),
      fileCount = $this.find(".filedropper__entries .filedropper__entry-label:not(.filedropper__entry-label--prototype)").length,
      $button = $this.closest(".customer-number-import__csv-form").find("button.button")
    ;
    if(fileCount) {
      $button.removeAttr("disabled");
    } else {
      $button.attr("disabled", true);
    }
  }
})(jQuery);
