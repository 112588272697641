Multivac.infofeed = (function($){
  return {
    filter: {
      onChange: onFilterChange,
      trigger: triggerFilter,
      onSubmit: onFilterSubmit,
      handleResponse: handleFilterResponse
    },
    triggerMarkAsRead: triggerMarkAsRead,
    markSingleReadSuccess: markSingleReadSuccess,
    markAllReadSuccess: markAllReadSuccess,
    clickDelegateDecider: clickDelegateDecider
  };

  function onFilterSubmit() {
    $(".infofeed-header__entries").each(function(){this.scrollTop = 0;});
  }

  function onFilterChange() {
    var
      $filter = $(this),
      $form = $filter.parents("form"),
      url = $filter.is(".input") ? $filter.data("form-ajax-url") : $filter.find("option:selected").data("form-ajax-url")
    ;
    if (url) {
      $form.attr("data-ajax-url", url).data("ajax-url", url);
    }
    $form.trigger("submit");
  }

  function triggerFilter() {
    window.requestAnimationFrame(function(){
      var $infofeedHeader = $(".infofeed-header");
      if($infofeedHeader.is(".infofeed-header--open")) {
        $infofeedHeader.find(".infofeed-header__filter").trigger("submit");
      }
    });
  }

  function markAllReadSuccess() {
    $(this)
      .parent()
      .find(".infofeed-entry")
      .each(markSingleReadSuccess)
    ;
    $(".infofeed-header__counter").addClass("infofeed-header__counter--0");
    $(".meta-nav-entry__newsfeed .meta-nav-entry__counter").addClass("meta-nav-entry__counter--0");
  }

  function markSingleReadSuccess(response) {
    var count = parseInt(response);
    $(this)
      .addClass("infofeed-entry--read")
      .removeAttr("data-ajax-url")
    ;
    handleCount(count);
  }

  function triggerMarkAsRead() {
    $(this).filter(":not(.infofeed-entry--read)").trigger("submit");
  }

  function handleFilterResponse() {
    window.requestAnimationFrame(function(){
      var
        $count = $(".infofeed__unread-count"),
        count = parseInt($count.text())
      ;
      $count.remove();
      handleCount(count);
    });
  }
  function handleCount(count) {
    var $counter1, $counter2;
    if(!isNaN(count)) {
      $counter1 = $(".infofeed-header__counter").text(count);
      $counter2 = $(".meta-nav-entry__newsfeed .meta-nav-entry__counter").text(count);
      if (count===0) {
        $counter1.addClass("infofeed-header__counter--0");
        $counter2.addClass("meta-nav-entry__counter--0");
      } else {
        $counter1.removeClass("infofeed-header__counter--0");
        $counter2.removeClass("meta-nav-entry__counter--0");
      }
    }
  }

  function clickDelegateDecider(e) {
    return $(e.target).parents(".meta-nav-entry__newsfeed").length === 0;
  }
})(jQuery);
