var MediaBreakpoint = {
  /**
   * fallback breakpointname announcement function
   * @returns {string}
   */
  "fallback":function(){return "";},
  /**
   * trigger Breakpoint-Aquiring (sometime the load-Event isn't fired jet)
   * @returns {string}  MediaBreakpoint
   */
  "trigger": function(){},
  "name": false
};

$(function(){
  'use strict';
  var
    $body = $("body"),
    mediaBreakpoint = $("<div/>")
      .addClass("mediabreakpoint-announce")
      .css("display","none!important")
      .appendTo($body)
      [0]
  ;

  function MediaQueryListener() {
    this.afterElement = document && document.body && window.getComputedStyle ? window.getComputedStyle(mediaBreakpoint, ':after') : false;
    this.currentBreakpoint = '';
    this.lastBreakpoint = '';
    this.init();
  }

  MediaQueryListener.prototype = {

    init: function () {
      var self = this;

      if(!self.afterElement && MediaBreakpoint.fallback()==="") {
        // If the browser doesn't support window.getComputedStyle just return
        return;
      }
      self._resizeListener();
    },
    _resizeListener: function () {
      var self = this;

      $(window).on('resizingStep orientationchange', handler);
      $(handler)

      function handler() {
        requestAnimationFrame(function() {
          if(self.afterElement) {
            self.currentBreakpoint = self.afterElement.getPropertyValue('content').replace(/(?:^["']|["']$)+/g,"");
          }
          if(!self.currentBreakpoint) {
            self.currentBreakpoint = MediaBreakpoint.fallback();
          }

          if (self.currentBreakpoint !== self.lastBreakpoint) {
            $(window).trigger('breakpoint-change', self.currentBreakpoint);
            self.lastBreakpoint = self.currentBreakpoint;
          }
          MediaBreakpoint.name = self.currentBreakpoint;
          return self.currentBreakpoint;
        })
      }
      MediaBreakpoint.trigger = handler;
    }
  };

  $(window).on('breakpoint-change', function(e, breakpoint) {
    var oldBreakpoint = $body.attr("media-breakpoint") || "";
    $body
      .attr("media-breakpoint",breakpoint)
      .removeClass("media-breakpoint-"+oldBreakpoint)
      .addClass("media-breakpoints-announced media-breakpoint-"+breakpoint);
  });

  new MediaQueryListener();
});
