SybitFront.comparator = {
  string: {
    paddedNumeric: function (a, b) {
      return SybitFront.comparator.string.numeric(
        a.replace(/^0+(?=.)/,""),
        b.replace(/^0+(?=.)/,"")
      );
    },
    numeric: function (a, b) {
      return toNum(a) - toNum(b);

      function toNum(str) {
        return parseFloat(str.replace(/[^\d,\.]+/g, "").replace(/,/, "."), 10);
      }
    },
    date: function (a, b) {
      return new Date(a).getTime() - new Date(b).getTime();
    }
  }
};
