Multivac.order = (function($){
  return {
    selection: {
      addToCart: selectionAddToCart,
      return: selectionReturn,
      saveAsTemplate: selectionSaveAsTemplate
    },
    template: {
      submitSave: submitSaveAsTemplate
    },
    return: {
      success: onReturnSuccess,
      successClose: onReturnSuccessClose,
      close: closeDialog
    }
  };

  function selectionAddToCart() {
    SybitFront.transferAttributes.call(this);
    SybitFront.removeAttributes.call(this);
    submitForm.call(this);
  }
  function selectionReturn() {
    SybitFront.transferAttributes.call(this);
    $("#order-detail__return-dialog-asnyc").html("");
    window.location.href = "#order-detail__return-dialog";
    submitForm.call(this);
  }

  function selectionSaveAsTemplate() {
    SybitFront.transferAttributes.call(this);
    window.location.href = "#cart-save-template";
    $(".cart-action__save-template-form .input--text").focus();
  }

  function submitSaveAsTemplate() {
    var
      $selectEntriesForm = $(".order-detail__list-cell"),
      $orderCode = $($selectEntriesForm).find("[name='sapOrderCode']").clone(),
      $entryNumbers = $($selectEntriesForm).find("[name='entryNumbers[]']:checked").clone().attr("type", "hidden")
    ;
    $(this).find("[name='sapOrderCode']").remove();
    $(this).find("[name='entryNumbers[]']").remove();
    $(this).append($entryNumbers, $orderCode);
  }

  function submitForm() {
    $($(this).data("transfer-attr-target-absolute-selector")).trigger("submit");
  }
  function onReturnSuccess() {
    var
      $this = $(this),
      title = $this.data("order-return-dialog-title")
    ;
    $this.parents(".popup").find(".popup__title").text(title);
  }
  function closeDialog() {
    Multivac.popup.forceClose();
    Multivac.ajax.handleError.call(this);
  }
  function onReturnSuccessClose(e) {
    e.preventDefault();
    window.location.hash='#';
    window.requestAnimationFrame(function(){
      window.location.reload();
    });
  }
})(jQuery);
