Multivac.cart.validation = (function ($) {

  $(function () {
    allRequiredFieldsSet();
    $("body").on("ajaxreload", allRequiredFieldsSet);
  });

  return {
    check: allRequiredFieldsSet
  };

  function allRequiredFieldsSet() {
    var
      $requiredFields = $(".cart-entry :input[required]"),
      allRequiredFieldsAreSet = true,
      $actions = $(".cart__continue-to-checkout-button, .cart-actions__create-quote")
    ;
    $requiredFields.each(function () {
      var val = $(this).val();
      if (val === "" || (val instanceof Array && val.length === 0)) {
        allRequiredFieldsAreSet = false;
      }
    });

    if (allRequiredFieldsAreSet) {
      $actions.removeAttr('disabled');
    } else {
      $actions.attr('disabled', true);
    }
  }
})(jQuery);
