SybitFront.select = (function($){
  "use strict";
  $(initAll);
  $("window").on("ajaxreload",initAll);

  return {
    attachOne: initOne
  };

  function initAll() {
    $(".select:not(select--initialized)")
      .addClass("select--initialized")
      .each(initOne);
  }

  function initOne() {
    $(this)
      .on("click", toggle)
      .on("blur", setCollapsed)
      .on("keypress",handleKeyPress)
      .on("change",checkPlaceholderSelected)
      .each(checkPlaceholderSelected)
    ;
  }
  function toggle() { $(this).toggleClass("select--expanded"); }
  function setCollapsed() { $(this).removeClass("select--expanded"); }
  function setExpanded() { $(this).addClass("select--expanded"); }
  function handleKeyPress(e) {
    var key = getKeyFromEvent(e);
    switch (key) {
      case 32:
        setExpanded.call(this);
        break;
      case 13:
        toggle.call(this);
        break;
      default:
        break;
    }
  }
  function getKeyFromEvent(e){
    var keynum = 0;
    if(window.event) { // IE
      keynum = e.keyCode;
    } else if(e.which){ // rest of the world
      keynum = e.which;
    }
    return keynum;
  }
  function checkPlaceholderSelected() {
    var
      $this = $(this),
      funcName = "removeClass"
    ;
    if($this.filter("[data-placeholder]").find("option[value='']:selected").length) {
      funcName = "addClass";
    }
    $this[funcName]("select--placeholder-selected");
  }
  // on ESC key toggle the "open" variable only if menu is in "open" state
  $(document).keyup(function(e) {
    if (e.keyCode === 27 || e.which === 27) {
      $(".select--expanded").removeClass("select--expanded");
    }
  });
})(jQuery);
