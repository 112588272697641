Multivac.machine.smartService = (function ($) {
  var { setMachineStatus, requestData, buildOeeUrl } = Multivac.machine.performance.util;

  return {
    details: Multivac.machine.performance.details,
    overview: machineOverview,
  };

  function machineOverview(value) {
    var $mainEntry = $(this);
    var token = value;
    var dummyData = $mainEntry.data("smart-service-dummy-data");
    var oeeHost = $mainEntry.data("smart-service-api-oee-host");

    fillMachineOverviewData();

    function fillMachineOverviewData() {
      var $machineEntries = $mainEntry.find(".machine-entry");
      var entryPromises = [];
      $machineEntries.each(function (index, entry) {
        var $entry = $(entry);
        entryPromises.push(handleEntry($entry));
      });
      Promise.all(entryPromises).then(function () {
        var $lines = $mainEntry.find(".machine-overview-line");
        $lines.each(function (index, line) {
          var $line = $(line);
          if ($line.data("machine-line")) {
            Multivac.machine.line.initOeeDropdown($line);
          }
        });
        refreshOverview();
      }).catch(function (e) {
        var text = $mainEntry.data("ajax-on-http-error-notification");
        if (text) {
          SybitFront.notification.create(text, "error");
        }
      });
    }

    function handleEntry($entry) {
      var equipmentCode = $entry.data("equipment-code");
      var machineType = $entry.data("technical-machine-type");
      var smartServiceConnection = $entry.data("smart-service-connection");
      var machineID = machineType + '_' + equipmentCode;
      setMachineStatus(smartServiceConnection, $entry);
      if (machineType) {
        return requestData(buildOeeUrl(dummyData, machineID, oeeHost), token).then(function (dataForEntry) {
          if ($.isEmptyObject(dataForEntry.oee)) {
            $entry.attr("data-machine-oee", '');
          } else {
            $entry.attr("data-machine-oee", dataForEntry.oee.oee || '');
          }
        });
      }
      return Promise.resolve();
    }

    function refreshOverview() {
      window.requestAnimationFrame(checkRefreshFacetting);
    }

    function checkRefreshFacetting() {
      Multivac.facetting.applyClientsideFacets();
    }
  }
})(jQuery);
