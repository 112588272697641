window.Multivac = window.Multivac || {};
window.Multivac.machine = window.Multivac.machine || {};
Multivac.machine = (function ($) {
  var
    $machineFormForRemoval = false,
    ccParentZIndex,
    $machineCustomDocDeleteForm = false
  ;

  return {
    connect: Multivac.machine.connect,
    remove: remove,
    rememberForRemoval: rememberForRemoval,
    saveB2BUnitUid: saveB2BUnitUid,
    catalogcreator: {
      load: loadCatalogCreator,
      toggleFullscreen: toggleCatalogCreatorFullscreen
    },
    docUpload: {
      onSubmit: onDocUploadSubmit,
      onSuccess: onDocUploadSuccess,
      onError: onDocUploadError,
      close: onDocUploadClose
    },
    docDelete: {
      trigger: onDocDelete,
      remember: rememberDocToDelete,
      success: onDocDeleteSuccess,
      error: onDocDeleteError
    },
    getDataForFilter: {
      name: getNameForFilter,
      type: getTypeForFilter,
      language: getLanguageForFilter
    }
  };

  function getNameForFilter() { return $(this).data("document-name"); }
  function getTypeForFilter() { return $(this).data("document-type"); }
  function getLanguageForFilter() { return $(this).data("document-language"); }
  function rememberForRemoval() {
    $machineFormForRemoval = $(this).find("form");
  }

  function saveB2BUnitUid() {
    var b2bUnitUid = $(this).parent().find("#chooseDebtorB2bUnitUid").val()
    var urlWithoutPopup = window.location.href.split('#')[0]
    window.location.href = urlWithoutPopup + "&c=" + b2bUnitUid
  }

  function remove() {
    $machineFormForRemoval.trigger("submit");
  }

  function loadCatalogCreator() {
    var
      $iFrame = $("#machine-catalogcreator-iframe"),
      $cartPoll = $("#updateCartPoll"),
      interval
    ;
    if (!$iFrame.attr("src")) {
      $iFrame.attr("src", $iFrame.data("src"));
      interval = $cartPoll.data("defered-reload-interval");
      $cartPoll
        .attr("data-reload-interval", interval)
        .data("reload-interval", interval)
        .each(Multivac.ajax.attachReloadTimer);

    }
  }

  function toggleCatalogCreatorFullscreen() {
    var
      $cc = $(".machine-catalogcreator"),
      isFullscreen = $cc.is(".machine-catalogcreator--fullscreen"),
      $container = $cc.find(".machine-catalogcreator__container"),
      offset = getContainerOffset($cc)
    ;
    if (isFullscreen) {
      $("body").removeClass("popup-is-open");
      $cc.removeClass("machine-catalogcreator--fullscreen");
      window.setTimeout(function () {
        $cc.parent().css("z-index", ccParentZIndex);
      }, 200);
    } else {
      $("body").addClass("popup-is-open");
      ccParentZIndex = $cc.parent().css("z-index");
      $cc.addClass("machine-catalogcreator--fullscreen").parent().css("z-index", "auto");
    }
    applyTransition($container, offset, {top: 0, right: 0, bottom: 0, left: 0}, isFullscreen);
  }

  function applyTransition($container, from, to, negative) {
    $container
      .removeClass("machine-catalogcreator--transition")
      .css(offsetToCss(from, negative))
    ;
    window.requestAnimationFrame(function () {
      $container.addClass("machine-catalogcreator--transition");
      window.setTimeout(function () {
        $container.css(offsetToCss(to, negative));
      }, 20);
    });
  }

  function offsetToCss(offset, negative) {
    var factor = negative ? -1 : 1;
    return {
      top: factor * offset.top + "px",
      right: factor * offset.right + "px",
      bottom: factor * offset.bottom + "px",
      left: factor * offset.left + "px"
    };
  }

  function getContainerOffset($container) {
    var
      offset = $container.offset(),
      windowOffset = getWindowOffset()
    ;
    offset.top -= windowOffset.top;
    offset.right = windowOffset.width - (offset.left + $container.outerWidth());
    offset.bottom = windowOffset.height - (offset.top + $container.outerHeight());
    offset.left -= windowOffset.left;
    return offset;
  }

  function getWindowOffset() {
    var $body = $("body");
    return {
      top: window.scrollY,
      right: 0,
      left: 0,
      bottom: 0,
      width: $body.innerWidth(),
      height: $body.innerHeight()
    };
  }

  function onDocUploadSubmit(e) {
    var that = this, args = arguments;
    window.setTimeout(function () {
      Multivac.ajax.runFormAsync.apply(that, args);
    }, 500);
    return false;
  }

  function onDocUploadSuccess() {
    window.location.hash = "machine-custom-document-table";
    window.requestAnimationFrame(function () {
      window.location.reload();
    });
  }

  function onDocUploadError(xhr) {
    if (xhr.responseText) {
      Multivac.notification.create(xhr.responseText, "error");
    }
  }

  function onDocUploadClose(e) {
    var
      $this = $(this),
      $overlay = $this.is(".popup__overlay") ? $this : $this.closest(".popup__overlay"),
      $filedropper = $overlay.find(".filedropper--uploading"),
      $e = $(e.target)
    ;
    if (!$e.is(".popup__overlay, .popup__close, .machine-detail-custom-doc-dialog__actions .button--link")) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if ($filedropper.length) {
      $filedropper.trigger("abort");
      window.setTimeout(function () {
        window.location.hash = "";
      }, 300);
    } else {
      window.location.hash = "";
    }
  }

  function onDocDelete() {
    $machineCustomDocDeleteForm.trigger("submit");
  }
  function rememberDocToDelete() {
    $machineCustomDocDeleteForm = $(this).closest(".machine-custom-doc-delete-form");
  }
  function onDocDeleteSuccess() {
    $(this).closest("tr").remove();
  }
  function onDocDeleteError(xhr) {
    if (xhr.responseText) {
      Multivac.notification.create(xhr.responseText, "error");
    }
  }

})(jQuery);
