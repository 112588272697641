SybitFront.modal = (function($){
  "use strict";
  var
    availableOptions = {
      alert: ["title","content","button","callback"],
      confirm: ["title","content","buttonyes","buttonno","callbackyes","callbackno"],
    },
    defaultMultiArguments = {
      confirm: {
        buttons: "yes,no",
        buttonyes: "OK",
        buttonno: "CANCEL"
      }
    },
    defaultOptions = {
      alert: {
        "button": "OK"
      },
      confirm: {
        "buttonyes": "OK",
        "buttonno": "CANCEL"
      }
    },
    prepareOptions = {
      alert: getAlertOptions,
      confirm: getConfirmOptions
    },
    commonOptions = {}
  ;

  /**
   * show onDomReady-Modals
   */
  $(function(){
    $(".dom-ready-modal").each(fireModal);
    initTriggers();
    $("body").on("ajaxreload",initTriggers);
  });

  function fireModal(e) {
    if (e) {
      e.preventDefault();
    }
    var $this = $(this), config = $this.data("modal-config-selector");
    if(!config && $this.data("modal-function")) {
      config = $this[0];
    }
    if(config) {
      SybitFront.modal(config);
    } else {
      console.error("Modal triggered without config!");
    }
  }

  function initTriggers() {
    $("[data-modal-trigger][data-modal-config-selector]:not(.modal-handler-attached)," +
      "[data-modal-trigger][data-modal-function]:not(.modal-handler-attached)")
      .each(attachHandler)
      .addClass("modal-handler-attached");
  }

  function attachHandler() {
    $(this).on("click", fireModal);
  }


  // returns public interface:
  // SybitFront.modal()
  // SybitFront.modal.alert()
  // SybitFront.modal.confirm()
  // SybitFront.modal.examples.callback()
  // SybitFront.modal.examples.callbackYes()
  // SybitFront.modal.examples.callbackNo()
  // SybitFront.modal.examples.callbackMulti()
  return $.extend(
    modal, {
      alert: alert,
      confirm: confirm,
      setCommonOptions: setCommonOptions,
      examples: {
        callback: function(){console.log("alert-example-callback was called");},
        callbackYes: function(){console.log("alert-example-callbackYes was called");},
        callbackNo: function(){console.log("alert-example-callbackNo was called");},
        callbackMulti: function(){
          console.log("alert-example-callbackNo was called");
          console.log(this);
          console.log(arguments);
        }
      }
    }
  );

  // --- public Interface --------------------------

  function modal(selector){
    $(function(){
      var args = parseArguments(selector);
      showDialog.call(args);
    });
  }
  function alert(title, content, button, callback) {
    showDialog.call(arguments);
  }
  function confirm(title, content, buttonyes, buttonno, callbackyes, callbackno) {
    showDialog.call(arguments);
  }

  // --- private Methods --------------------------


  function showDialog() {
    var options = getOptions.apply(this);
    $[this.callee.name](options);
  }

  function getOptions() {
    var
      funcName = this.callee.name,   //"alert" oder "confirm"
      options = $.extend({},defaultOptions[funcName]),
      i
    ;
    if(this[0] instanceof Object) {
      $.extend(options, this[0]);
    } else {
      for(i=0;i<Math.min(this.length,availableOptions[funcName].length);i++) {
        options[availableOptions[funcName][i]] = this[i];
      }
    }
    return applyCommonOptions(
      prepareOptions[funcName](options)
    );
  }

  function getConfirmOptions(opt) {
    var ret = {
      title: opt.title || null,
      content: getOptionContent(opt),
      buttons: {}

    },b,buttons = opt.buttons || defaultOptions.confirm.buttons;
    for(b in buttons) {
      if(buttons.hasOwnProperty(b)) {
        ret.buttons[b] =
          {
            text: opt.buttons[b],
            action: ensureCallable(opt.callbacks && opt.callbacks[b] ?opt.callbacks[b] :null)
          };
        if(opt.buttonClassNames && opt.buttonClassNames[b]) {
          ret.buttons[b].btnClass = opt.buttonClassNames[b];
        }
      }
    }
    return ret;
  }

  function getOptionContent(opt) {
    var content;
    if (typeof opt.content === 'undefined'){
      content = $(opt.configContext).html();
    } else {
      content = opt.content || null;
    }
    return content;
  }

  function getAlertOptions(opt) {
    return {
      title: opt.title || null,
      content: opt.content || null,
      buttons: {
        ok: {
          text: opt.button,
          action: ensureCallable(opt.callback)
        }
      }
    };
  }

  function setCommonOptions(options) {
    var o;
    for(o in options) {
      if (options.hasOwnProperty(o)) {
        jconfirm.pluginDefaults[o] = options[o];
      }
    }
    commonOptions = options;
  }

  function applyCommonOptions(options) {
    return $.extend({},commonOptions,options);
  }

  function parseArguments(context) {
    var
      $modal = $(context).eq(0), func,
      optionNames,
      options = {}, i, ret = []
    ;
    if(!$modal.length) {
      throw "Error: modal-config is missing for selector "+context;
    }
    func = $modal.data("modal-function");
    optionNames = availableOptions[func] || false;
    if(!optionNames) {
      throw "Error: modal-function is missing for selector "+context;
    }
    for(i=0; i<optionNames.length; i++) {
      options[optionNames[i]] = $modal.data("modal-option-"+optionNames[i]);
    }

    ret[0] = $.extend(options, parseMultiArguments($modal, func), {configContext: $modal[0]});
    ret.callee = {name:func};
    return ret;
  }

  function parseMultiArguments($modal, func) {
    var buttons, callbacks, buttonClassNames, ret={};
    if (func === "confirm") {
      buttons = parseButtons($modal,func);
      buttonClassNames = parseButtonClassnames($modal,buttons);
      callbacks = parseCallbacks($modal,buttons);
    }
    if(!$.isEmptyObject(buttons)) { ret.buttons = buttons; }
    if(!$.isEmptyObject(callbacks)) { ret.callbacks = callbacks; }
    if(!$.isEmptyObject(buttonClassNames)) { ret.buttonClassNames = buttonClassNames; }
    return ret;
  }

  function parseButtons($modal, func) {
    var
      attrButtons = String(
        $modal.data("modal-option-buttons") ||
        (defaultMultiArguments[func] && defaultMultiArguments[func].buttons)
      ).split(/\s*,\s*/), a,
      ret = {},
      key,
      val
    ;
    for(a=0;a<attrButtons.length;a++) {
      key = attrButtons[a];
      val = $modal.data("modal-option-button"+key) || defaultMultiArguments[func]["button"+key];
      if(val) {
        ret[key] = val;
      }
    }
    return ret;
  }

  function parseButtonClassnames($modal, buttons) {
    var key,ret={},className;
    for(key in buttons) {
      if(buttons.hasOwnProperty(key)) {
        className = $modal.data("modal-option-button"+key+"-classname");
        if(className) {
          ret[key] = className;
        }
      }
    }
    return ret;
  }

  function parseCallbacks($modal, buttons) {
    var key,ret={},
      stdCallback = $modal.data("modal-option-callback")
    ;
    for(key in buttons) {
      if(buttons.hasOwnProperty(key)) {
        ret[key] = $modal.data("modal-option-callback"+key) || stdCallback;
      }
    }
    return ret;
  }

  function ensureCallable(obj) {
    if(typeof obj==="string") {
      obj = SybitFront.util.retrieveFunction(obj);
    }
    if(isCallable(obj)) {
      return obj;
    }
    return function(){};
  }

  function isCallable(obj) {
    return typeof obj==="function";
  }
})(jQuery);
