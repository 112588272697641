Multivac.async = {handle: {cart: {}, product: {}, machine: {}}};
(function Multivac_async($) {
  Multivac.async.handle.cart.scalePrices = function (data) {
    'use strict';
    var
      $this = $(this),
      $target = $this.find(".tooltip__trigger"),
      $prototype = $target.find(".cart-scale-prices-lightbox--prototype"),
      $prototypeRow = $prototype.find(".cart-scale-prices-lightbox__row--prototype"),
      currentQuantity = $target.attr("data-cart-scale-pricing-entry-quantity"),
      $discountText = $target.find(".cart-entry__quantity-discount-text");
    if (!$prototype.length) {
      return;
    }

    if (data instanceof Array && data.length) {
      for (var i = 0; i < data.length; i++) {
        var $clone = $prototypeRow.clone(),
          quantityCol = $clone.find(".cart-scale-prices-lightbox__col-quantity"),
          itempriceCol = $clone.find(".cart-scale-prices-lightbox__col-itemprice");

        $clone.removeClass("cart-scale-prices-lightbox__row--prototype");
        if (currentQuantity >= data[i].minQuantity) {
          $clone.addClass("cart-scale-prices-lightbox__row--below");
        }

        quantityCol.text(data[i].minQuantity + quantityCol.text());
        itempriceCol.text(data[i].formattedValue);

        $clone.appendTo($prototype);
      }
      $prototypeRow.remove();
      $prototype.removeClass("cart-scale-prices-lightbox--prototype");
      $target.data('modal-option-content', $prototype[0].outerHTML);
      $prototype.remove();
      $discountText.show();
      $this.show();
    } else {
      $this.hide();
    }
  };
  Multivac.async.handle.product.scalePrices = function (value) {
    var
      $this = $(this),
      $prototype = $this.find(".product-scale-prices__row--prototype"),
      v,
      $clone
    ;
    $this.find("product-scale-prices__row:not(.product-scale-prices__row--prototype)").remove();
    if (value instanceof Array && value.length) {
      for (v = 0; v < value.length; v++) {
        if (value[v].minQuantity <= 1) {
          // hide scale prices for quantity 1 and below as they are shown separately
          continue;
        }
        $clone = $prototype.clone().removeClass("product-scale-prices__row--prototype");
        $clone.find(".product-scale-prices__min-quantitity").text(value[v].minQuantity);
        $clone.find(".product-scale-prices__value").text(value[v].formattedValue);
        $clone.appendTo($this);
      }
      $(".product-detail__block--scale-prices").show();
    } else {
      $(".product-detail__block--scale-prices").hide();
    }
  };

  Multivac.async.handle.product.discountValue = function (value) {
    if (value === null || typeof value === "undefined") {
      $(".product-prices").addClass("product-prices--no-discount");
    }
    $(this).removeAttr("data-retrieve-data-async-class").data("retrieve-data-async-class", "");
    return value;
  };

  Multivac.async.handle.product.discounts = function (value) {
    var
      $this = $(this),
      $prototype = $this.find(".product-discount__row--prototype"),
      v,
      $clone
    ;
    $this.find(".product-discount__row:not(.product-discount__row--prototype)").remove();
    if (value instanceof Array && value.length) {
      for (v = 0; v < value.length; v++) {
        $clone = $prototype.clone().removeClass("product-discount__row--prototype");
        $clone.find(".product-discount__name").text(value[v].name);
        $clone.find(".product-discount__value").text(value[v].discountPercentage + "%");
        $clone.appendTo($this);
      }
      $(".product-detail__block--discounts").show();
    } else {
      $(".product-detail__block--discounts").hide();
    }
  };

  (function () {
    Multivac.async.handle.product.availability = function (value) {
      var
        $this = $(this),
        $value = $this.find(".product-availability__value"),
        $description = $this.find(".product-availability__description"),
        $cartEntry
      ;
      if (value) {
        if (value.type) {
          $value
            .removeClass("product-availability__value--void")
            .addClass("product-availability__value--" + value.type);
        }
        if (value.description) {
          $description.text(value.description).show();
        } else if (value.type !== "undefined") {
          $description.hide();
        }
        $cartEntry = $this.parents(".cart-entry");
        if ($cartEntry.length && value.type === "undefined") {
          $cartEntry.addClass("cart-entry--availability-error");
        } else {
          $cartEntry.removeClass("cart-entry--availability-error");
        }
      }
      loadCartButtonsOnce();
    };

    function loadCartButtonsOnce() {
      if (!loadCartButtonsOnce.willBeCalled) {
        window.requestAnimationFrame(function () {
          $(".cart__buttons-loader").trigger("click");
          loadCartButtonsOnce.willBeCalled = false;
        });
        loadCartButtonsOnce.willBeCalled = true;
      }
    }
  })();

  Multivac.async.handle.product.prices = (function () {
    var notification = false;
    var ommitOnce = false;

    function checkNotification() {
      if (notification) {
        Multivac.notification.create(notification, "warning");
        notification = false;
      }
    }

    return function (value) {
      var
        $this = $(this),
        ret = value
      ;
      if (value === null || typeof value === "undefined") {
        notification = $this.data("price-unavailable-message");
        window.setTimeout(checkNotification, 100);
        ret = $this.addClass("price-unavailable").data("price-unavailable-text");
      }
      $this.removeAttr("data-retrieve-data-async-class").data("retrieve-data-async-class", "");
      if (!ommitOnce) {
        window.requestAnimationFrame(ommitMultipleMessages);
        ommitOnce = true;
      }
      return ret;
    };

    function ommitMultipleMessages() {
      $(".product-prices__row--customer-price.price-unavailable").prevUntil().hide();
      $(".price-unavailable")
        .parent()
        .filter(":not(.product-prices)")
        .find(".price-unavailable:has(+.price-unavailable)")
        .hide();
      ommitOnce = false;
    }
  })();

  Multivac.async.payload = (function () {
    return {
      buildAvailabilityRequest: buildAvailabilityRequest
    };

    function buildAvailabilityRequest(payload) {
      var
        $this = $(this),
        id = $this.data("retrieve-data-async-id"),
        $cartEntry = $this.parents(".cart-entry"),
        val = parseInt(
          $cartEntry.find("input[name='quantity']").val() ||
          $cartEntry.find(".cart-entry__qty-number").text().trim()
        ) || 0
      ;
      payload.cartMap = payload.cartMap || {};
      if (val) {
        payload.cartMap[id] = val;
      }
    }
  })();

})(jQuery);
