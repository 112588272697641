Multivac.quote = function($) {
  var $deleteForm = false;

  return {
    submitForm: submitForm,
    rememberForDeletion: rememberForDeletion,
    saveAsTemplate: selectionSaveAsTemplate,
    submitSaveAsTemplate: submitSaveAsTemplate
  };

  function submitForm() {
    $deleteForm.trigger("submit");
  }

  function rememberForDeletion() {
    $deleteForm = $(this);
  }

  function selectionSaveAsTemplate() {
    SybitFront.transferAttributes.call(this);
    window.location.href = "#cart-save-template";
    $(".cart-action__save-template-form .input--text").focus();
  }

  function submitSaveAsTemplate() {
    var
      $selectEntriesForm = $(".quote-detail__list-cell"),
      $quoteCode = $($selectEntriesForm).find("[name='sapOrderCode']").clone(),
      $entryNumbers = $($selectEntriesForm).find("[name='entryNumbers[]']:checked").clone().attr("type", "hidden")
    ;
    $(this).find("[name='sapOrderCode']").remove();
    $(this).find("[name='entryNumbers[]']").remove();
    $(this).append($entryNumbers, $quoteCode);
  }

}(jQuery);
