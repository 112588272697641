Multivac.emergencyMode = (function ($) {
  return {
    submitForm: submitForm,
    onChange: onChange,
    resetSwitch: resetSwitch,
    triggerPageReload: triggerPageReload
  };

  function submitForm() {
    $(".emergency-menu__form").trigger("submit");
  }

  function onChange(e) {
    var $this = $(this);
    window.requestAnimationFrame(function () {
      if ($this.is(":checked")) {
        Multivac.modal(".emergency-menu__modal-config");
      } else {
        submitForm();
      }
    });
  }

  function resetSwitch() {
    $(".emergency-menu__form .dipswitch input").prop("checked", false);
  }

  function triggerPageReload() {
    window.location.reload();
  }
})(jQuery);
