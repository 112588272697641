Multivac.tabs = (function($){

  $(function(){
    initAll();
    $("body").on("ajaxreload", initAll);
  });

  return {
    open: openTab
  };

  function initAll() {
    $(".tabs:not(.tabs--initialized)").addClass("tabs--initialized").each(initOne);
  }

  function initOne() {
    createTabsNav.call(this);
  }

  function createTabsNav() {
    var
      $tabs = $(this),
      $tabsNav = $('<li class="tabs__nav"></li>'),
      tabId = 1
    ;
    $tabs.find(".tabs__content").each(function(){
      var
        $tabsContent = $(this)
      ;
      $('<a class="tabs__nav_link"></a>')
        .text($tabsContent.find(".tabs__content-title").text())
        .on("click", toggleTab)
        .attr("tab-id", tabId)
        .appendTo($tabsNav)
      ;
      $tabsContent.attr("tab-id", tabId);
      tabId++;
    });
    $tabsNav.prependTo($tabs);
    $tabsNav.find(".tabs__nav_link:first-child").each(openTab);
  }

  function toggleTab() {
    var
      $tabNavLink = $(this),
      $tabs = $tabNavLink.parents(".tabs"),
      tabId = $tabNavLink.attr("tab-id")
    ;
    $tabs
      .find(".is__active").removeClass("is__active").trigger("inactive").end()
      .find("[tab-id='"+tabId+"']").addClass("is__active").trigger("active")
    ;
  }

  function openTab() {
    toggleTab.call(this);
  }

})(jQuery);
