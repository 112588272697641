SybitFront.retrieve = SybitFront.retrieve || {};

SybitFront.retrieve.async = (function ($) {
  "use strict";
  $(function () {
    onDomReady();
    $("body").on("ajaxreload", onDomReady);
  });

  return byElement;

  function byElement() {
    $(this)
      .filter(":not(.retrieve-data-async--in-progress, [data-retrieve-data-once='true'].retrieve-data-async--done)")
      .each(retrieve);
  }

  function onDomReady() {
    $("[data-retrieve-data-async-on-dom-ready]" +
      ":not(.retrieve-data-async--in-progress, [data-retrieve-data-once='true'].retrieve-data-async--done)").each(retrieve);
    $("[data-retrieve-data-async-on-click]:not(.retrieve-data-async--attached)")
      .addClass("retrieve-data-async--attached")
      .on("click", retrieve);
  }

  function retrieve() {
    var
      $source = $(this),
      url = $source.data("retrieve-data-async-url"),
      clazz = $source.data("retrieve-data-async-class"),
      method = getValidMethod($source.data("retrieve-data-async-method")),
      payloadCallbackName = $source.data("retrieve-data-async-payload-callback"),
      payloadCallback = payloadCallbackName ? SybitFront.util.retrieveFunction(payloadCallbackName) : false,
      ids = [],
      fields = [],
      $declarations,
      payload = {}
    ;
    if (!url || !clazz) {
      return;
    }
    $declarations = getDeclarations(clazz);
    if (payloadCallback) {
      $declarations.each(function () {
        payloadCallback.call(this, payload);
      });
    } else {
      $declarations.each(function () {
        collectParamsFromDeclaration.call(this, ids, fields);
      });
      payload = {ids: ids};
      if (fields.length) {
        payload.fields = fields;
      }
    }
    if (payloadNotEmpty(payload)) {
      request.call(this, url, payload, function (data) {
        processResponse(data, $declarations);
      }, $declarations, method);
    }
  }

  function getDeclarations(clazz) {
    var
      byField = "[data-retrieve-data-async-field]",
      byTarget = "[data-retrieve-data-async-target-selector],[data-retrieve-data-async-target-absolute-selector]",
      byHandler = "[data-retrieve-data-async-handler]"
    ;
    return $(
      "[data-retrieve-data-async-class='" + clazz + "']" +
      "[data-retrieve-data-async-id]" +
      ":not(" +
      "  .retrieve-data-async--in-progress," +
      "  [data-retrieve-data-once='true'].retrieve-data-async--done" +
      ")").filter(byField + "," + byTarget + "," + byHandler);
  }

  function collectParamsFromDeclaration(requestIds, requestFields) {
    var
      $this = $(this),
      id = $this.data("retrieve-data-async-id"),
      field = $this.data("retrieve-data-async-field")
    ;
    if (-1 === requestIds.indexOf(id)) {
      requestIds.push(id);
    }
    if (field && -1 === requestFields.indexOf(field)) {
      requestFields.push(field);
    }
  }

  function request(url, payload, callback, $declarations, method) {
    var $this = $(this), settings;
    settings = {
      type: method,
      data: JSON.stringify(payload),
      contentType: 'application/json',
      url: url
    };
    attachAjaxHandlers.call(this, settings, $declarations, callback);
    $this.add($declarations)
      .addClass("retrieve-data-async--in-progress")
      .removeClass("retrieve-data-async--done retrieve-data-async--error");
    $.ajax(settings);
  }

  function attachAjaxHandlers(settings, $declarations, callback) {
    var $this = $(this);
    settings.success = function (data) {
      $this
        .add($declarations)
        .addClass("retrieve-data-async--done")
        .removeClass("retrieve-data-async--in-progress")
      ;
      callback.call($this[0], data);
    };
    settings.error = function (xhr) {
      var params = [];
      if (typeof xhr !== "undefined" && !isNaN(xhr.status)) {
        params.push(xhr.status);
      }

      if (typeof xhr !== "undefined" && xhr.status !== 0) {
        SybitFront.ajax.handleError.apply($this[0], params);
        $(this)
          .add($declarations)
          .addClass("retrieve-data-async--error");
      }

      $(this).removeClass("retrieve-data-async--in-progress");
    };
  }

  function processResponse(data, $declarations) {
    $declarations.each(function () {
      processResponsePerDeclaration.call(this, data);
    });
  }

  function processResponsePerDeclaration(data) {
    var
      $this = $(this),
      id = $this.data("retrieve-data-async-id"),
      field = $this.data("retrieve-data-async-field"),
      path = $this.data("retrieve-data-async-path") || id + (field ? ("." + field) : ""),
      selector = $this.data("retrieve-data-async-target-selector"),
      selectorAbs = $this.data("retrieve-data-async-target-absolute-selector"),
      valueIsHtml = /^true$/i.test($this.data("retrieve-data-async-as-html")),
      noErrorLogging = /^true$/i.test($this.data("retrieve-data-async-no-error-logging")),
      value = SybitFront.util.retrieveObject(path, data, !noErrorLogging),
      insertMethod = valueIsHtml ? "html" : "text"
    ;
    value = processHandler.call(this, value);
    processSelector.call(this, value);
    if (selector) {
      $this.find(selector)[insertMethod](value);
    } else if (selectorAbs) {
      $(selectorAbs)[insertMethod](value);
    }
  }

  function processHandler(value) {
    var
      $this = $(this),
      handlerName = $this.data("retrieve-data-async-handler"),
      handler
    ;
    if (handlerName) {
      handler = SybitFront.util.retrieveFunction(handlerName);
      if (!handler) {
        return;
      }
      return handler.call(this, value);
    }
    return value;
  }

  function processSelector(value) {
    var
      $this = $(this),
      selector = $this.data("retrieve-data-async-target-selector"),
      selectorAbs = $this.data("retrieve-data-async-target-absolute-selector"),
      valueIsHtml = /^true$/i.test($this.data("retrieve-data-async-as-html")),
      insertMethod = valueIsHtml ? "html" : "text"
    ;
    if (selector) {
      $this.find(selector)[insertMethod](value);
    } else if (selectorAbs) {
      $(selectorAbs)[insertMethod](value);
    }
  }

  function getValidMethod(method) {
    return /(?:GET|POST)/i.test(method) ? method : "POST";
  }

  function payloadNotEmpty(payload) {
    return Object.keys(payload).length > 0 && (!payload.ids || payload.ids.length > 0)
  }
})(jQuery);
