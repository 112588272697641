Multivac.machine.entry = (function($){
  return {
    getDataForFilter: {
      category: getCategoryForFilter,
      type: getTypeForFilter,
      serialnumber: getSerialnumberForFilter,
      manufacturer: getManufacturerForFilter,
      year: getYearForFilter,
      line: getLineForFilter,
      tags: getTagsForFilter,
      status: getStatusForFilter
    },
    handleLineChange: handleLineChange,
    handleLineChangeWithSorting: handleLineChangeWithSorting,
    onLineFacetDrop: onLineFacetDrop,
    onTagFacetDrop: onTagFacetDrop,
    handleLineAssignSuccess: handleLineAssignSuccess,
    sortableConfig: {
      connectWith: ".machine-overview-line__entries",
      placeholder: "machine-entry__sortable-placeholder",
      forcePlaceholderSize: true,
      scroll: true,
      update: handleLineChangeWithSorting,
      tolerance: "pointer",
      distance: 10,
      cursor: "move",
      cursorAt: { top:-5, left:-5 },
      start: function(e, ui){
        ui.placeholder.height(ui.item.height());
      }
    }
  };

  function getCategoryForFilter() { return $(this).data("machine-category"); }
  function getTypeForFilter() { return $(this).data("machine-type"); }
  function getSerialnumberForFilter() { return $(this).data("machine-serialnumber"); }
  function getManufacturerForFilter() { return $(this).data("machine-manufacturer"); }
  function getYearForFilter() { return $(this).data("machine-year"); }
  function getLineForFilter() { return $(this).data("machine-line"); }
  function getTagsForFilter() {
    var
      $select = $(this).find("select.machine-tags"),
      tags = []
    ;
    if ($select.length) {
      tags = $select.val();
    } else {
      $(this).find(".machine-tags__entry").each(function(){
        tags.push($(this).text().trim());
      });
    }
    return tags;
  }
  function getStatusForFilter() { return $(this).data("machine-status"); }

  function handleLineChange() {
    resetMachineStatusRequestState();
    $(this).parents("form").trigger("submit");
  }

  function handleLineChangeWithSorting(event, dragPayload) {
    if(!checkIfLineChangeIsInLineColumn(event)) {
      event.preventDefault();
      return;
    }
    var
      $entry = $(dragPayload.item),
      $line = $entry.parents(".machine-overview-line"),
      lineId = $line.data("machine-line"),
      order = getLineSortingAfterLineChange.call($line),
      $form = $entry.find(".machine-entry__line-form")
    ;
    $entry.find(".select[name='equipmentGroupName']").val(lineId);
    appendEquipmentGroupOrderFields.call($form, order);
    resetMachineStatusRequestState();
    $form.trigger("submit");
  }

  function resetMachineStatusRequestState() {
    $(".machine-overview__lines").removeClass("retrieve-data-async--done");
  }

  function checkIfLineChangeIsInLineColumn(event) {
    if(
      event.originalEvent &&
      event.originalEvent.target
    ) {
      var
        $target = $(event.originalEvent.target),
        isInLines = $target.parents(".machine-overview__lines").length>0,
        isAPlaceholder = $target.is(".machine-entry__sortable-placeholder")
      ;
      return isInLines || isAPlaceholder;
    }
    return true;
  }

  function getLineSortingAfterLineChange() {
    var order = [];
    $(this).find(".machine-entry").each(function(){
      order.push($(this).data("machine-id"));
    });
    return order;
  }

  function appendEquipmentGroupOrderFields(order) {
    var $form = $(this), o, $input;
    $form.find("[name='equipmentGroupOrder']").remove();
    for (o=0; o<order.length; o++) {
      $input = $("<input/>").attr({
        type: "hidden",
        name: "equipmentGroupOrder"
      }).val(order[o]);
      $form.append($input);
    }
  }

  function onLineFacetDrop(event, dragged) {
    var
      $facetValue = $(this),
      $machineEntry = dragged.draggable,
      $form = $machineEntry.find(".machine-entry__line-form"),
      lineId = $facetValue.data("facet-value-id")
    ;
    $form.find("select[name='equipmentGroupName']").val(lineId);
    resetMachineStatusRequestState();
    $form.trigger("submit");
  }

  function onTagFacetDrop(event, dragged) {
    var
      $facetValue = $(this),
      $machineEntry = dragged.draggable,
      $tagSelect = $machineEntry.find("select.machine-tags"),
      tagName = $facetValue.data("facet-value-id")
    ;
    if($tagSelect.length) {
      Multivac.machine.tags.select.call($tagSelect[0], tagName);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  function handleLineAssignSuccess() {
    Multivac.facetting.applyClientsideFacets();
  }
})(jQuery);
