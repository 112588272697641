SybitFront.sortable = (function($){
  "use strict";
  var nameSpace = SybitFront;

  $(function(){
    attachHandlers();
    $("body").on("ajaxreload", attachHandlers);
  });

  return {
    setNameSpace: setNameSpace
  };

  function attachHandlers() {
    $("[data-sortable='true']:not(.sortable-attached)")
      .addClass("sortable-attached")
      .each(attachHandler);
  }

  function attachHandler() {
    var
      params = getConfig.call(this),
      $this = $(this)
    ;
    $this.sortable(params);
  }

  function getConfig() {
    if($(this).is("[data-sortable-config]")) {
      // data-sortable-config
      return getConfigByObject.call(this);
    } else {
      // data-sortable-[hyphenatedParamName from https://api.jqueryui.com/sortable]
      // eg. data-sortable-connect-with
      return $.extend(
        getConfigByAttributes.call(this),
        getCursorConfigByAttributes.call(this),
        getEventHandlersByAttributes.call(this)
      );
    }
  }

  function getConfigByObject() {
    var
      $this = $(this),
      configName = $this.data("sortable-config")
    ;
    return configName ? SybitFront.util.retrieveObject(configName) : {};
  }

  function getConfigByAttributes() {
    var
      $this = $(this),
      paramNames = ["appendTo","axis","cancel","classes","connectWith","containment","delay","disabled","distance","dropOnEmpty","forceHelperSize","forcePlaceholderSize",
        "grid","handle","helper","items","opacity","placeholder","revert","scroll","scrollSensitivity",
        "scrollSpeed","tolerance","zIndex"],
      params = {},
      p
    ;
    for (p=0; p<paramNames.length; p++) {
      getConfigParam($this, paramNames[p], params);
    }
    return params;
  }

  function getConfigParam($this, paramName, params) {
    var
      paramNameHyphenated = SybitFront.util.String.camelToHyphen(paramName),
      param = $this.data("sortable-"+paramNameHyphenated)
    ;
    if(param) {
      if(0===String(param).indexOf(nameSpace+".")) {
        param = SybitFront.util.retrieveFunction(param);
      } else if (/^(true|false)$/i.test(param)) {
        param = /^true$/i.test(param);
      }
      params[paramName] = param;
    }
  }

  function getCursorConfigByAttributes() {
    var
      $this = $(this),
      params = {},
      cursorLeft = $this.data("sortable-cursor-left"),
      cursorTop = $this.data("sortable-cursor-top"),
      cursor = $this.data("sortable-cursor")
    ;
    if(cursorLeft !== undefined) {
      params.cursorAt = params.cursorAt || {};
      params.cursorAt.left = cursorLeft;
    }
    if(cursorTop !== undefined) {
      params.cursorAt = params.cursorAt || {};
      params.cursorAt.top = cursorTop;
    }
    if(cursor) {
      params.cursor = cursor;
    }
    return params;
  }

  function getEventHandlersByAttributes() {
    var
      $this = $(this),
      params = {},
      eventNames = ["activate","beforeStop","change","create","out","over","receive","remove","sort", "start","stop","update"],
      e
    ;
    for (e=0; e<eventNames.length; e++) {
      getEventHandler($this, eventNames[e], params);
    }
    return params;
  }

  function getEventHandler($this, eventName, params) {
    var
      eventNameHyphenated = SybitFront.util.String.camelToHyphen(eventName),
      eventHandlerName = $this.data("sortable-on-"+eventNameHyphenated),
      eventHandler = eventHandlerName ? SybitFront.util.retrieveFunction(eventHandlerName) : false
    ;
    if(eventHandler) {
      params[eventName] = eventHandler;
    }
  }

  function setNameSpace(str) {
    nameSpace = str;
  }
})(jQuery);
