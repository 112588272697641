window.Multivac = window.Multivac || {};
window.Multivac.machine = window.Multivac.machine || {};
window.Multivac.machine.connect = (function ($) {

  setEquipmentIdInConnectTeaserPopupOnEquipmentDetailsPage();

  function setEquipmentIdInConnectTeaserPopupOnEquipmentDetailsPage() {
    $(".page-equipment-details .connectTeaser .button")
      .click(function (e) {
        e.preventDefault();
        var params = new URLSearchParams(window.location.search);
        var equipmentId = params.get('sn');
        params.append('startFlow', 'connectmachines');
        params.append('equipmentCodes', equipmentId);
        window.location.search = params.toString();
      });
  }

  var regexTrue = /^true$/i;
  return {
    handleAjaxResult: handleAjaxResult
  };

  function handleAjaxResult(data) {
    var $data = $(data);

    if (regexTrue.exec($data.data('ajax-success'))) {
      SybitFront.popup.handlePopupSuccessClose($("[data-popup-id=\"connectmachines\"]"));
    }
  }

})(jQuery);
