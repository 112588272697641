var Multivac;

(function Multivac_main($) {
  Multivac = $.extend({}, window.Multivac || {}, SybitFront);
  /**
   * Fallback for very old or faulty Browsers
   * This function returns the name of the current breakpoint
   * NOTE: If you use this function and breakpoints shall be changed,
   * don't forget to adapt this function.
   */
  Multivac.getMediaBreakpointName = function () {
    if (window.matchMedia('screen and (max-width: 499px)').matches) {
      return "small";
    } else if (window.matchMedia('screen and (max-width: 767px)').matches) {
      return "smedium";
    } else if (window.matchMedia('screen and (max-width: 1023px)').matches) {
      return "medium";
    } else if (window.matchMedia('screen and (max-width: 1199px)').matches) {
      return "large";
    } else {
      return "xlarge";
    }
  };

  MediaBreakpoint.fallback = Multivac.getMediaBreakpointName;

  Multivac.scroll = {
    horizontal: {
      decider: function () {
        if (MediaBreakpoint.name === "small" || MediaBreakpoint.name === "smedium") {
          return 30;
        } else {
          return 0;
        }
      }
    }
  };

  Multivac.modal.setCommonOptions({
    useBootstrap: false,
    animation: "scale",
    closeAnimation: "scale", animationSpeed: 200,
    animateFromElement: false,
    draggable: false,
    scrollToPreviousElement: false
  });

//https://developers.google.com/youtube/player_parameters?playerVersion=HTML5
  Multivac.youtube.profiles.default = {
    "controls": 2,
    "enablejsapi": 1,
    "fs": 1,
    "iv_load_policy": 3,
    "loop": 0,
    "modestbranding": 1,
    "origin": "localhost",
    "playsinline": 1,
    "rel": 0,
    "showinfo": 0
  };

  $(function () {
    $(".js-autosuggest").select2({
      tags: true
    });

    $(".js-select2Tags").select2({
      tags: true,
      dropdownAutoWidth: true
    });
  });
})(jQuery)
