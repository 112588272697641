SybitFront.labeleddipswitch = (function ($) {

  return {
    setUi:setUi,
    setUiWithFormSubmit: setUiWithFormSubmit
  };

  function setUi(event) {
    $(event.target).parent().parent().toggleClass("labeled-dipswitch--active");
  }

  function setUiWithFormSubmit(event) {
    SybitFront.labeleddipswitch.setUi(event);
    $('<input type="submit">').hide().appendTo($(event.target).parent().parent() ).click().remove()
  }

})(jQuery);
