Multivac.select2 = (function ($) {

  $(function () {
    initAll();
    $("body").on("ajaxreload", initAll);
  });

  return {};

  function initAll() {
    $(".select[data-init-select2]:not(.select2--initialized)")
      .addClass("select2--initialized")
      .each(initOne);
  }

  function initOne() {
    var
      $select = $(this),
      options = getSelect2Options($select)
    ;
    $select.select2(options);
  }

  function getSelect2Options($select) {
    var
      optionNames = ["adaptContainerCssClass", "adaptDropdownCssClass", "ajax", "allowClear", "amdBase",
        "amdLanguageBase", "closeOnSelect", "containerCss", "containerCssClass", "data", "dataAdapter",
        "debug", "dir", "dropdownAdapter", "dropdownAutoWidth", "dropdownCss", "dropdownCssClass",
        "dropdownParent", "escapeMarkup", "initSelection", "language", "matcher", "maximumInputLength",
        "maximumSelectionLength", "minimumInputLength", "minimumResultsForSearch", "multiple", "placeholder",
        "query", "resultsAdapter", "selectionAdapter", "selectOnClose", "sorter", "tags", "templateResult",
        "templateSelection", "theme", "tokenizer", "tokenSeparators", "width", "scrollAfterSelect"],
      options = {}
    ;
    Multivac.util.setNameSpace("Multivac");
    optionNames.forEach(function(optionName){
      Multivac.util.appendConfigFromDataAttr($select, "select2-", optionName, options);
    });
    return options;
  }
})(jQuery);
