Multivac.remoteAssistance = (function ($) {

  //TODO: flo


  $('.remote-assistance-form__privacy-policy-checkbox,.remote-assistance-form__instructions-policy-checkbox').change(function () {


    let $checkboxTerms = $(".remote-assistance-form__privacy-policy-checkbox").find(".checkbox__native").get(0).checked
    let $checkboxInstructions = $(".remote-assistance-form__instructions-policy-checkbox").find(".checkbox__native").get(0).checked

    let $button = $('.remote-assistance__form-submit').find('.button');

    if ($checkboxTerms && $checkboxInstructions) {
      $button.prop('disabled', false);
    } else {
      $button.prop('disabled', true);
    }
  });

  return {};
})(jQuery);
