window.Multivac = window.Multivac || {};
window.Multivac.dataOutagePopup = (function ($) {
  var regexTrue = /^true$/i;
  return {
    onClickDataOutageBtn: onClickDataOutageBtn,
    handleAjaxResult: handleAjaxResult
  };


  function onClickDataOutageBtn() {
    var urlParams = new URLSearchParams(window.location.search);
    var serial = urlParams.get('sn');
    if (serial != null) {
      $('input[name="serialNumber"]').val(serial);
    }
  }

  function handleAjaxResult(data) {
    var $data = $(data);

    if (regexTrue.exec($data.data('ajax-success'))) {
      SybitFront.popup.handlePopupSuccessClose($("[data-popup-id=\"dataoutage\"]"));
    }
  }

})(jQuery);
