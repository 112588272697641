Multivac.facetting = (function($){
  var
    $body,
    facetIdOpen = false,
    deferredReload = false
  ;
  $(function(){
    $body = $("body");
    initFacetting();
    $body.on("ajaxreload", initFacetting);
  });

  return {
    valueChanged: facetValueChanged,
    onMouseAway: onMouseAway,
    filterValueRetriever: filterValueRetriever,
    filterCounterValueRetriever: filterCounterValueRetriever,
    resetFilterFacets: resetFilterFacets,
    applyClientsideFacets: applyClientsideFacets,
    ensureTagFacetValue: ensureMachineTagFacetValue
  };

  function addClassOnInput($this) {
    if($this.val() === '') {
      $this.removeClass('facet-value--inputProvided')
    } else {
      $this.addClass('facet-value--inputProvided')
    }
  }

  function initFacetting() {
    var $facettingsToInit = $(".facetting:not(.facetting--initialized)")
      .addClass("facetting--initialized")
      .each(refreshFacetting);
    initFacets();
    if ($facettingsToInit.length) {
      facetIdOpen = false;
    }
  }

  function refreshFacetting() {
    var
      $this = $(this),
      count = $this.find(".facet-value--checked, .facet-value--inputProvided").length,
      cmd = count ? "addClass" : "removeClass"
    ;
    $body[cmd]("facetting--facets-selected")
      .find(".facetting-opener__count")
      .text(count);
    $(".machine-overview").each(refreshMachineOverview);
  }

  function initFacets() {
    $(".facet:not(.facet--initialized)")
      .addClass("facet--initialized")
      .each(refreshFacet)
      .each(checkAndReopenFacetAfterAsyncReload)
      .each(initFacetCounters)
    ;
  }

  function initFacetCounters() {
    $(this).find(".facet-value").each(function(){
      var
        $facetValue = $(this),
        $counter = $facetValue.find(".facet-value__counter")
      ;
      Multivac.filter.registerFilterCounter($counter[0], $facetValue[0]);
    });
  }

  function facetValueChanged() {
    var $facet = $(this).parents(".facet").each(refreshFacet);
    if($(this).is('input')) {
      addClassOnInput($(this))
    }
    $facet.parents(".facetting, .machine-menu-lines").eq(0).each(refreshFacetting);
    refreshResultCount();
    facetIdOpen = $facet.data("facet-id");
  }

  function refreshFacet() {
    var
      $facet = $(this),
      $dropdown = $facet.find(".facet__dropdown"),
      $checked = $facet.find(".facet-value--checked:not([data-facet-value-id='selectAll'])"),
      $checkedValues = $facet.find(".facet__checked-values"),
      text = ""
    ;
    $dropdown.find(".facet__checked-value").text();
    $checked.each(function(){
      var
        singleText = $(this).find(".facet-value__label").text().trim()
      ;
      text += (text === "") ? singleText : (", "+singleText);
    });
    if (text === "") {
      text = $facet.data("i18n-facet-all-values");
    }
    $checkedValues.text(text);
  }

  function checkAndReopenFacetAfterAsyncReload() {
    if(facetIdOpen && $(this).data("facet-id") === facetIdOpen) {
      $(this).addClass("facet--open");
    }
  }

  function onMouseAway() {
    $(this).removeClass(".facet--open");
  }

  function filterValueRetriever() {
    var ret = [];
    $(this).parent().find(".facet-value--checked").each(function(){
      var id = $(this).data("facet-value-id");
      if (id !== undefined) {
        ret.push(id);
      }
    });
    return ret.length ? ret : false;
  }

  function filterCounterValueRetriever() {
    var ret = [];
    $(this).each(function(){
      var id = $(this).data("facet-value-id");
      if (id !== undefined) {
        ret.push(id);
      }
    });
    return ret;
  }

  function resetFilterFacets() {
    var $facetting = $(".facetting");
    $facetting.find(".facet").each(function() {
      var $values = $(this).find(".facet-value--checked[data-filter-on]");
      $values
        .removeClass("facet-value--checked")
        .eq(0)
        .each(Multivac.filter.triggerRefreshSingle);
    });
    $facetting.find(".facet :input[data-filter-on]")
      .val("")
      .each(Multivac.filter.triggerRefreshSingle);
    $facetting.each(refreshFacetting);
    refreshResultCount();
  }

  function refreshResultCount() {
    var
      $facetting = $(".facetting"),
      $target = $facetting.find(".facetting__result-count"),
      $filters = $facetting.find(".facet [data-filter-on]"),
      $elements = $()
    ;
    window.requestAnimationFrame(function(){
      $filters.each(function() {
        var $filterTargets = getFilterTargets(this).filter(":visible");
        if($filterTargets.length) {
          $elements = $elements.add($filterTargets);
        }
      });
      Multivac.template.text.resolve.call($target, $elements.length);
    });
  }

  function getFilterTargets(filter){
    var
      $filter = $(filter),
      selector = $filter.data("filter-element-selector"),
      selectorAbs = $filter.data("filter-element-absolute-selector"),
      $elements = $()
    ;
    if(selector) {
      $elements = $elements.add($filter.find(selector));
    }
    if(selectorAbs) {
      $elements = $elements.add(selectorAbs);
    }
    return $elements;
  }

  function refreshMachineOverview() {
    window.requestAnimationFrame(function(){
      $(".machine-overview-line")
        .removeClass("machine-overview-line--empty")
        .each(function(){
          var $this = $(this)
          var visible = $this.find(".machine-entry:visible").length > 0
          var notEmptyLine = $this.attr("data-machine-line")
          var $facet = $('[data-facet-value-id="'+notEmptyLine+'"]')
          var cssClass = $facet.attr("data-click-toggle-cssclass")
          $facet.removeClass(cssClass);
          if($(".facetting--facets-selected").length > 0) {
            if(!visible) {
              $this.addClass("machine-overview-line--empty");
            } else {
              $facet.addClass(cssClass);
            }
          }
        });
    });
  }
  function applyClientsideFacets() {
    window.requestAnimationFrame(function(){
      $(".facet:has(.facet-value--clientside.facet-value--checked)")
        .each(function(){
          $(this)
            .find(".facet-value--checked")
            .eq(0)
            .each(Multivac.filter.triggerRefreshSingle);
        });
    });
  }

  function ensureMachineTagFacetValue(id) {
    var
      $facet = $(this),
      $facetValue
    ;
    if($facet
      .find(".facet-value")
      .filter(function(){return $(this).data("facet-value-id")===id;})
      .length>0) {
      return;
    }
    $facetValue = createFacetValue("tags", "tag", id, id, true)
      .data("droppable", "true").attr("data-droppable", "true")
      .data("droppable-accept", ".machine-entry").attr("data-droppable-accept", ".machine-entry")
      .data("droppable-tolerance", "pointer").attr("data-droppable-tolerance", "pointer")
      .data("droppable-on-drop", "Multivac.machine.entry.onTagFacetDrop").attr("data-droppable-on-drop", "Multivac.machine.entry.onTagFacetDrop")
      .appendTo($facet.find(".facet__values-scroll"))
    ;
    Multivac.filter.registerFilterCounter($facetValue.find(".facet-value__counter")[0],$facetValue[0]);
    triggerDeferredReloadOnce();
  }
  

  function createFacetValue(callbackType, mismatchType, id, text, counter) {
    return $('<div class="facet-value facet-value--clientside"' +
      ' data-click-toggle-cssclass="facet-value--checked"' +
      ' data-on-click-call="Multivac.facetting.valueChanged"' +
      ' data-facet-value-id=""' +
      ' data-filter-on="click"' +
      ' data-filter-value-retriever="Multivac.facetting.filterValueRetriever"' +
      (counter?' data-filter-counter-value-retriever="Multivac.facetting.filterCounterValueRetriever"':'') +
      ' data-filter-text-callback="Multivac.machine.entry.getDataForFilter.'+callbackType+'"' +
      ' data-filter-full-match="true"' +
      ' data-filter-element-absolute-selector=".machine-entry"' +
      ' data-filter-conflict-class="machine-entry--'+mismatchType+'-mismatch"' +
      '>' +
      '    <div class="facet-value__toggle">\n' +
      '      <svg class="icon icn--tick">\n' +
      '        <use xlink:href="#icn--tick"></use>\n' +
      '      </svg>\n' +
      '    </div>\n' +
      '    <div class="facet-value__label">\n' +
      '    </div>\n' +
      '    <div class="facet-value__counter"></div>\n' +
      '  </div>')
      .attr({"data-facet-value-id": id})
      .data("facet-value-id", id)
      .find(".facet-value__label").text(text).end()
    ;
  }

  function triggerDeferredReloadOnce() {
    if(!deferredReload) {
      deferredReload = true;
      window.requestAnimationFrame(function(){
        $("body").trigger("ajaxreload");
        deferredReload = false;
      });
    }
  }
})(jQuery);
