Multivac.orderMode = (function ($) {
  "use strict";

  if (window.location.href.indexOf("/checkout") > -1) {
    $('.ordermode-selector').hide();
  }

  $('.ordermode-menu__dropdown').change(function() {
    var $this = $(this);
    var url = $this.data('ordermodeUrl');

    $.ajax({
      url: url,
      type: 'GET',
      data: {
        id: $this.val()
      },
      success: function () {
        window.location.reload();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("Failed to set order mode. Error: [" + errorThrown + "]");
      }
    });
  });

  return {};
})(jQuery);
