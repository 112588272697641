(function SybitFront_img($){
  "use strict";
  $(function(){
    $("body").on("ajaxreload",handleImgWithDataSrcOnAjaxReload);
    handleImgWithDataSrc();
  });

  function handleImgWithDataSrcOnAjaxReload() {
    window.requestAnimationFrame(handleImgWithDataSrc);
  }
  function handleImgWithDataSrc() {
    $("img[data-src]").filter("[data-onerror-src],[data-onerror-call]").each(handleImgDataSrc);
  }

  function handleImgDataSrc() {
    var $this = $(this);
    $this
      .on("error",function(){
        $this.off("error").each(handleImgError);
      })
      .attr("src",$this.data("src"))
      .removeData("src")
      .removeAttr("data-src")
    ;
  }

  function handleImgError() {
    var
      $this = $(this),
      onErrorSrc = $this.data("onerror-src"),
      onErrorCall = $this.data("onerror-call"),
      onErrorCallback = onErrorCall ? SybitFront.util.retrieveFunction(onErrorCall) : false
    ;
    if(onErrorSrc) {
      $this.attr("src",onErrorSrc).addClass("onerror-src");
    }
    if(onErrorCallback) {
      onErrorCallback.call(this);
    }
  }
})(jQuery);
