Multivac.machine.line = (function ($) {
  var createSubmitOnce = false;
  var $deleteForm = false;

  return {
    onCreateSubmit: onCreateSubmit,
    onCreateKeydownEnter: onCreateKeydownEnter,
    onRenameAbort: onRenameAbort,
    onRenameResponse: onRenameResponse,
    rememberForDeletion: rememberForDeletion,
    initOeeDropdown: initOeeDropdown,
    updateOee: updateOee,
    onChangeOee: onChangeOee,
    deleteLine: deleteLine
  };

  function onCreateKeydownEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!createSubmitOnce) {
      $(this).parents(".machine-menu-lines__create-form").trigger("submit");
    }
  }

  function onCreateSubmit(e) {
    $(this).addClass("machine-menu-lines__create-form--submitting");
  }

  function onRenameAbort() {
    $(this)
      .parents(".machine-overview-line")
      .each(closeRenameDialog);
  }

  function closeRenameDialog() {
    $(this)
      .find(".machine-overview-line--renaming")
      .removeClass("machine-overview-line--renaming");
  }

  function onRenameResponse(data) {
    var
      $line = $(this).parents(".machine-overview-line"),
      $old = $(this).find("input[name='oldEquipmentGroupName']"),
      $new = $(this).find("input[name='newEquipmentGroupName']"),
      oldName = $old.val(),
      newName = $new.val()
    ;
    if (data && data.success) {
      renameLine($line, oldName, newName);
      renameMachineEntryLines(oldName, newName);
      renameFacetValues(oldName, newName);
      renameLineInDeleteForm($line, oldName, newName);
      $line.each(closeRenameDialog);
      $old.val(newName);
    }
    if (data && data.notification) {
      showNotification(data.notification);
    }
  }

  function showNotification(notification) {
    Multivac.notification.create(notification.text, notification.type);
  }

  function renameFacetValues(oldName, newName) {
    $(".machine-menu-lines .facet-value")
      .filter(function () {
        return String($(this).data('facet-value-id')) === String(oldName);
      })
      .data('facet-value-id', newName)
      .find(".facet-value__label")
      .text(newName)
    ;
  }

  function renameLine($line, oldName, newName) {
    $line
      .data("machine-line", newName)
      .find(".machine-overview-line__title")
      .text(newName)
    ;
  }

  function renameMachineEntryLines(oldName, newName) {
    $(".machine-entry__line-form .select option")
      .filter(function () {
        return $(this).val() === oldName;
      })
      .val(newName)
      .text(newName)
    ;
  }

  function renameLineInDeleteForm($line, oldName, newName) {
    $line.find(".machine-overview-line__delete-form input").val(newName);
  }

  function rememberForDeletion() {
    $deleteForm = $(this)
      .parents(".machine-overview-line")
      .find(".machine-overview-line__delete-form")
    ;
  }

  function initOeeDropdown($line) {
    var
      $oeeSelectBlock = $line.find(".machine-overview-line__oee .select-block"),
      $oeeSelect = $oeeSelectBlock.find(">select"),
      $machineEntriesWithOee = $line.find(".machine-entry[data-machine-oee]"),
      persistedMachineForDropdown = $line.data("persisted-line-oee"),
      options = []
    ;
    $machineEntriesWithOee.each(function () {
      var
        $entry = $(this),
        id = $entry.data("machine-id"),
        oee = $entry.data("machine-oee"),
        name = $entry.find(".machine-entry__named").text() || $entry.find(".machine-entry__type").text()
      ;

      if (persistedMachineForDropdown === id) {
        options.push('<option selected="selected" data-machine-id="' + id + '" value="' + oee + '">' + name + ' (' + id + ')</option>');
      } else {
        options.push('<option data-machine-id="' + id + '" value="' + oee + '">' + name + ' (' + id + ')</option>');
      }
    });

    if (options.length) {
      $oeeSelectBlock.removeClass("empty");
      $oeeSelect.html(options.join(''));
    } else {
      $oeeSelectBlock.addClass("empty");
    }

    updateOee($oeeSelect);
  }

  function onChangeOee() {
    updateOee($(this));
  }

  function updateOee($select) {
    var
      selectedVal = $select.val(),
      selectedMachineId = $select.children("option:selected").data("machine-id"),
      $line = $select.closest(".machine-overview-line"),
      $percentSpan = $line.find(".machine-overview-line__oee-percent")
    ;

    if (selectedVal) {
      $percentSpan.removeClass("oee-not-available").text(selectedVal + ' %');
      $line.find(".machine__oee-bar-filling").width(selectedVal + '%');
    } else {
      $percentSpan.addClass("oee-not-available").text($percentSpan.data("oee-not-available"));
    }
    persistOee($line, selectedMachineId);
  }

  function persistOee($line, selectedMachineId) {
    var $persistForm = $line.find(".machine-overview-line__persist-oee-form");
    $persistForm.find("input[name=serialNumber]").val(selectedMachineId);
    $persistForm.trigger("submit");
  }

  function deleteLine() {
    $deleteForm.trigger("submit");
  }
})(jQuery);
