Multivac.smartServices = (function($){
  return {
    inquiry: {
      validateForm: validateRequestForm
    }
  };

  function validateRequestForm() {
    var
      $this = $(this),
      valid = true,
      selected = $this.find(".smart-services-inquiry__service .checkbox__native:checked").length > 0,
      $submit = $(".smart-services-inquiry__form-submit .button")
    ;
    $this.find(".input[required], .select[required]").each(function(){
      valid = valid && this.checkValidity();
    });
    if(valid && selected) {
      $submit.removeAttr("disabled");
    } else {
      $submit.attr("disabled", true);
    }
  }
})(jQuery);
