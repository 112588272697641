(function SybitFront_horizontalScroll($){
  "use strict";
  $(function(){
    initScroller();
    $("body").on("ajaxreload", initScroller);
  });

  function initScroller() {
    $("[data-horizontal-scroll]:not(.scroller--initialized)")
      .addClass("scroller--initialized")
      .each(attachScroller);
  }

  function attachScroller() {
    this.addEventListener('wheel', handleWheel, { passive: false });
  }

  function handleWheel(e) {
    var
      $this = $(this),
      step = $this.data("horizontal-scroll"),
      func,
      delta
    ;
    if(/^false$/i.test(step)) {
      step = 0;
    } else if(/^true$/i.test(step)) {
      step = 30;
    } else if(/^\d+$/i.test(step)) {
      step = parseInt(step);
    } else if(step) {
      func = SybitFront.util.retrieveFunction(step);
      step = func.call(this);
    } else {
      step = 30;
    }
    if(step) {
      delta = e.deltaY < 0 ? +1 : -1;
      this.scrollLeft -= (delta * step);
      e.preventDefault();
      return false;
    }
  }
})(jQuery);
