Multivac.customretrofitname = (function ($) {
  return {
    assigne: function (_data) {
      var sapNumber = $(this).data("retrofit-sap-number");
      $("#retrofit__custom-name-" + sapNumber).css("display", "none");
      $("#machine-set-custom-retrofit-name-form-" + sapNumber).css("display", "flex");
      $("#customerSpecificRetrofitName-" + sapNumber).focus();
      var csp = $(this).data("customer-specific-retrofit-name");
      $("#customerSpecificRetrofitName-" + sapNumber).val(csp)
    },
    cancel: function (_data) {
      var sapNumber = $(this).data("retrofit-sap-number");
      $("#machine-set-custom-retrofit-name-form-" + sapNumber).css("display", "none");
      $("#retrofit__custom-name-" + sapNumber).css("display", "flex");
    }
  };
})(jQuery);
