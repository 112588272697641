Multivac.privacy = function($) {
  return {
      acceptLatestVersion: acceptLatestVersion,
      denyVersion: triggerLogout
  };

  function acceptLatestVersion() {
    $.post($('#privacyAcceptUrl').text());
  }

  function triggerLogout() {
    window.location.href = $('#logoutUrl').text();
  }
}(jQuery);
