SybitFront = SybitFront || {};
SybitFront.checkbox = (function($){
  "use strict";
  var internalChangeTriggered = false;

  $(function(){
    initAll();
    $("body").on("ajaxreload", initAll);
  });

  return {};

  function initAll() {
    $(".checkbox input")
      .filter("[data-checkbox-group-selector],[data-checkbox-group-absolute-selector]")
      .each(initSlaves)
      .not(".checkbox-group-initialized")
      .addClass("checkbox-group-initialized")
      .each(initMaster)
    ;
  }

  function initMaster() {
    $(this)
      .on("change", onMasterChange)
      .each(refreshMaster)
    ;
  }

  function initSlaves() {
    getGroupedCheckboxesOf($(this))
      .filter(":not(.master-checkbox-attached)")
      .addClass("master-checkbox-attached")
      .prop("registeredMaster",this)
      .on("change", function(){
        var registeredMaster = $(this).prop("registeredMaster");
        window.requestAnimationFrame(function(){refreshMaster.call(registeredMaster);});
      })
    ;
  }

  function getGroupedCheckboxesOf($master) {
    var
      relativeSelector = $master.data("checkbox-group-selector"),
      absoluteSelector = $master.data("checkbox-group-absolute-selector"),
      $checkboxes = absoluteSelector ? $(absoluteSelector) : $master.find(relativeSelector)
    ;
    return $checkboxes.is("input") ? $checkboxes : $checkboxes.find("input");
  }

  function refreshMaster() {
    var
      $this = $(this),
      $masterNative = $this.is("input") ? $this : $this.find("input"),
      $checkboxes = getGroupedCheckboxesOf($masterNative),
      allSelected = $checkboxes.filter(":not(:checked)").length === 0,
      noneSelected = $checkboxes.filter(":checked").length === 0,
      allDisabled = $checkboxes.filter(":not(:disabled)").length === 0
    ;
    if(allSelected && !noneSelected) {
      $masterNative.attr("checked","checked").prop("checked", true).removeAttr("semi");
    } else {
      $masterNative.removeAttr("checked").prop("checked", false);
      if(!noneSelected && $masterNative.parents(".checkbox").is(".checkbox--tristate")) {
        $masterNative.attr("semi","semi");
      } else {
        $masterNative.removeAttr("semi","semi");
      }
    }
    if(allDisabled) {
      $masterNative.attr("disabled","disabled").prop("disabled", true);
    }
    internalChangeTriggered = true;
    $masterNative.trigger("change");
    internalChangeTriggered = false;
  }

  function onMasterChange() {
    if(internalChangeTriggered) {
      return true;
    }
    var
      $master = $(this),
      $checkboxes = getGroupedCheckboxesOf($master),
      allSelected = $checkboxes.filter(":not(:checked)").length === 0
    ;
    if(allSelected) {
      $checkboxes.removeAttr("checked").prop("checked", false);
    } else {
      $checkboxes.attr("checked","checked").prop("checked", true);
    }
    window.requestAnimationFrame(function(){refreshMaster.call($master);});
  }
})(jQuery);
