SybitFront.inputQuantity = (function ($) {
  "use strict";
  var
    focussed = false,
    BOUNDARY = 1073741824
  ;

  $(function () {
    attachHandlersToAll();
    $("body").on("ajaxreload", attachHandlersToAll);
  });
  return {
    minus: minus,
    plus: plus
  };

  function minus(e) {
    var $input = getInput(this);
    process(e, $input, -1);
    return false;
  }

  function plus(e) {
    var $input = getInput(this);
    process(e, $input, +1);
    return false;
  }

  function process(e, $input, delta) {
    var value = parseInt($input.val()) || 0;
    if ($input.attr("readonly") === undefined && $input.attr("disabled") === undefined && (value + delta) >= 0) {
      $input.val(correctIfOutOfBounds($input, value + delta));
    }
    if (e) {
      e.preventDefault();
    }
  }

  function getInput(element) {
    var $element = $(element);
    if ($element.is(".input--number")) {
      return $element;
    } else {
      return $element.parents(".input-quantity").find(".input");
    }
  }

  function correctIfOutOfBounds($input, valueToSet) {
    var
      min,
      max
    ;
    if ($input.attr("min") !== undefined) {
      min = parseInt($input.attr("min") || -BOUNDARY);
      valueToSet = Math.max(min, valueToSet);
    }
    if ($input.attr("max") !== undefined) {
      max = parseInt($input.attr("max") || BOUNDARY);
      valueToSet = Math.min(max, valueToSet);
    }
    return valueToSet;
  }

  function attachHandlersToAll() {
    $(".input-quantity:not(.input-quantity--handlers-attached)")
      .addClass("input-quantity--handlers-attached")
      .each(attachHandlersToSingle);
    $(".input--number[data-attach-quantity-handlers]:not(.input--number-handlers-attached)")
      .addClass("input--number-handlers-attached")
      .each(attachHandlersToSingleInputNumber);
  }

  function attachHandlersToSingle() {
    $(this)
      .on("keypress keydown keyup", handleQtyKeyStrokes)
      .find(".input")
      .on("focus", onFocus)
      .on("blur", onBlur);
  }

  function attachHandlersToSingleInputNumber() {
    $(this)
      .on("keypress keydown keyup", handleQtyKeyStrokes)
      .on("focus", onFocus)
      .on("blur", onBlur);
  }

  function handleQtyKeyStrokes(e) {
    switch (e.keyCode) {
      case 13:
      case 43:
      case 44:
      case 45:
      case 69:
      case 107:
      case 109:
      case 187:
      case 188:
      case 190:
        e.preventDefault();
        break;
      default:
        break;
    }
  }

  function onFocus() {
    focussed = this;
    document.body.addEventListener('wheel', handleWheel, {passive: false});
  }

  function onBlur() {
    focussed = false;
    document.body.removeEventListener('wheel', handleWheel, {passive: false});
  }

  function handleWheel(e) {
    if (focussed) {
      if (e.deltaY < 0) {
        $(focussed).each(plus);
      } else {
        $(focussed).each(minus);
      }
      e.preventDefault();
      return false;
    }
  }

})(jQuery);
