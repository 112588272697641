Multivac.machine.performance = (function ($) {
  var locale = $('body').data('current-language');

  return {
    details: machineDetails,
    util: {
      setMachineStatus: setMachineStatus,
      requestData: requestData,
      buildOeeUrl: buildOeeUrl,
    },
  };

  function setMachineStatus(connected, $entry) {
    var $status = $entry.find(".machine-status");
    var $statusText = $status.find(".machine-status__text");

    if (connected) {
      $statusText.text("Online");
      $status.removeClass("machine-status--unconnected");
      $status.addClass("machine-status--" + dashStatus("online"));
      $entry.data("machine-status", "Online");
      $entry.removeClass("machine-performance--not-connected");
    } else {
      $status.addClass("machine-status--" + dashStatus("not-connected"));
      $entry.data("machine-status", "Not connected");
      $entry.addClass("machine-performance--not-connected");
      $statusText.text("Not connected");
    }
  }

  function requestData(url, token) {
    return $.ajax({
      url: url,
      beforeSend: function (request) {
        request.setRequestHeader("Authorization", "Bearer " + token);
      },
      type: 'GET'
    }).then(function (data) {
      return data;
    }).catch(function (error) {
      return Promise.reject(error);
    });
  }

  function buildOeeUrl(dummyData, machineID, host) {
    if (dummyData === true) {
      return addPortalToUrl("/my-machines/oee-dummy");
    } else {
      return 'https://' + host + '/machines/' + machineID + '/preview';
    }
  }

  function addPortalToUrl(url) {
    if (location.hostname !== 'mvkp.local') {
      return url;
    }
    return "/portal" + url;
  }

  function dashStatus(status) {
    return Multivac.util.String.camelToHyphen(status.replace(/\W/g, '-'));
  }

  function machineDetails(value) {
    var machineStatusColors = {
      PRODUCTION: "#009A44",
      FAULT: "#A52019",
      OTHERS: "#5591CD"
    };

    var $mainEntry = $(this);
    var token = value;
    var dummyData = $mainEntry.data("smart-service-dummy-data");
    var oeeHost = $mainEntry.data("smart-service-api-oee-host");
    var slaHost = $mainEntry.data("smart-service-api-sla-host");
    var spdHost = $mainEntry.data("smart-service-api-spd-host");
    var equipmentCode = $mainEntry.data("equipment-code");
    var machineType = $mainEntry.data("technical-machine-type");
    var smartServiceConnection = $mainEntry.data("smart-service-connection");
    var machineID = machineType + '_' + equipmentCode;
    var EMPTY_API_RESPONSE = "emptyApiResponse";

    fillDataForMachineDetail();

    function fillDataForMachineDetail() {
      setMachineStatus(smartServiceConnection, $mainEntry);
      Promise.all([handleOEECall(), handleSLACall(), handleSPDCall()])
        .catch(function (reason) {
          if (reason !== EMPTY_API_RESPONSE) {
            var text = $mainEntry.data("ajax-on-http-error-notification");
            SybitFront.notification.create(text, "error");
          }
        });
    }

    function handleOEECall() {
      return requestData(buildOeeUrl(dummyData, machineID, oeeHost), token)
        .then(function (oeeData) {
          if ($.isEmptyObject(oeeData)) {
            return Promise.reject(EMPTY_API_RESPONSE);
          }

          var { recipe, dateRange, oee, performance } = oeeData;
          handleRecipeWidget(recipe);
          handleSinceWidget(dateRange);
          handleOeeWidget(oee);
          handlePerformanceWidget(performance);
        }).catch(function (_error) {
          hideOeeValues();
          return Promise.reject(EMPTY_API_RESPONSE);
        });
    }

    function handleSLACall() {
      return requestData(buildSlaUrl(), token).then(function (smartLogData) {
        if ($.isEmptyObject(smartLogData.status)) {
          return Promise.reject(EMPTY_API_RESPONSE);
        }

        var statuses = getSortedStatusesFromData(smartLogData);
        var dataSet = constructDataSetForStackedBarChart(statuses);
        prepareStatusTable(statuses);
        prepareAndTriggerEChart(dataSet, ".machine-async__production-overview .echart");
      }).catch(function (_error) {
        hideWidget(".machine-async__production-overview");
        return Promise.reject(EMPTY_API_RESPONSE);
      });
    }

    function handleSPDCall() {
      return requestData(buildSpdUrl(), token).then(function (spdData) {
        if ($.isEmptyObject(spdData)) {
          return Promise.reject(EMPTY_API_RESPONSE);
        }
        handlePackagesWidget(spdData.packages);
      }).catch(function (_error) {
        hideWidget(".machine-async__produced");
        return Promise.reject(EMPTY_API_RESPONSE);
      });
    }

    function buildSlaUrl() {
      if (dummyData === true) {
        return addPortalToUrl("/my-machines/sla-dummy");
      } else {
        return 'https://' + slaHost + '/machines/' + machineID + '/preview';
      }
    }

    function buildSpdUrl() {
      if (dummyData === true) {
        return addPortalToUrl("/my-machines/spd-dummy");
      } else {
        return 'https://' + spdHost + '/machines/' + machineID + '/preview';
      }
    }

    function handleRecipeWidget(recipe) {
      if (!recipe || !recipe.name) {
        hideWidget(".machine-async__recipe");
      } else {
        $mainEntry.find(".machine-async__recipe .info-list__line").text(recipe.name);
      }
    }

    function handleSinceWidget(dateRange) {
      if (!dateRange || !dateRange.start) {
        hideWidget(".machine-async__since");
      } else {
        var since = new Date(dateRange.start);
        var sinceDate = since.toLocaleDateString(locale, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        });
        var sinceTime = since.toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit" });

        $mainEntry.find(".machine-async__since .machine-async__since-date .info-list-pairs__value").text(sinceDate);
        $mainEntry.find(".machine-async__since .machine-async__since-time .info-list-pairs__value").text(sinceTime);
      }
    }

    function handleOeeWidget(oee) {
      if (!oee || !oee.oee) {
        hideWidget(".machine-async__oee");
      } else {
        $mainEntry.find(".machine-async__oee .info-list__line").text(oee.oee + "%");
        $mainEntry.find(".machine-async__oee .machine__oee-bar-filling").width(oee.oee + "%");
      }
    }

    function handlePerformanceWidget(performance) {
      if (!performance || !performance.avgActualCycle || !performance.avgTargetCycle) {
        hideWidget(".machine-async__cycle-speed");
      } else {
        var dataSet = constructDataSetForPerformanceChart(performance);
        preparePerformanceTable(performance);
        prepareAndTriggerEChart(dataSet, ".machine-async__cycle-speed .echart");
      }
    }

    function handlePackagesWidget(packages) {
      if (!packages || !packages.good) {
        hideWidget(".machine-async__produced");
      } else {
        $mainEntry.find(".machine-async__produced .info-list__line").text(Intl.NumberFormat(locale).format(packages.good));
      }
    }

    function hideWidget(target) {
      $mainEntry.find(target).css({ "display": "none" });
    }

    function hideOeeValues() {
      hideWidget(".machine-async__recipe");
      hideWidget(".machine-async__since");
      hideWidget(".machine-async__oee");
      hideWidget(".machine-async__cycle-speed");
    }

    // performance chart consists of two echarts which are put together into the series array.
    // scatterSeries: the scale including the pin and label with actual cycle
    // gaugeBarSeries: stacked bar charts with different colors and spaces between
    function constructDataSetForPerformanceChart(performance) {
      var scatterSeries = createScatterSeries(performance.avgActualCycle);
      var series = createGaugeBarSeries(performance.avgTargetCycle);
      series.splice(0, 0, scatterSeries);

      return {
        baseOption: {
          xAxis: createXAxis(performance.avgTargetCycle),
          series: series,
        }
      };

      function createScatterSeries(actualCycle) {
        var symbolPath = "path://M 54.013 126.662 H 103.63 L 103.63 126.662 L 135.63 156.662 L 103.63 186.662 L 103.63 186.662 H 54.013 V 126.662 Z";
        return {
          name: "scatterSeries",
          type: "scatter",
          silent: true,
          xAxisIndex: 0,
          yAxisIndex: 0,
          symbol: symbolPath,
          symbolSize: [18, 13,],
          symbolOffset: ["0%", "60%"],
          symbolRotate: 90,
          data: [actualCycle],
          z: 2,
          itemStyle: {
            color: "#0C68B0FF",
            borderColor: "#FFFFFF",
            borderWidth: 1,
            opacity: 1,
          },
          label: {
            show: true,
            color: "#36577B",
            position: "bottom",
            fontSize: 14,
            fontWeight: 600,
            formatter: formatPerformanceValue,
          }
        };
      }

      function createGaugeBarSeries(targetCycle) {
        var gaugeColors = [
          "#BDD3EB",
          "#9CBDE1",
          "#5A91CD",
          "#4874A4",
          "#36577B",
          "#243A52"
        ];
        var numberOfElements = gaugeColors.length;
        var numberOfGaps = numberOfElements - 1;
        var gaugeElementWidth = targetCycle / (numberOfGaps * 0.05 + numberOfElements);
        var gaugeGapElementWidth = gaugeElementWidth * 0.05;

        // basic option for bar charts
        var gaugeBarSeriesOption = {
          type: "bar",
          silent: true,
          barWidth: 24,
          stack: "total",
          data: [gaugeElementWidth],
          xAxisIndex: 0,
          yAxisIndex: 0,
        };

        // transparent gap element
        var gaugeGapElement = Object.assign({
            itemStyle: { color: "transparent" },
          },
          gaugeBarSeriesOption, { data: [gaugeGapElementWidth] }
        );

        // colored bar element with gap included (except for last)
        var gaugeBarSeriesOptionList = gaugeColors.flatMap(function (gaugeColor, index, array) {
          var gaugeElement = {
            itemStyle: {
              color: gaugeColor,
              barBorderRadius: getBorderRadiusForIndex(index, array.length),
            }
          };
          Object.assign(gaugeElement, gaugeBarSeriesOption);
          return index < array.length - 1 ? [gaugeElement, gaugeGapElement] : gaugeElement;
        });

        return gaugeBarSeriesOptionList;
      }

      // returns xAxis options for charts
      // [0] is for scatterSeries, [1] for gaugeBar
      function createXAxis(targetCycle) {
        return [
          {
            type: "value",
            max: targetCycle,
            interval: targetCycle,
            splitLine: { show: false },
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            gridIndex: 0,
          },
          {
            type: "value",
            min: 0,
            max: targetCycle,
            interval: targetCycle,
            position: "top",
            splitLine: { show: false },
            axisLabel: {
              formatter: formatPerformanceValue,
              inside: true,
              align: "right",
              color: "#86888F",
              fontSize: 14,
              fontWeight: 600,
            },
            axisLine: { show: false },
            axisTick: { show: false },
            gridIndex: 1,
          },
        ];
      }
    }

    function constructDataSetForStackedBarChart(statuses) {
      var series = [];

      statuses.forEach(function (status) {
        series.push({
          name: status.name,
          type: "bar",
          stack: "status",
          silent: true,
          data: [status.value || 0],
          itemStyle: {
            color: status.color || "#878787",
            barBorderRadius: status.radius || 0,
          },
          datasetIndex: 0,
          barWidth: 24
        });
      });

      return {
        baseOption: {
          series: series
        }
      };
    }

    function getSortedStatusesFromData(data) {
      if ($.isEmptyObject(data.status)) {
        console.warn("[SLA] received unintelligible data (see debug messages)");
        console.debug(data);
        return;
      }

      var PRODUCTION = "PRODUCTION";
      var FAULT = "FAULT";
      var OTHERS = "OTHERS";

      var statuses = [
        {
          name: $mainEntry.data("i18n-machine-status-" + PRODUCTION.toLowerCase()) || PRODUCTION,
          value: data.status.PRODUCTION,
          color: machineStatusColors.PRODUCTION,
        },
        {
          name: $mainEntry.data("i18n-machine-status-" + FAULT.toLowerCase()) || FAULT,
          value: data.status.FAULT,
          color: machineStatusColors.FAULT,
        },
        {
          name: $mainEntry.data("i18n-machine-status-" + OTHERS.toLowerCase()) || OTHERS,
          value: accumulateOtherValues(),
          color: machineStatusColors.OTHERS,
        },
      ];

      // descending
      statuses.sort(function (a, b) {
        return b.value - a.value;
      });

      statuses.forEach(function (status, index, array) {
        status.radius = getBorderRadiusForIndex(index, array.length);
      });

      return statuses;

      function accumulateOtherValues() {
        var keys = Object.keys(data.status).filter(function (status) {
          return status !== PRODUCTION && status !== FAULT;
        });

        return keys.reduce(function (accumulated, status) {
          return accumulated + data.status[status];
        }, 0);
      }
    }

    function formatPerformanceValue(params) {
      var value = params.value ? params.value : params;
      return Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
    }

    function getBorderRadiusForIndex(index, arrayLength) {
      var radius = 4;
      if (index === 0) {
        return [radius, 0, 0, radius];
      } else if (index === arrayLength - 1) {
        return [0, radius, radius, 0];
      }
      return 0;
    }

    function prepareAndTriggerEChart(data, target) {
      var $eChart = $mainEntry.find(target);
      if ($eChart.length && data) {
        $eChart.data("echart-dataset", data);
      }
      Multivac.eChart.load($eChart);
    }

    function preparePerformanceTable(performance) {
      var ACTUAL = $mainEntry.data("i18n-machine-performance-actual-cycle") || 'ACTUAL';
      var TARGET = $mainEntry.data("i18n-machine-performance-target-cycle") || 'TARGET';
      var table = $("<table></table>");
      var actualRow = $("<tr></tr>").addClass("row");
      actualRow.append($("<td></td>").text(ACTUAL).addClass("label"));
      actualRow.append($("<td></td>").text(formatPerformanceValue(performance.avgActualCycle)).addClass("value").css({ "color": "#5A91CD" }));

      var targetRow = $("<tr></tr>").addClass("row");
      targetRow.append($("<td></td>").text(TARGET).addClass("label"));
      targetRow.append($("<td></td>").text(formatPerformanceValue(performance.avgTargetCycle)).addClass("value").css({ "color": "#243A52" }));

      table.append(actualRow, targetRow);
      var $tableDiv = $mainEntry.find(".performance-table");
      $tableDiv.empty();
      $tableDiv.append(table);
    }

    function prepareStatusTable(statuses) {
      var totalDuration = statuses.map(function (status) {
        return status.value;
      }).reduce(function (accumulated, currentVal) {
        return accumulated + currentVal;
      }, 0);

      var table = $("<table></table>");
      statuses.forEach(function (status) {
        var percent = Math.round(status.value / totalDuration * 100);
        var durationHours = moment.duration(status.value * 1000).hours();
        var durationMins = moment.duration(status.value * 1000).minutes();
        var durationString = durationHours + "h " + durationMins + "m";

        var tr = $("<tr></tr>").addClass("row");
        tr.append($("<td></td>").addClass("legend").css({ "background-color": status.color }));
        tr.append($("<td></td>").text(status.name).addClass("label"));
        tr.append($("<td></td>").text(durationString).addClass("value"));
        tr.append($("<td></td>").text(percent + "%").addClass("value"));
        table.append(tr);
      });

      var $tableDiv = $mainEntry.find(".status-table");
      $tableDiv.empty();
      $tableDiv.append(table);
    }
  }
})(jQuery);
