SybitFront.clone = (function($){
  "use strict";
  /**
   * handles attached event on
   * [data-clone-selector],[data-clone-absolute-selector]
   * [data-clone-delete-parents-selector],[data-clone-delete-absolute-selector]
   *
   * auto-attaches handler on event configured by
   * [data-clone-on]
   *
   * clones the element(s) with eventhandlers and applies array-naming to
   * fields (thus fieldgroups) with counter. Example:
   *   data-clone-absolute-selector=".pickup-order-form__product-prototype"
   *   data-clone-strip-classname="pickup-order-form__product-prototype"
   *   data-clone-target-absolute-selector=".pickup-order-form__product-list"
   *   data-clone-fieldgroup-name="defectProductEntryList"
   *   data-clone-fieldgroup-count-absolute-selector=".pickup-order-form__product-list .pickup-order-form__product"
   *  with fields
   *   <input name="fielname1" .../>
   *   <input name="fielname2" .../>
   *   <input name="fielname3" .../>
   *  results in
   *   defectProductEntryList[0][fielname1]
   *   defectProductEntryList[0][fielname2]
   *   defectProductEntryList[0][fielname3]
   *   defectProductEntryList[1][fielname1]
   *   defectProductEntryList[1][fielname2]
   *   defectProductEntryList[1][fielname3]
   */

  $(function(){
    attachAll();
    $("body").on("ajaxreload", attachAll);
  });

  return {
    add: add,
    remove: remove
  };

  function attachAll() {
    $("[data-clone-on]:not(.clone-handler-attached)")
      .addClass("clone-handler-attached")
      .each(attachOne);
  }

  function attachOne() {
    var
      $this = $(this),
      eventName = $this.data("clone-on")
    ;
    if(eventName) {
      $this.filter("[data-clone-selector],[data-clone-absolute-selector]")
        .on(eventName, add);
      $this.filter("[data-clone-delete-parents-selector],[data-clone-delete-absolute-selector]")
        .on(eventName, remove);
    }
  }

  function add(e) {
    var
      $this = $(this),
      config = retrieveConfig($this),
      $clone, $b4
    ;
    if($this.is("a")) {
      e.preventDefault();
    }
    $clone = config.$source.clone(true).removeClass(config.stripClass);
    if (config.prepareCloneCallback) {
      config.prepareCloneCallback.call($clone[0], $this);
    }
    if(config.groupName) {
      SybitFront.formgroup.add(config.$target ,$clone, config.groupName, config.b4||false);
    } else {
      $b4 = config.b4 !== false && config.$target.find(config.b4).eq(0);
      if($b4 && $b4.length) {
        $clone.insertBefore($b4);
      } else {
        config.$target.append($clone);
      }
    }
  }

  function remove(e) {
    var
      $this = $(this),
      selP = $this.data("clone-delete-parents-selector"),
      selA = $this.data("clone-delete-absolute-selector"),
      keepLast = /^true$/i.test($this.data("clone-delete-keep-last")),
      groupName = $this.data("clone-fieldgroup-name"),
      $group = selP
        ?$this.parents(selP)
        :$(selA)
    ;
    e.preventDefault();
    if(groupName && (!keepLast || $group.siblings(selP||selA).length)) {
      SybitFront.formgroup.del($group, groupName);
    }
  }

  function retrieveConfig($element) {
    var
      source = $element.data("clone-selector"),
      target = $element.data("clone-target-selector"),
      absoluteSource = $element.data("clone-absolute-selector"),
      absoluteTarget = $element.data("clone-target-absolute-selector"),
      prepareCallbackPath = $element.data("clone-prepare-callback"),
      config = {}
    ;
    config.stripClass = $element.data("clone-strip-classname");
    config.groupName = $element.data("clone-fieldgroup-name");
    config.$source = $(source||absoluteSource,source?$element:"body");
    config.$target = $(target||absoluteTarget,target?$element:"body");
    config.b4 = $element.data("clone-target-before-selector");
    config.prepareCloneCallback = prepareCallbackPath ? SybitFront.util.retrieveFunction(prepareCallbackPath) : false;
    if(!config.$source || !config.$target) {
      throw "data-clone must be configured with source and target";
    }
    return config;
  }
})(jQuery);

