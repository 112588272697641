SybitFront.util = (function ($) {
  "use strict";
  var nameSpace = "SybitFront";
  return {
    setNameSpace: setNameSpace,
    retrieveFunction: retrieveFunction,
    retrieveObject: retrieveObject,
    appendConfigFromDataAttr: appendConfigFromDataAttr,
    Array: {
      unique: arrayUnique
    },
    String: {
      camelToHyphen: camelToHyphen,
      hyphenToCamel: hyphenToCamel,
      escapeHtml: escapeHtml
    },
    url: {
      convertAbsolute: convertUrlAbsolute
    }
  };

  function retrieveFunction(str, logErrors) {
      var obj = this.retrieveObject(str);
      if (typeof obj !== "function") {
        if (logErrors !== false) {
          console.error("window." + str + " is not a function");
        }
        return false;
      }
      return obj;
  }
  function retrieveObject(str, rootObj, logErrors) {
      var
        parts = String(str).split(/\./), p,
        obj = rootObj || window
      ;

      // if we need to handle based of the complete response
      if (str === "/") return obj;

      for (p = 0; p < parts.length; p++) {
        if (obj !== null && typeof obj[parts[p]] !== "undefined") {
          obj = obj[parts[p]];
        } else {
          if (logErrors !== false) {
            console.error("couldn't find " + (rootObj ? "data" : "window") + "." + str + " (faulted with " + parts[p] + ")");
          }
          return undefined;
        }
      }
      return obj;
  }
  function appendConfigFromDataAttr($element, dataPrefix, configName, configs) {
      var
        configNameHyphenated = SybitFront.util.String.camelToHyphen(configName),
        hasConfig = $element.is("[data-" + dataPrefix + configNameHyphenated + "]"),
        config = $element.data(dataPrefix + configNameHyphenated)
      ;
      if (hasConfig) {
        if (0 === String(config).indexOf(nameSpace + ".")) {
          config = SybitFront.util.retrieveFunction(config);
        } else if (/^(true|false)$/i.test(config)) {
          config = /^true$/i.test(config);
        } else if (config === "null") {
          config = null;
        }
        configs[configName] = config;
      }
  }
  function arrayUnique(arr) {
        return $.grep(arr, function (el, index) {
          return index === $.inArray(el, arr);
        });
      }
  function setNameSpace(newNameSpace) {
    if (typeof window[newNameSpace] === "object") {
      nameSpace = newNameSpace;
    }
  }
  function camelToHyphen (str) {
        return str.replace(/(?!^)[A-Z]/g, function (match) {
          return "-" + match.toLowerCase();
        });
  }
  function hyphenToCamel(str) {
        return str.replace(/(?!^)-([a-z])/g, function (match, char) {
          return char.toUpperCase();
        });
      }

  function convertUrlAbsolute(relative) {
    return new URL(relative, baseuri()).href;
  }
  function escapeHtml(unsafe) {
    return String(unsafe)
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
    ;
  }

  /**
   * Return the base URI of the document.
   *
   * @return {String} uniform resource identifier
   * @link https://github.com/Benxamin/baseuri/blob/master/index.js
   */
  function baseuri() {

    // Check expected property.
    try {
      if (document.baseURI) return document.baseURI;
    } catch(e) {
      console.debug("Cannot access document.baseURI", e);
    }

    // Should be exactly one tag:
    //   https://developer.mozilla.org/en-US/docs/Web/HTML/Element/base
    var base = document.getElementsByTagName('base');

    // Return location from BASE tag.
    if (base.length > 0) return base[0].href;

    // Else use implementation of documentURI:
    //   http://www.w3.org/TR/DOM-Level-3-Core/core.html#Node3-baseURI
    return document.URL;
  }
})(jQuery);
